<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Actualizar Contraseña</h4>
  <br>
 
  <button type="button" class="close" aria-label="Close" (click)="cerrar()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="row" style="justify-content: center; text-align: left;">
    
      <div class="col-md-12">
        <label for="">Contraseña actual</label>
        <input class="form-control" type="password" placeholder="Contraseña actual" style="width: 430px;" [(ngModel)]="datos.pw_antigua">
      </div>
      <div class="col-md-12">
        <br>
        <label for="">Contraseña nueva</label>
        <input class="form-control" type="password" placeholder="Contraseña nueva" style="width: 430px;" [(ngModel)]="datos.pw_nueva">
      </div>
      <div class="col-md-12">
        <label for="">Confirmar contraseña</label>
        <input class="form-control" type="password" placeholder="Repetir contraseña nueva" style="width: 430px;" [(ngModel)]="datos.pw_nueva2">
      </div>
    
  </div>
</div>
<div class="modal-footer">
  <button (click)="actualizarPw()" type="button" class="btn btn-success">Actualizar contraseña</button>
</div> 
