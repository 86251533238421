
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Mantenedor query INDESAD</h3>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                <div class="row">
                    <label>Nombre</label>
                    <input type="text" [(ngModel)]="dataQuery.NOMBRE" name="NOMBRE" class="form-control">
                    <label>Consulta</label>
                    <textarea [(ngModel)]="dataQuery.CONSULTA" name="CONSULTA" class="form-control" style="height: 100px;">{{dataQuery.CONSULTA}}</textarea>

                    <button type="button" class="btn btn-primary" (click)="Guardar(dataQuery)">Guardar</button>
                </div>
                <div class="row">
                    <ngx-table #table [configuration]="configuration" [data]="data" [columns]="columns" ></ngx-table>
                    <ng-template #frmNombre let-row let-rowIndex="rowIndex">
                        <input type="text" [(ngModel)]="row.NOMBRE" name="NOMBRE" class="form-control">
                    </ng-template>
                    <ng-template #frmConsulta let-row let-rowIndex="rowIndex">
                        <textarea [(ngModel)]="row.CONSULTA" name="CONSULTA" class="form-control" style="height: 100px;">{{row.CONSULTA}}</textarea>
                    </ng-template>
                    <ng-template #frmAccion let-row let-rowIndex="rowIndex">
                        <button type="button" class="btn btn-success" (click)="Guardar(row)">Actualizar</button>
                        <button type="button" class="btn btn-info" (click)="ProbarQuery(row)">Probar</button>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>
