import { Component, OnInit, OnDestroy, Input, EventEmitter, ViewChild, TemplateRef } from '@angular/core';

import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Columns, Config, DefaultConfig, API, APIDefinition } from 'ngx-easy-table';
import { IntranetoldService } from '../../../api/intranetold.service'
import { variableGlobal } from "../../../global.enum";
import { abreTablaGenericoComponent } from '../../app/query-mongo/abreTablaGenerico';


@Component({
  selector: 'app-detalleArticuloBodega',
  templateUrl: './detalleArticuloBodega.html',
  styleUrls: ['./stock-comercial.component.sass']
})

export class StockComercialDetalleItemBodegaComponent implements OnInit {
  @Input() anyDataForm: any;
  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private _api: IntranetoldService,
  ) {
  }
  @ViewChild('tableModal', { static: true }) tableModal: APIDefinition;
  @ViewChild('frmIsCommited', { static: true }) frmIsCommited: TemplateRef<any>;
  @ViewChild('frmOnOrder', { static: true }) frmOnOrder: TemplateRef<any>;
  @ViewChild('frmDetalleLote', { static: true }) frmDetalleLote: TemplateRef<any>;

  ItemCode: string;
  dataModal: any = [];
  public configurationModal: Config;
  public columnsModal: Columns[];
  ngOnInit(): void {
    this.cargaTabla();

  }

  async cargaTabla() {
    this.columnsModal = [
      { key: 'ItemCode', title: "Codigo" },
      { key: 'ItemName', title: 'Descripción' },
      { key: 'ItmsGrpNam', title: 'Categoria' },
      { key: 'WhsCode', title: 'Cod. Bodega' , cellTemplate: this.frmDetalleLote },
      { key: 'WhsName', title: 'Bodega' },
      { key: 'OnHand', title: 'Stock' },
      { key: 'IsCommited', title: 'NV', cellTemplate: this.frmIsCommited },
      { key: 'OnOrder', title: 'Por llegar',cellTemplate: this.frmOnOrder },
      { key: 'U_Ubicacion_R', title: 'U Reserva' },
      { key: 'U_Ubicacion_R2', title: 'U Reserva 2' },
      { key: 'U_Ubicacion_R3', title: 'U Reserva 3' },
      { key: 'U_Ubicacion_R4', title: 'U Reserva 4' },
      { key: 'U_Ubicacion_R5', title: 'U Reserva 5' },


    ];

    this.configurationModal = {
      ...DefaultConfig,
      //searchEnabled: true,
      detailsTemplate: true,
      //paginationRangeEnabled: false,

      horizontalScroll: true,
      resizeColumn: false,
      fixedColumnWidth: true,
    };


    var data: any = await this._api.getQueryAll();
    var query: string = "";
    await data.objeto.forEach(element => {
      if (element.NOMBRE == "stockComercialDetalleBodega") {
        query = element.CONSULTA;
      }
    });
    query = query + " AND T0.[ItemCode] = '" + this.ItemCode + "'";
    //console.log(query);
    this.dataModal = await this._api.GetNotFileQuery(query);
    this.dataModal = this.dataModal.objeto;


    if (variableGlobal.ESTILO_EASY_TABLE) {
      this.setClass(variableGlobal.ESTILO_EASY_TABLE);
    }
  }
  private setClass(name: string): void {
    this.tableModal.apiEvent({
      type: API.setTableClass,
      value: name,
    });
  }
  cerrar() {
    this.activeModal.close();
  }

  async abrirItemLote(item) {

    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: "xl",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG
    };

    var queryConsulta: string;
    queryConsulta = await this.obtieneQueryMongo("VistaLotesBodega");

    queryConsulta = queryConsulta.replace("INPUTARTICULO", item.ItemCode);
    queryConsulta = queryConsulta.replace("INPUTBODEGA", item.WhsCode);

    const modalRef = this.modalService.open(abreTablaGenericoComponent, ngbModalOptions);
    modalRef.componentInstance.query = queryConsulta;
    modalRef.componentInstance.titulo = "Detalle lotes de articulo";
    modalRef.result.then();

  }
  async abrirItemDetalle(item) {

    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: "xl",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG
    };

    var queryConsulta: string;
    queryConsulta = await this.obtieneQueryMongo("DocumentosVentaArticulo");

    queryConsulta = queryConsulta.replace("INPUTARTICULO", item.ItemCode);
    queryConsulta = queryConsulta.replace("INPUTBODEGA", item.WhsCode);

    const modalRef = this.modalService.open(abreTablaGenericoComponent, ngbModalOptions);
    modalRef.componentInstance.query = queryConsulta;
    modalRef.componentInstance.titulo = "Detalle ventas de articulo";
    modalRef.result.then();

  }

  async abrirItemDetalleCompra(item) {

    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: "xl",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG
    };

    var queryConsulta: string;
    queryConsulta = await this.obtieneQueryMongo("DocumentosCompraArticulo");

    queryConsulta = queryConsulta.replace("INPUTARTICULO", item.ItemCode);
    queryConsulta = queryConsulta.replace("INPUTBODEGA", item.WhsCode);

    const modalRef = this.modalService.open(abreTablaGenericoComponent, ngbModalOptions);
    modalRef.componentInstance.query = queryConsulta;
    modalRef.componentInstance.titulo = "Detalle ventas de articulo";
    modalRef.result.then();

  }

  async obtieneQueryMongo(nombre) {
    var data: any = await this._api.getQueryAll();
    var query: string = "";
    await data.objeto.forEach(element => {
      if (element.NOMBRE == nombre) {
        query = element.CONSULTA;
      }
    });

    return query;
  }


}
