import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {IntranetoldService} from '../../../api/intranetold.service'
import { Columns, Config, DefaultConfig, APIDefinition, API } from 'ngx-easy-table';
import {DocumentoMarketingDetalle, DocumentoMarting, Serie} from "../../../model/ventaModel"
import Swal from 'sweetalert2'
import {variableGlobal} from "../../../global.enum"



@Component({
  selector: 'app-selectedSerie',
  templateUrl: './listaSerie.html',
  styleUrls: ['./venta.component.sass']
})
export class listaSerieComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    private _api: IntranetoldService
  ) { }
  ItemCode:string;
  data:DocumentoMarketingDetalle[] = [];
  dataGeneral:DocumentoMarketingDetalle[] = [];
  public configurationGeneral: Config;
  public columnsGeneral: Columns[];

  @ViewChild('frmCantidadSeleccionada', { static: true }) frmCantidadSeleccionada: TemplateRef<any>;
  @ViewChild('frmAccionPicking', { static: true }) frmAccionPicking: TemplateRef<any>;
  
  public configurationStock: Config;
  public columnsStock: Columns[];
  dataStock:any = [];

  public configurationPicking: Config;
  public columnsPicking: Columns[];
  dataPicking:Serie[] = [];
  rowSeleccionada:any = {};
  public pagination = {
    limit: 5,
    offset: 1,
    count: -1,
  };
  @ViewChild('table', { static: true }) table: APIDefinition;
  @ViewChild('tablaStock', { static: true }) tablaStock: APIDefinition;
  @ViewChild('tableLotePickeado', { static: true }) tableLotePickeado: APIDefinition;
  
  ngOnInit(): void {


    this.columnsGeneral = [
        { key: 'ItemCode', title: "Articulo", searchEnabled: true },
        { key: 'Dscription', title: 'Descripción', searchEnabled: false },
        { key: 'WhsCode', title: 'Bodega', searchEnabled: false },
        { key: 'Quantity', title: 'Cantidad', searchEnabled: false },
        { key: 'CantidadPendiente', title: 'Cantidad faltante', searchEnabled: false  }, 
        { key: 'CantidadPickeada', title: 'Cantidad seleccionada', searchEnabled: false }
      ];

      this.configurationGeneral = { 
        ...DefaultConfig,
        searchEnabled: true,
        detailsTemplate: true,
        paginationRangeEnabled: false,
        paginationEnabled:false,
        horizontalScroll: true,
        //resizeColumn: false,
        orderEnabled: false,
        //rowReorder:true,
        //columnReorder:true,
        fixedColumnWidth: true,
        selectRow:false,
     };





     this.columnsStock = [
        { key: 'DistNumber', title: "Lote", searchEnabled:true },
        { key: 'Quantity', title: 'Cantidad disponible', searchEnabled:false},
        { key: '', title: 'Cantidad seleccionada', cellTemplate:this.frmCantidadSeleccionada, searchEnabled:false },

    ];

    this.configurationStock = { 
        ...DefaultConfig,
        searchEnabled: true,
        detailsTemplate: true,
        paginationRangeEnabled: true,
        paginationEnabled:true,
        horizontalScroll: true,
        //resizeColumn: false,
        orderEnabled: false,
        //rowReorder:true,
        //columnReorder:true,
        fixedColumnWidth: true,
        selectRow:false,
        rows:5,
    };





    this.columnsPicking = [
        { key: 'SerialNumber', title: "Serie"},
        { key: 'Quantity', title: 'Cantidad disponible'},
        { key: '', title: 'Acción', cellTemplate: this.frmAccionPicking}

    ];

    this.configurationPicking= { 
        ...DefaultConfig,
        searchEnabled: false,
        detailsTemplate: true,
        paginationRangeEnabled: false,
        paginationEnabled:false,
        horizontalScroll: true,
        //resizeColumn: false,
        orderEnabled: false,
        //rowReorder:true,
        //columnReorder:true,
        fixedColumnWidth: true,
        selectRow:false,
    };






    this.cargaInicial();
  }



  queryLoteStock:string;
  async cargaInicial()
  {
    this.data.forEach(element => {
        if (element.ManSerNum == "Y") {
            this.dataGeneral.push(element);
            element.CantidadPendiente = element.Quantity;
            element.CantidadPickeada = 0;
            element.Serie.forEach(element2 => {
              element.CantidadPickeada += element2.Quantity;
            });
            element.CantidadPendiente = element.CantidadPendiente - element.CantidadPickeada;

        }
    });


    var data:any = await this._api.getQueryAll();
    await data.objeto.forEach(element => {
      if (element.NOMBRE == "serieStockBodega") {
        this.queryLoteStock = element.CONSULTA;
      }
    });
    if (this.ItemCode) {
      this.table.apiEvent({
        type: API.setInputValue,
        value: [
            { key: 'ItemCode', value: this.ItemCode },
          ],
      });
    }
    if(variableGlobal.ESTILO_EASY_TABLE)
    {
      this.setClass(variableGlobal.ESTILO_EASY_TABLE);
    }
  
  }

  private setClass(name: string): void {
    this.table.apiEvent({
      type: API.setTableClass,
      value: name,
    });
    this.tablaStock.apiEvent({
      type: API.setTableClass,
      value: name,
    });
    this.tableLotePickeado.apiEvent({
      type: API.setTableClass,
      value: name,
    });
    
  }

  cerrar() {
    this.activeModal.close();
  }
  keyRow:string;
  eventEmittedGeneral($event): void {
    //this.clicked = JSON.stringify($event);
    console.log('$event', $event);
    var data:any = $event.value.row;
    // || $event.event == "onClick"
    if ($event.event == "onDoubleClick" || $event.event == "onClick") {
        var row = $event.value.row;
        //console.log(row);
        this.keyRow = row.key;
        this.seleccionaRow(row);

    }

  
  }

  async seleccionaRow(item:DocumentoMarketingDetalle)
  {
    var query = this.queryLoteStock + " WHERE T0.[Quantity] > 0 AND T0.[ItemCode] = '" + item.ItemCode + "' AND T0.[WhsCode] = '" + item.WhsCode + "'";
    //console.log(query);
    var data:any = await this._api.GetNotFileQuery(query);
    //console.log(data);
    this.dataStock = data.objeto;
    //console.log(this.dataStock);
    this.dataStock.forEach(element => {
        element.CantidadSeleccionada = 0;
    });

    //this.tablaStock.apiEvent({
    //  type: API.setPaginationDisplayLimit,
    //  value: 5,
    //});

    //this.pagination.count = this.dataStock.length;
    //this.pagination = { ...this.pagination };


   

    this.dataPicking = [...item.Serie];

  }

  async agregaDatosLote()
  {
    //console.log(this.dataStock);
    
    var vueltaStock:number = 0;
    await this.dataStock.forEach(async element => {
        if (element.CantidadSeleccionada > 0) {
            var existeLinea:boolean = false;

            var vuelta:number = 0;
            this.dataPicking.forEach(async element2 => {
                if (element.DistNumber == element2.SerialNumber) {
                    existeLinea = true;

                    element2.Quantity = element2.Quantity + element.CantidadSeleccionada;
              
                }

                vuelta++;
            });

            if (!existeLinea) {
                this.dataPicking = [...this.dataPicking,
                {
                    SerialNumber:element.DistNumber,
                    LineNum:-1,
                    Quantity: element.CantidadSeleccionada,
                    SysNumber: element.SysNumber
                }]
                
            }


            element.CantidadSeleccionada = 0;
         
           

        }

       

        vueltaStock++;
    });

    //console.log(this.dataStock)
    //console.log(this.dataPicking)
    this.actualizaPicking();
    
    this.actualizaStock();

    //actualiza lote en detalle real
    this.actualizaLoteDetalle();
  }

  async actualizaLoteDetalle()
  {


    this.dataGeneral = [
        ...this.dataGeneral.map((obj, index) => {

          if (obj.key == this.keyRow) {
            //console.log(this.dataPicking);
            //console.log(item);
            obj.Serie = [...this.dataPicking];

            obj.CantidadPendiente = obj.Quantity;
            obj.CantidadPickeada = 0;
            obj.Lote.forEach(element2 => {
              obj.CantidadPickeada += element2.Quantity;
            });
            obj.CantidadPendiente = obj.CantidadPendiente - obj.CantidadPickeada;
            
            return {...obj}
          }
          
          return obj;
        }),
      ];

  }

  async actualizaData(rowIndex)
  {
    this.data = [
        ...this.data.map((obj, index) => {
          if (index === rowIndex) {
            //console.log(item);
            
            return {...obj}
          }
          return obj;
        }),
      ];
  }

  actualizaStock()
  {
    this.dataStock = [
        ...this.dataStock.map((obj, index) => {
       
            //console.log(item);
            
            return {...obj}
          
        }),
      ];
  }

  actualizaPicking()
  {
    this.dataPicking = [
        ...this.dataPicking.map((obj, index) => {

            return {...obj}
   
  
        }),
      ];
  }


  eliminaRowPicking(rowIndex: number): void {

    Swal.fire({
      title: "¿Está seguro que desea eliminar serie seleccionada?",
      showDenyButton: true,
      showCancelButton: false,
      denyButtonText: "Cerrar",
      confirmButtonText: "Si",
    
    }).then( async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        
        this.dataPicking = [...this.dataPicking.filter((_v, k) => k !== rowIndex)];

        this.actualizaPicking();
        this.actualizaLoteDetalle();
       
      } else if (result.isDenied) {
        //Swal.fire('Changes are not saved', '', 'info')
      }
    })
  
  }


  Seleccionar()
  {
    this.activeModal.close(this.dataGeneral);
  }
}