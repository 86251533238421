import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2'
import {MongoService} from '../../../app/api/mongo.service';

@Component({
  selector: 'app-cambio-pw',
  templateUrl: './cambio-pw.component.html',
  styleUrls: ['./cambio-pw.component.scss']
})
export class CambioPwComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private _apiMongo: MongoService
  ) { }

  datos = {
    "pw_antigua": "",
    "pw_nueva": "",
    "pw_nueva2": "",
    "RUT": ""
  }

  ngOnInit() {

  }

  cerrar() {
    this.activeModal.close();
  }

  async actualizarPw() {
    if (this.datos.pw_nueva == this.datos.pw_nueva2) {
      var datosActualizados:any = await this._apiMongo.actualizarContrasena(this.datos);
      if (datosActualizados.estado) {
        // Swal.fire("Mensaje", datosActualizados.mensaje, "success");
        Swal.fire({
          "title": "Mensaje",
          "text": datosActualizados.mensaje,
          "showConfirmButton": true,
          "confirmButtonText": "Ok",
          "showCloseButton": false,
          "icon": "success",
        }).then((result) => {
          if(result.isConfirmed){
            this.cerrar();
          }
        })
      }
      else {
        Swal.fire("Mensaje", datosActualizados.mensaje, "error");
      }
    } 
    else {
      Swal.fire("Mensaje", "Las contraseña nueva y su confirmación deben coincidir", "error");
    }
  }
}
