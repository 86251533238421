import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import {NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {CorreoAddEditComponent} from './addEdit'
import { Columns, Config, DefaultConfig } from 'ngx-easy-table';
import {IntranetoldService} from './.././/../../api/intranetold.service'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-correo-etapa',
  templateUrl: './correo-etapa.component.html',
  styleUrls: ['./correo-etapa.component.sass']
})
export class CorreoEtapaComponent implements OnInit {

  constructor(
    private modalService: NgbModal,
    private _api:IntranetoldService
  ) { }
  public configuration: Config;
  public columns: Columns[];
  etapas:any;
  @ViewChild('frmAccion', { static: true }) frmAccion: TemplateRef<any>;
  @ViewChild('frmEtapa', { static: true }) frmEtapa: TemplateRef<any>;
  
  data:any;
  ngOnInit(): void {
    this.columns = [
      { key: 'Etapa', title: "Etapa", cellTemplate: this.frmEtapa, searchEnabled: false },
      { key: 'Correo', title: 'Correo' },
      { key: 'action', title: 'Acción', cellTemplate: this.frmAccion, searchEnabled: false },
    ];

    this.configuration = { 
      ...DefaultConfig,
      searchEnabled: true,
      detailsTemplate: true,
      paginationRangeEnabled: false,
     
      horizontalScroll: true,
      resizeColumn: false,
      fixedColumnWidth: true,
   };
   this.obtieneTablasSAP();
    this.cargaCorreos();


  
   this.modeloNuevo()


  }

  buscaEtapa(id)
  {
    //console.log(id);
    //console.log(this.etapas);
    var nombre:string = "";
    this.etapas.forEach(element => {
      if (Number(element.Num) ==  Number(id)) {
        nombre = element.Descript;
      }
    });
    return nombre;
  }

  async obtieneTablasSAP()
  {
    var objeto = "SELECT * FROM [OOST]";

    var data:any = await this._api.GetNotFileQuery(objeto);
    this.etapas = data.objeto;
    //console.log(this.etapas);

  }



  async cargaCorreos()
  {
    var data:any = await this._api.getAllEtapaCorreo();
    this.data = data.objeto;
  }
  nuevo:any;
  modeloNuevo()
  {
    this.nuevo = 
      {
        "_id": null
        ,"Etapa": null
        ,"Correo": null
      }
  }
  async editar(item, titulo = "Editar perfil", rowIndex)
  {
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size:""
    };
    const modalRef = this.modalService.open(CorreoAddEditComponent, ngbModalOptions);
    modalRef.componentInstance.data = item;
    modalRef.componentInstance.titulo = titulo;
    modalRef.componentInstance.etapas = this.etapas;
    
    modalRef.componentInstance.modalReference = modalRef;
    modalRef.result.then(async (data) => {
      // on close
      //console.log("opt1", data);
      if (data) {
        var datos:any = await this._api.AddEditEtapaCorreo(data);
        this.cargaCorreos();
        //console.log("hacer algo", data);
       
        if (datos.estado) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: datos.mensaje,
            showConfirmButton: false,
            timer: 1500
          });
        }
        else
        {
          Swal.fire(datos.mensaje, '', 'error');
        }
      }
      this.modeloNuevo();
      
    }, (reason) => {
      //this.editar(item);
    });
  }
  async eliminar(item)
  {
    var etapa = this.buscaEtapa(item.Etapa);
    Swal.fire({
      title: "¿Está seguro que desea eliminar el Correo de la etapa (" + etapa + ")?",
      showDenyButton: true,
      showCancelButton: false,
      denyButtonText: "Cerrar",
      confirmButtonText: "Si",
    
    }).then( async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        var respuesta:any = await this._api.EliminaEtapaCorreo(item);

        if (respuesta.estado) {
          this.cargaCorreos();
          Swal.fire(respuesta.mensaje, '', 'success');
        }
        else
        {
          Swal.fire(respuesta.mensaje, '', 'error');
        }
       
      } else if (result.isDenied) {
        //Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }

}
