<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Lista modelos de aprobación</h4>

  <button type="button" class="close" aria-label="Close" (click)="cerrar()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12">

        
      <ngx-table #tabla [configuration]="configuration"
      [data]="obj.APROBACION"
      [columns]="columns">

    </ngx-table>
    <ng-template #frmComentario let-row let-rowIndex="rowIndex">
      <div class="input-group input-group-sm" >
        <input type="text" class="form-control form-control-sm" [(ngModel)]="row.MENSAJE">
      </div>
    </ng-template>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-info btn-sm" (click)="CrearPreliminar()">Enviar a aprobación</button>
  <!--<button (click)="Seleccionar()" type="button" class="btn btn-success">Seleccionar</button>-->
</div> 