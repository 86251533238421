import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { CookieService } from 'ngx-cookie-service';
import {variableGlobal} from './../global.enum'
import md5 from 'md5-ts';
import { Console } from 'console';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class IntranetoldService {

  constructor(
    private _http: HttpClient,
  ) { }


  async loginV2(RUT: string, PASSWORD: string, enter:string = "N") {

    const pass = md5(PASSWORD);
    const body =
    {
      "estado": true,
      "mensaje": "",
      "token": environment.TOKEN,
      "objeto": {
          "RUT": RUT,
          "PASSWORD": PASSWORD,
          "pass": pass,
          "enter": enter
      }

    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/loginV2', body).toPromise();
  }
  async validaToken(token)
  {
    const body =
    {
      "token": environment.TOKEN,
      "objeto": token
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/validaToken', body).toPromise();
  }

  async getMenuAll()
  {
    const body =
    {
      "token": environment.TOKEN
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/getMenuAll', body).toPromise();
  }
  async AddEditMenu(objeto:any)
  {
    const body =
    {
      "token": environment.TOKEN,
      "objeto": objeto
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/AddEditMenu', body).toPromise();
  }
  async EliminaMenu(objeto:any)
  {
    const body =
    {
      "token": environment.TOKEN,
      "objeto": objeto
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/EliminaMenu', body).toPromise();
  }

  async getQueryAll()
  {
    const body =
    {
      "token": environment.TOKEN
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/getQueryAll', body).toPromise();
  }
  async AddEditQuery(objeto:any)
  {
    const body =
    {
      "token": environment.TOKEN,
      "objeto": objeto
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/AddEditQuery', body).toPromise();
  }
  async EliminaQuery(objeto:any)
  {
    const body =
    {
      "token": environment.TOKEN,
      "objeto": objeto
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/EliminaQuery', body).toPromise();
  }

  async getMenuAccesoAll()
  {
    const body =
    {
      "token": environment.TOKEN
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/getMenuAccesoAll', body).toPromise();
  }
  async AddEditMenuAcceso(objeto:any)
  {
    const body =
    {
      "token": environment.TOKEN,
      "objeto": objeto
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/AddEditMenuAcceso', body).toPromise();
  }
  async EliminaMenuAcceso(objeto:any)
  {
    const body =
    {
      "token": environment.TOKEN,
      "objeto": objeto
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/EliminaMenuAcceso', body).toPromise();
  }

  async getCarritoAll()
  {
    const body =
    {
      "token": environment.TOKEN
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/getCarritoAll', body).toPromise();
  }
  async AddEditCarrito(objeto:any)
  {
    const body =
    {
      "token": environment.TOKEN,
      "objeto": objeto
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/AddEditCarrito', body).toPromise();
  }
  async EliminaCarrito(objeto:any)
  {
    const body =
    {
      "token": environment.TOKEN,
      "objeto": objeto
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/EliminaCarrito', body).toPromise();
  }
  async EliminaCarritoAll(objeto:any)
  {
    const body =
    {
      "token": environment.TOKEN,
      "objeto": objeto
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/EliminaCarritoAll', body).toPromise();
  }

  async getPerfilAll()
  {
    const body =
    {
      "token": environment.TOKEN
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/getPerfilAll', body).toPromise();
  }
  async AddEditPerfil(objeto:any)
  {
    const body =
    {
      "token": environment.TOKEN,
      "objeto": objeto
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/AddEditPerfil', body).toPromise();
  }
  async EliminaPerfil(objeto:any)
  {
    const body =
    {
      "token": environment.TOKEN,
      "objeto": objeto
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/EliminaPerfil', body).toPromise();
  }

  async getMenuPadreHijo(PERFIL)
  {
    const body =
    {
      "token": environment.TOKEN,
      "objeto": {"PERFIL":PERFIL }
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/getMenuPadreHijo', body).toPromise();
  }
  async getAllLogin()
  {
    const body =
    {
      "token": environment.TOKEN
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/getAllLogin', body).toPromise();
  }
  async AddEditLogin(objeto:any)
  {
    const body =
    {
      "token": environment.TOKEN,
      "objeto": objeto
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/AddEditLogin', body).toPromise();
  }
  async EditPass(objeto:any)
  {
    const body =
    {
      "token": environment.TOKEN,
      "objeto": objeto
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/EditPass', body).toPromise();
  }

  async EliminaLogin(objeto:any)
  {
    const body =
    {
      "token": environment.TOKEN,
      "objeto": objeto
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/EliminaLogin', body).toPromise();
  }

  async getAllVentaJefe()
  {
    const body =
    {
      "token": environment.TOKEN
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/getAllVentaJefe', body).toPromise();
  }
  async AddEditVentaJefe(objeto:any)
  {
    const body =
    {
      "token": environment.TOKEN,
      "objeto": objeto
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/AddEditVentaJefe', body).toPromise();
  }
  async EliminaVentaJefe(objeto:any)
  {
    const body =
    {
      "token": environment.TOKEN,
      "objeto": objeto
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/EliminaVentaJefe', body).toPromise();
  }

  async getAllEtapaCorreo()
  {
    const body =
    {
      "token": environment.TOKEN
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/getAllEtapaCorreo', body).toPromise();
  }
  async AddEditEtapaCorreo(objeto:any)
  {
    const body =
    {
      "token": environment.TOKEN,
      "objeto": objeto
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/AddEditEtapaCorreo', body).toPromise();
  }
  async EliminaEtapaCorreo(objeto:any)
  {
    const body =
    {
      "token": environment.TOKEN,
      "objeto": objeto
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/EliminaEtapaCorreo', body).toPromise();
  }


  async getAlllogOportunidad()
  {
    const body =
    {
      "token": environment.TOKEN
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/getAlllogOportunidad', body).toPromise();
  }
  async AddEditlogOportunidad(objeto:any)
  {
    const body =
    {
      "token": environment.TOKEN,
      "objeto": objeto
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/AddEditlogOportunidad', body).toPromise();
  }
  async EliminalogOportunidad(objeto:any)
  {
    const body =
    {
      "token": environment.TOKEN,
      "objeto": objeto
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/EliminalogOportunidad', body).toPromise();
  }

  async getAllActividadTipo()
  {
    const body =
    {
      "token": environment.TOKEN
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/getAllActividadTipo', body).toPromise();
  }
  async AddEditActividadTipo(objeto:any)
  {
    const body =
    {
      "token": environment.TOKEN,
      "objeto": objeto
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/AddEditActividadTipo', body).toPromise();
  }
  async EliminaActividadTipo(objeto:any)
  {
    const body =
    {
      "token": environment.TOKEN,
      "objeto": objeto
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/EliminaActividadTipo', body).toPromise();
  }

  async GetFileQuery(objeto:any)
  {
    const body =
    {
      "token": environment.TOKEN,
      "objeto": objeto
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/GetFileQuery', body).toPromise();
  }

  async GetNotFileQuery(objeto:any)
  {
    const body =
    {
      "token": environment.TOKEN,
      "objeto": objeto
    }

    console.log("body",body);
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/GetNotFileQuery', body).toPromise();
  }

  async EnvioCorreo(objeto:any)
  {
    const body =
    {
      "token": environment.TOKEN,
      "objeto": objeto
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/EnvioCorreo', body).toPromise();
  }

  async CargarFlete(objeto:any)
  {
    const body =
    {
      "token": environment.TOKEN,
      "objeto": objeto
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/CargarFlete', body).toPromise();
  }
  async GetMetodoPago(objeto:any)
  {
    const body =
    {
      "token": environment.TOKEN,
      "objeto": objeto
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/GetMetodoPago', body).toPromise();
  }

  async GeneraPagoDoucmentoRelacionado(objeto:any)
  {
    const body =
    {
      "token": environment.TOKEN,
      "objeto": objeto
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/GeneraPagoDoucmentoRelacionado', body).toPromise();
  }

  async EnviaDocumentoAprobacion(objeto:any)
  {
    const body =
    {
      "token": environment.TOKEN,
      "objeto": objeto
    }
    return this._http.post(environment.RUTA_API + '/api/INTRANETV1/EnviaDocumentoAprobacion', body).toPromise();
  }

  async generarBusinessPartnerModelCrear(objeto:any)
  {
    const body =
    { ...objeto
    }
    console.log(body);
    return this._http.post(environment.RUTA_API + '/api/BP/generarBusinessPartnerModelCrear', body).toPromise();
  }

  async generarBusinessPartnerModel(objeto:any)
  {
    const body =
    { ...objeto
    }
    console.log(body);
    return this._http.post(environment.RUTA_API + '/api/BP/generarBusinessPartnerModel', body).toPromise();
  }

  async AgregarContactoModelo(objeto:any)
  {
    const body =
    { ...objeto
    }
    return this._http.post(environment.RUTA_API + '/api/BP/AgregarContactoModelo', body).toPromise();
  }

  async ActualizarContactoModelo(objeto:any)
  {
    const body =
    { ...objeto
    }
    return this._http.post(environment.RUTA_API + '/api/BP/ActualizarContactoModelo', body).toPromise();
  }


}


