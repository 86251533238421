import { Component, EventEmitter, Output, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Columns, Config, DefaultConfig, APIDefinition, API } from 'ngx-easy-table';
import { IntranetoldService } from 'src/app/api/intranetold.service';
import { variableGlobal } from 'src/app/global.enum';
@Component({
  selector: 'app-buscar-card-code',
  templateUrl: './buscar-card-code.component.html',
  styleUrls: ['./buscar-card-code.component.css']
})
export class BuscarCardCodeComponent implements OnInit {

  public configuration: Config;
  public columns: Columns[];
  @ViewChild('table', { static: true }) table: APIDefinition;
  @ViewChild('frmAccion', { static: true }) frmAccion: TemplateRef<any>;

  listCardCode:any;
  data = [];
  cardcode:any;



  private setClass(name: string): void {
    this.table.apiEvent({
      type: API.setTableClass,
      value: name,
    });
  }

  constructor(
      public activeModal: NgbActiveModal,
      private _api: IntranetoldService,
    ) { }

  ngOnInit(): void {
    this.cargaInicial();
  }



  traerCardCode($event): void {
    //this.clicked = JSON.stringify($event);
    //console.log('$event', $event);
    var data:any = $event.value.row;
    // || $event.event == "onClick"
    if ($event.event == "onDoubleClick") {
      //console.log($event);

      this.cardcode = data.CardCode;
      this.activeModal.close(this.cardcode);
    }


  }

  async cargaInicial(){

    let queryOCRD = await this.obtieneQueryMongo("OCRD");

    this.listCardCode = await this._api.GetNotFileQuery(queryOCRD);
    this.listCardCode = this.listCardCode.objeto;


    this.data = this.listCardCode;

    this.columns = [

      { key: 'CardCode', title: 'CardCode' },
      { key: 'CardName', title: 'CardName' },
      { key: '', title: '', searchEnabled: false,cellTemplate: this.frmAccion },

    ];



    console.log(this.listCardCode);

    this.configuration = {
      ...DefaultConfig,
      searchEnabled: true,
      detailsTemplate: true,
      paginationRangeEnabled: true,
      paginationEnabled: true,
      horizontalScroll: true,
      resizeColumn: false,
      orderEnabled: true,
      rowReorder: false,
      columnReorder: false,
      fixedColumnWidth: true,
    };


    if (variableGlobal.ESTILO_EASY_TABLE) {
      this.setClass(variableGlobal.ESTILO_EASY_TABLE);
    }


  }

  @Output() onSave = new EventEmitter();

  async obtieneQueryMongo(nombre) {
    var data: any = await this._api.getQueryAll();
    var query: string = "";
    await data.objeto.forEach((element) => {
      if (element.NOMBRE == nombre) {
        query = element.CONSULTA;
      }
    });

    return query;
  }

  guardar() {
    this.onSave.emit('Valor devuelto');
    this.cerrar();
  }

  cerrar() {
    this.activeModal.close();
  }


}
