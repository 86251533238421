//import 'bootstrap/dist/css/bootstrap.css';
//import '@fortawesome/fontawesome-free/css/all.css'; // needs additional webpack config!

import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/angular'; // useful for typechecking
import esLocale from '@fullcalendar/core/locales/es';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { Calendar } from '@fullcalendar/core';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import { CalendarCommonModule } from 'angular-calendar';
import {IndesadService} from "./../../../api/indesad.service"
import {evento} from "./../../../model/Indesad"


@Component({
  selector: 'app-Calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.sass']
})
export class CalendarioComponent implements OnInit {
  @ViewChild('calendar', { static: false }) calendarComponent: CalendarCommonModule; // the #calendar in the template

  constructor(
    private _indesad:IndesadService
  ) { }
  ngOnInit(): void {
    this.cargaInicial();
  }

  eventosArreglo:any;
  async cargaInicial()
  {
    var datos:any = await this._indesad.obtenerEventoCalendario();
    this.eventosArreglo = datos.resultado;
    //console.log(this.eventosArreglo);
    this.calendarOptions.events = this.eventosArreglo;
    
  }

  
  calendarOptions: CalendarOptions = {
    //plugins: [ interactionPlugin   ],
    timeZone:"America/Santiago",
    themeSystem: 'darkly',
    headerToolbar: {
      start: 'title', // will normally be on the left. if RTL, will be on the right
      center: '',
      end: 'dayGridMonth,dayGridWeek,dayGridDay today prev,next' // will normally be on the right. if RTL, will be on the left
    },
    buttonIcons:{
      prev: 'chevron-left',
      next: 'chevron-right',
      prevYear: 'chevrons-left', // double chevron
      nextYear: 'chevrons-right' // double chevron
    },
    firstDay:1,
    locale:esLocale,
    initialView: 'dayGridMonth',

    weekNumbers: false,
    dayMaxEvents: true, // allow "more" link when too many events

    //dateClick: this.handleDateClick.bind(this), // bind is important!
    eventClick: this.eventos.bind(this),
    select:this.eventos.bind(this),
    selectable:true,
    selectMirror:true,
    dayMaxEventRows:true,

    
  };

  holamundo(arg)
  {
    console.log(arg);
  }

  eventos(arg)
  {
    console.log(arg);
  }

  handleDateClick(arg) {
    console.log(arg);
    //alert('date click! ' + arg.dateStr)
  }
}