import { Component, OnInit, ViewChild, TemplateRef, ChangeDetectionStrategy } from '@angular/core';
import {NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Columns, Config, DefaultConfig, API, APIDefinition } from 'ngx-easy-table';
import {IntranetoldService} from './../../../api/intranetold.service'
import { StockComercialDetalleItemBodegaComponent } from './detalleArticuloBodega';
import {variableGlobal} from "../../../global.enum";
import Swal from 'sweetalert2'
import { ExportToCsv } from 'export-to-csv';
import { NgxIndexedDBService } from 'ngx-indexed-db';

@Component({
  selector: 'app-stock-comercial',
  templateUrl: './stock-comercial.component.html',
  styleUrls: ['./stock-comercial.component.sass','./stock-comercial.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockComercialComponent implements OnInit {

  constructor(
    private _api:IntranetoldService,
    private modalService: NgbModal,
    private _dbService: NgxIndexedDBService,

  ) { }
  @ViewChild('table', { static: true }) table: APIDefinition;
  data:any = [];
  dataCopia:any = [];
  OPLN:any;
  listaPrecio:string = '4';
  listaCategorias:any;
  categoriaSeleccionada:string = "";
  listaFamilia:any;
  familiaSeleccionada:string = "";
  listaSubFamilia:any;
  listaPrecioSeleccionada:string = "4";
  subFamiliaSeleccionada:string = "";
  public configuration: Config;
  public columns: Columns[];

  carrito:any = [];
  carritoCantidad:any;
  carritoModal:any;
  carritoTotalNeto:any = null;
  carritoTotalBruto:any = null;


  mensajeAlert = Swal.mixin({
    toast: true,
    position: 'bottom-right',
    iconColor: 'white',
    customClass: {
      popup: 'colored-toast'
    },
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true
  })

  borrarItemCarrito(index){

    this.carrito.splice(index,1);

    this.actualizarCartTotal();

  }

  addCart(row:any){

    const maxLength = 20;

    row.ItemName = row.ItemName.substring(0, maxLength);

    this.carrito.push(row);

    this.mensajeAlert.fire({
      icon: 'success',
      title: row.ItemName+' agregado correctamente.'
    })

    this.actualizarCartTotal();

  }

  actualizarCartTotal(){

    this.carritoCantidad = this.carrito.length;

    this.carritoTotalNeto = null;

    this.carritoTotalBruto = null;

    this.carrito.forEach(element => {
      this.carritoTotalNeto = this.carritoTotalNeto + element.Price;
    });

    this.carrito.forEach(element => {
      this.carritoTotalBruto = this.carritoTotalBruto + element.PriceBruto;
    });

  }

  verCart(){
    this.carritoModal = !this.carritoModal;
  }




  async ngOnInit(): Promise<void> {

    await this.buscaUsuario();

    this.carritoModal = false;


  }

  login:any;
  usuarios:any;
  usuarioON:any;
  perfilUsuario:any;

  async buscaUsuario(){
    await this._dbService.getByID(variableGlobal.DB_TABLE_GENERAL, variableGlobal.DB_TABLE_GENERAL_id_login).subscribe(
      async item => {
        var itemx:any = item;
        this.login = itemx.datos.login;
    
        console.log("LOGIN ", this.login);
    
    
        var res:any = await this._api.getAllLogin();
        if(res.estado)
        {
          this.usuarios = [...res.objeto];
          this.usuarios = this.usuarios.filter( x => x._id == this.login._id);
          this.usuarioON = this.usuarios[0];
    
          await this.cargaDatosClientes();
    
          this.perfilUsuario = JSON.parse(localStorage.getItem("PERFILJSON"));

          console.log("PERFIL USUARIO",this.perfilUsuario);

        }
    
        console.log("usuarioON", this.usuarioON);

        await this.cargaTabla();

    
      }
    )
  }

  nombreConsulta:string = "ListaClientes"

  async cargaDatosClientes(){
    var data:any = await this._api.getQueryAll();
    var query:string = "";
    await data.objeto.forEach(element => {
      if (element.NOMBRE == this.nombreConsulta) {
        query = element.CONSULTA;
      }
    });

    data = await this._api.GetNotFileQuery(query);
    let clientes = [...data.objeto];

    console.log("usuarioON antes de for clientes",this.usuarioON);
    
    clientes.forEach(cliente => {
      this.usuarioON.CLIENTES.forEach(onCliente => {
        if (cliente.CardCode == onCliente.CardCode) {
          onCliente.PriceList = cliente.ListNum;
        }
      });
    });

  }

  verificarPerfilCliente(){

    let perfil = localStorage.getItem("USER_ROL");

    if (perfil === "Cliente") {
      // Obtener una lista de los PriceList en UsuarioON.CLIENTES
      const priceLists = this.usuarioON.CLIENTES.map(cliente => cliente.PriceList);
  
      this.OPLN = this.OPLN.filter(item => priceLists.includes(item.ListNum));
      console.log("OPLN",this.OPLN);
      console.log("this.usuarioON.CLIENTES",this.usuarioON.CLIENTES);
      
    }

  }
  
  @ViewChild('frmItemCode', { static: true }) frmItemCode: TemplateRef<any>;
  @ViewChild('frmPrice', { static: true }) frmPrice: TemplateRef<any>;
  @ViewChild('cart', { static: true }) cart: TemplateRef<any>;

  // SE muestra este mensaje durante mucho tiempo, solucionar
  async cargaTabla()
  {
    Swal.fire({
      title: 'Espere por favor!',
      html: 'Se esta cargando stock',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      didOpen: () => {
          Swal.showLoading()
      }
    });
    this.columns = [
      { key: '', title: "", cellTemplate: this.cart,searchEnabled:false },
      { key: 'ItemCode', title: "Codigo", cellTemplate: this.frmItemCode },
      { key: 'ItemName', title: 'Descripción' },
      { key: 'ItmsGrpNam', title: 'Categoria' },
      { key: 'OnHand', title: 'En stock' },
      { key: 'IsCommited', title: 'NV' },
      { key: 'OnOrder', title: 'Por llegar' },
      { key: 'Available', title: 'Disponible' },
      { key: 'Price', title: 'Precio', cellTemplate: this.frmPrice },
    ];

    this.configuration = {
      ...DefaultConfig,
      searchEnabled: true,
      detailsTemplate: true,
      paginationRangeEnabled: false,

      horizontalScroll: true,
      resizeColumn: false,
      fixedColumnWidth: true,
   };

    var data:any = await this._api.getQueryAll();

    console.log(data);
    console.log(this.dataCopia);
    await data.objeto.forEach(element => {
      if (element.NOMBRE == "listaPrecios") {
        query = element.CONSULTA;
      }
    });
    var datos:any = await this._api.GetNotFileQuery(query);
    this.OPLN = datos.objeto;

    console.log("OPLN",this.OPLN);

    await this.verificarPerfilCliente();

    let perfil = localStorage.getItem("USER_ROL");

    if (perfil === "Cliente") {
      this.listaPrecio = this.OPLN[0].ListNum;
    }

    
    var query:string = "";

    await data.objeto.forEach(element => {
      // if (element.NOMBRE == "stockComercial") {
        if(element.NOMBRE == "stockComercialCLP") { //stockComercial
        query = element.CONSULTA;
    //    query.replace('REEMPLAZALISTA',this.listaPrecioSeleccionada);
      }
    });
    query = query.replace("REEMPLAZAPLIST", this.listaPrecio);
    console.log("query",query);

    this.data = await this._api.GetNotFileQuery(query);
    console.log("data",this.data);
    this.dataCopia = this.data.objeto;

    

    // console.log("PRECIO CLP: "+ this.dataCopia[120].Price);



    let collator = new Intl.Collator()
    var distinct = this.dataCopia
                 .map(item => item.ItmsGrpNam)
                 .filter((value, index, self) => self.indexOf(value) === index);
    distinct = distinct.sort((a,b) => collator.compare(a,b));
    // distinct = distinct.sort((a,b) => a.localeCompare(b));
    this.listaCategorias = distinct;

    distinct = this.dataCopia
                 .map(item => item.U_IND_CATEGORIA2)
                 .filter((value, index, self) => self.indexOf(value) === index);
    distinct = distinct.sort((a,b) => collator.compare(a,b));
    // distinct = distinct.sort((a,b) => a.localeCompare(b));
    this.listaFamilia = distinct;
    distinct = this.dataCopia
                 .map(item => item.U_IND_CATEGORIA3)
                 .filter((value, index, self) => self.indexOf(value) === index);

    distinct = distinct.sort((a,b) => collator.compare(a,b));
    // distinct = distinct.sort((a,b) => a.localeCompare(b)); //error posible acá
    this.listaSubFamilia = distinct;

    this.data = [...this.dataCopia];

    //console.log(this.data);
    if(variableGlobal.ESTILO_EASY_TABLE)
    {
      this.setClass(variableGlobal.ESTILO_EASY_TABLE);
    }

    Swal.close();
    
  }

  private setClass(name: string): void {
    this.table.apiEvent({
      type: API.setTableClass,
      value: name,
    });
  }
  AbrirDetalleItem(item)
  {
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size:"xl",
      modalDialogClass:variableGlobal.CLASS_MODAL_BG
    };
    const modalRef = this.modalService.open(StockComercialDetalleItemBodegaComponent, ngbModalOptions);
    modalRef.componentInstance.ItemCode = item.ItemCode;
    modalRef.componentInstance.modalReference = modalRef;
    modalRef.result.then((data) => {


    }, (reason) => {
      //this.editar(item);
    });
  }

  limpiar()
  {
    this.data = [...this.dataCopia];
    //this.listaPrecio = 1;
    this.categoriaSeleccionada = "";
    this.familiaSeleccionada = "";
    this.subFamiliaSeleccionada = "";
  }

  filtro()
  {
    this.data = [...this.dataCopia];
    //ItmsGrpNam              categoriaSeleccionada
    //U_IND_CATEGORIA2        familiaSeleccionada
    //U_IND_CATEGORIA3        subFamiliaSeleccionada
    if (this.categoriaSeleccionada) {
      this.data = this.data.filter((_) => _.ItmsGrpNam == this.categoriaSeleccionada);
    }
    if (this.familiaSeleccionada) {
      this.data = this.data.filter((_) => _.U_IND_CATEGORIA2 == this.familiaSeleccionada);
    }
    if (this.subFamiliaSeleccionada) {
      this.data = this.data.filter((_) => _.U_IND_CATEGORIA3 == this.subFamiliaSeleccionada);
    }
  }
  async cambiaPrecio()
  {
    Swal.fire({
        title: 'Espere por favor!',
        html: 'Se esta cargando lista de precio',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
        didOpen: () => {
            Swal.showLoading()
        }
    });
    var data:any = await this._api.getQueryAll();
    var query:string = "";
    await data.objeto.forEach(element => {
      if (element.NOMBRE == "stockComercialCLP") { //stockComercial
        query = element.CONSULTA;
      }
    });
    query = query.replace("REEMPLAZAPLIST", this.listaPrecio);
    console.log(query);
    var datos:any = await this._api.GetNotFileQuery(query);
    this.data = datos.objeto;
    this.dataCopia = [...this.data];
    this.filtro();
    Swal.close();

  }

  exportToCSV(): void {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(this.data);
  }

  filteredItems:any = [];
  filtroGlobal:string = "";


  filterData(query: string, items: any[]): any[] {
    console.log("items",items)
    const normalizedQuery = this.filtroGlobal.toLowerCase();
    const keywords = normalizedQuery.split(/\s+/); // Dividir consulta en palabras clave
  
    if (keywords.length === 0) {
      return items;
    }
  
    return items.filter((item) => {
      return keywords.every((keyword) => {
        return Object.values(item).some((value) => {
          if (typeof value === "string") {
            return value.toLowerCase().includes(keyword);
          }
          return false;
        });
      });
    });
  }

}
