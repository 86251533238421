import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-preasignar-pedido',
  templateUrl: './preasignar-pedido.component.html',
  styleUrls: ['./preasignar-pedido.component.css']
})
export class PreasignarPedidoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
