import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {IntranetoldService} from '../../../api/intranetold.service'
import { Columns, Config, DefaultConfig } from 'ngx-easy-table';
import {DocumentoMarketingDetalle, DocumentoMarting} from "./../../../model/ventaModel"
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


@Component({
  selector: 'app-selectedDireccion',
  templateUrl: './listaDireccion.html',
  styleUrls: ['./venta.component.sass']
})
export class listaDireccionComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private _api: IntranetoldService
  ) { }

  data = {
      "Street": "",
      "City":"",
      "ZipCode":"",
      "County":"",
      "State":"",
      "Country": "CL"
  }
  ngOnInit(): void {
    console.log("data",this.data)
    if (!this.data.Country)
    {
        this.data.Country = "CL";
    }
    this.cargaInicial();
  }


  OCRY:any;
  OCST:any;
  async cargaInicial()
  {


   
    var objeto = "SELECT [Code], [Name] FROM [OCRY]";
    var dato:any = await this._api.GetNotFileQuery(objeto);
    this.OCRY = dato.objeto;

    objeto = "SELECT * FROM [OCST] WHERE [Country] = '" + this.data.Country + "'";
    dato = await this._api.GetNotFileQuery(objeto);
    this.OCST = dato.objeto;
    //console.log(this.OCST);
  
  }

  cerrar() {
    this.activeModal.close();
  }
  eventEmitted($event): void {
    //this.clicked = JSON.stringify($event);
    //console.log('$event', $event);
    var data:any = $event.value.row;
    // || $event.event == "onClick"
    if ($event.event == "onDoubleClick") {
      //console.log($event);

     
      
      
    }
    if ($event.event == "onDoubleClick") {
      //console.log(data);
      //console.log(this.Detalle.Factor1, this.Detalle.Factor2)
      this.Seleccionar();      
    }
  
  }
  Seleccionar()
  {
    this.activeModal.close(this.data);
  }
}