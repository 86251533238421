import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { data } from 'jquery';
import { IntranetoldService } from 'src/app/api/intranetold.service';
import { SapService } from 'src/app/api/sap.service';
import Swal from 'sweetalert2';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { variableGlobal } from 'src/app/global.enum';
import { BuscarCardCodeComponent } from '../../buscar-card-code/buscar-card-code.component';
import { Columns, Config, DefaultConfig, APIDefinition, API } from 'ngx-easy-table';
import { number } from '@amcharts/amcharts4/core';
import { type } from 'os';

@Component({
  selector: 'app-crear-maestro-articulo',
  templateUrl: './crear-maestro-articulo.component.html',
  styleUrls: ['./crear-maestro-articulo.component.css']
})
export class CrearMaestroArticuloComponent implements OnInit {

  @ViewChild('table', { static: true }) table: APIDefinition;
  @ViewChild('frmAccion', { static: true }) frmAccion: TemplateRef<any>;
  public configuration: Config;
  public columns: Columns[];

  cargando:any;
  data:any;
  crear:any;
  body:any;
  VinculoPrecioArticulo:any;
  grupoArticulos:any;
  listaPrecios:any;
  listaPreciosOriginal:any;
  listaFabricantes:any;
  formasEnvio:any;
  grupoAduanas:any;
  checkChangeValue:any;
  listapreciosnueva:any;
  listaseleccionada:any;
  obtenerCardCode:any;
  dataTablaBodegas:any;


  constructor(
    public activeModal: NgbActiveModal,
    private _api: IntranetoldService,
    private _sap: SapService,
    private modalService: NgbModal

    ) { }


  async ngOnInit(): Promise<void> {


    await this.cargaInicial();
    await this.cargaTablaBodega();


    console.log("VER BODY",this.body)

  }

  buscarCardCode() {


    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
      size: "md",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG,
    };

    const modalRef = this.modalService.open(BuscarCardCodeComponent, ngbModalOptions);
    modalRef.componentInstance.modalReference = modalRef;

    modalRef.result.then(async (data) => {
      if (data) {
        this.body.CardCode = data
      }
    }, (reason) => {
      //this.editar(item);
    });

  }

  async cargaInicial(){

    this.cargando = true;

   this.listapreciosnueva = [  ]

    if (!this.crear) {

      this.body = this.data.resultado;
      this.body.PriceList = 1;

    }




    else{

      this.body = {
        IWeight1:null,
        Series:3,
        "ItemCode":null,
        ItemName:null,
        FrgnName:null,
        ItemType:null,
        ItmsGrpCod:null,
        UgpEntry:null,
        ListNum:null,

        CodeBars:null,
        FirmCode:null,
        ShipType:null,
        ManBtchNum:null,
        ManSerNum:null,
        MngMethod:null,
        CardCode:null,
        SuppCatNum:null,
        BuyUnitMsr:null,
        NumInBuy:null,
        PurPackMsr:null,
        PurPackUn:null,
        CstGrpCode:null,
        TotalTax:null,
        SalUnitMsr:null,
        NumInSale:null,
        SalPackMsr:null,
        SalPackUn:null,
        PriceList:1,

        QRCodeSrc:null,
        GLMethod:"C",
        InvntryUom:null,
        EvalSystem:null,
        AvgPrice:null,
        ReorderQty:null,
        MinLevel:null,
        MaxLevel:null,
        InvntItem:null,
        SellItem:null,
        PrchseItem:null,
        WTLiable:true,
        VATLiable:true,
        IndirctTax:null,
        NoDiscount:null,
        ByWh:true,
        Price:0,

        BLength1:null,
        BWidth1:null,
        BHeight1:null,
        BVolume:null,
        BVolUnit:null,
        BWeight1:null,
        PurFactor1:null,
        PurFactor2:null,
        PurFactor3:null,
        PurFactor4:null,

        SLength1:null,
        SWidth1:null,
        SHeight1:null,
        SVolume:null,
        SVolUnit:null,
        SWeight1:null,
        SalFactor1:null,
        SalFactor2:null,
        SalFactor3:null,
        SalFactor4:null,
        userFields:[

        {
          "Field": "U_IND_CATEGORIA2",
          "FieldType": null,
          "Value": "",
          "TypeHTML": null,
          "MaxLength": null,
          "Step": null,
          "Description": null
        },
        {
          "Field": "U_IND_CATEGORIA3",
          "FieldType": null,
          "Value": "",
          "TypeHTML": null,
          "MaxLength": null,
          "Step": null,
          "Description": null
        },
        {
          "Field": "U_IND_SWW",
          "FieldType": null,
          "Value": "",
          "TypeHTML": null,
          "MaxLength": null,
          "Step": null,
          "Description": null
        }

        ],
        listaPrecios:[

        ]

      };


    }

    let queryListaPrecios = await this.obtieneQueryMongo("articulosListaPrecio");

    this.listaPrecios = await this._api.GetNotFileQuery(queryListaPrecios);
    this.listaPrecios = this.listaPrecios.objeto;

    console.log(this.listaPrecios)


    let queryVinculoPrecioArticulo = await this.obtieneQueryMongo("VinculoPrecioArticulo");
    let queryGrupoArticulos = await this.obtieneQueryMongo("articulosGrupo");
    let queryListaFabricantes = await this.obtieneQueryMongo("articulosFabricantes")
    let queryformasEnvio = await this.obtieneQueryMongo('formaEnvio')
    let queryGrupoAduanas = await this.obtieneQueryMongo('GrupoAduanas')
    let queryObtenerCardCode = await this.obtieneQueryMongo('OCRD')


    this.grupoArticulos = await this._api.GetNotFileQuery(queryGrupoArticulos);
    this.grupoArticulos = this.grupoArticulos.objeto;



    this.listaFabricantes = await this._api.GetNotFileQuery(queryListaFabricantes);
    this.listaFabricantes = this.listaFabricantes.objeto;

    this.formasEnvio = await this._api.GetNotFileQuery(queryformasEnvio);
    this.formasEnvio = this.formasEnvio.objeto;

    this.grupoAduanas = await this._api.GetNotFileQuery(queryGrupoAduanas);
    this.grupoAduanas = this.grupoAduanas.objeto;

    this.VinculoPrecioArticulo = await this._api.GetNotFileQuery(queryVinculoPrecioArticulo);
    this.VinculoPrecioArticulo = this.VinculoPrecioArticulo.objeto;

    this.obtenerCardCode = await this._api.GetNotFileQuery(queryObtenerCardCode);
    this.obtenerCardCode = this.obtenerCardCode.objeto;


    if (this.crear){


    this.listaPrecios.forEach(element => {
      let modelo = {
        "ListName":element.ListName,
        "PriceList": element.ListNum,
        "Currency": element.Currency,
        "Price": element.Price
      }

      this.listapreciosnueva.push(modelo);
    });
  }

    console.log("QUERY POR VER",this.listapreciosnueva);


    if (this.crear){

      this.listapreciosnueva.forEach(element => {
        this.body.listaPrecios.push(element)
      });

    }else{
      this.listaPrecios.forEach(element => {
        this.body.listaPrecios.forEach(item => {
          if (item.PriceList == element.PriceList) {
            item.ListName = element.ListName;
          }
        });
      });
    }


    this.cargando = false;



  }

  async crearMaestroArticulo(){

    let alerta = "";

    if (!this.body.Series) {
      alerta = alerta + " " + "Número de Artículo,"
    }
    else if (!this.body.ItemCode) {
      alerta = alerta + " " + "Número de Artículo,"
    }
    else if (!this.body.ItemName) {
      alerta = alerta + " " + "Descripción,"
    }
    else if (!this.body.ItemType) {
      alerta = alerta + " " + "Clase de artículo,"
    }
    else if (!this.body.ItmsGrpCod) {
      alerta = alerta + " " + "Grupo de artículo,"
    }
    else if (!this.body.PriceList) {
      alerta = alerta + " " + "Lista de precios,"
    }


    this.body.userFields.forEach(element => {
      if (element.ListName == "U_IND_CATEGORIA2") {
        if (!element.Value) {
          alerta = alerta + " " + "Familia,"
        }
      }
    });



    this.body.userFields.forEach(element => {
      if (element.ListName == "U_IND_CATEGORIA3") {
        if (!element.Value) {
          alerta = alerta + " " + "Sub-Familia,"
        }
      }
    });


    this.body.userFields.forEach(element => {
      if (element.ListName == "U_IND_SWW") {
        if (!element.Value) {
          alerta = alerta + " " + "Código Antiguo,"
        }
      }
    });


    if (!this.body.BuyUnitMsr) {
      alerta = alerta + " " + "Nombre de unidad de medidad de compras,"
    }
    else if (!this.body.NumInBuy) {
      alerta = alerta + " " + "Artículos por unidad comprar,"
    }
    else if (!this.body.SalUnitMsr) {
      alerta = alerta + " " + "Nombre de unidad de medida,"
    }
    else if (!this.body.NumInSale) {
      alerta = alerta + " " + "Artículos por unidad de ventas,"
    }
    else if (!this.body.SalPackUn) {
      alerta = alerta + " " + "Nombre de unidad de medida de em,"
    }
    else if (!this.body.SVolume) {
      alerta = alerta + " " + "Volumen,"
    }
    else if (!this.body.SVolUnit) {
      alerta = alerta + " " + "Volumen,"
    }
    else if (!this.body.GLMethod) {
      alerta = alerta + " " + "Fijar ctas de mayor según,"
    }
    else if (!this.body.InvntryUom) {
      alerta = alerta + " " + "Nombre unid. de medida,"
    }

    else {

      console.log("VER BODY ANTES DE CREAR/EDITAR",this.body)

      console.log(await this._sap.CrearEditarMT(this.body))

    }

    if (alerta) {

      Swal.fire({
        icon: 'error',
        title: '¡Ha ocurrido un error!',
        text: 'Los campos:'+alerta+ 'son requeridos.',
      })

    }




  }

  actualizarPrecioUnidad(event){

+
    this.body.listaPrecios.forEach(element => {
      if (element.PriceList == event.target.value) {
        this.listaseleccionada = element;
      }

    });

    console.log(this.listaseleccionada)

  }

  setValue(propiedad, event: MatCheckboxChange) {
    this.body[propiedad] = event.checked ? true : false;

    console.log(this.body)
  }

  SlcLoteSerie(event){

    if (event.target.value == "0") {
      this.body.ManBtchNum = false;
      this.body.ManSerNum = false;

    }
    else if(event.target.value == "1"){
      this.body.ManBtchNum = false;
      this.body.ManSerNum = true;
    }
    else if(event.target.value == "2"){
      this.body.ManBtchNum = true;
      this.body.ManSerNum = false;
    }

  }


  async obtieneQueryMongo(nombre) {
    var data: any = await this._api.getQueryAll();
    var query: string = "";
    await data.objeto.forEach((element) => {
      if (element.NOMBRE == nombre) {
        query = element.CONSULTA;
      }
    });

    return query;
  }

  cerrar() {
    this.activeModal.close();
  }





  cargaTablaBodega(){

    if (!this.crear) {



    }


    this.columns = [

      { key: 'ItemCode', title: 'Bodega' },
      { key: 'StockValue', title: 'Stock' },

    ];

    this.configuration = {
      ...DefaultConfig,
      searchEnabled: true,
      detailsTemplate: true,
      paginationRangeEnabled: true,
      paginationEnabled: true,
      horizontalScroll: true,
      resizeColumn: false,
      orderEnabled: true,
      rowReorder: false,
      columnReorder: false,
      fixedColumnWidth: true,
    };


  }






}
