import { Component, OnInit, ViewChild, TemplateRef, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Columns, Config, DefaultConfig, API, APIDefinition } from 'ngx-easy-table';
import {IntranetoldService} from './../../../api/intranetold.service';
import {NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { StockComercialDetalleItemBodegaComponent } from './detalleArticuloBodega';


@Component({
  selector: 'app-detalleArticulo',
  templateUrl: './detalleArticulo.html',
  styleUrls: ['./stock-comercial.component.sass']
})

export class StockComercialDetalleItemComponent implements OnInit {
  @Input() anyDataForm: any;
    constructor(
    public activeModal: NgbActiveModal,
    private _api:IntranetoldService,
    private modalService: NgbModal,
    ) { 
    }
    ItemCode:string;
    data:any = [];
    public configuration: Config;
    public columns: Columns[];
    ngOnInit(): void {
        this.cargaTabla();
  
    }
    @ViewChild('frmItemCode', { static: true }) frmItemCode: TemplateRef<any>;
    async cargaTabla()
    {
        this.columns = [
            { key: 'ItemCode', title: "Codigo", cellTemplate: this.frmItemCode },
            { key: 'ItemName', title: 'Descripción' },
            { key: 'ItmsGrpNam', title: 'Categoria' },
            { key: 'U_IND_CATEGORIA2', title: 'Categoria 2' },
            { key: 'WhsCode', title: 'Bodega' },
            { key: 'SalUnitMsr', title: 'UM' },
            { key: 'OnHand', title: 'Existencia' },
            { key: 'IsCommited', title: 'NV Asignada' },
            { key: 'Disponible', title: 'Saldo Teórico' }
          ];
      
        this.configuration = { 
            ...DefaultConfig,
            searchEnabled: true,
            detailsTemplate: true,
            paginationRangeEnabled: false,
            
            horizontalScroll: true,
            resizeColumn: false,
            fixedColumnWidth: true,
        };
      
      
        var query = "SELECT T0.[ItemCode]\r\n" +
        "   ,T0.[ItemName] as [ItemName]\r\n" +
        "   ,T3.[ItmsGrpNam]\r\n" +
        "   ,T1.[U_IND_CATEGORIA2]\r\n" +
        "   ,T1.[SalUnitMsr]\r\n" +
        "   ,T0.[WhsCode]\r\n" +
        "   ,Sum(T0.[Quantity]) as [OnHand]\r\n" +
        "   ,Sum(T0.[IsCommited]) as [IsCommited],\r\n" +
        "   (IFNULL(sum(T0.[Quantity]), 0) - IFNULL(sum(T0.[IsCommited]), 0)) as [Disponible]\r\n" +
        "   FROM OIBT T0\r\n" +
        "   INNER JOIN OITM T1 ON T0.[ItemCode] = T1.[ItemCode]\r\n" +
        "   INNER JOIN OWHS T4 ON T0.[WhsCode] = T4.[WhsCode]\r\n" +
        "   INNER JOIN OITB T3 ON T1.[ItmsGrpCod] = T3.[ItmsGrpCod]\r\n" +
        "   AND IFNULL(T0.[Quantity],0)  <> 0\r\n" +
        "   AND T0.[WhsCode] NOT IN('300','300M','101','106','105','400','500','600','202')\r\n" +
        "   WHERE T0.[ItemCode] = '" + this.ItemCode + "'\r\n" +
        "   GROUP BY T0.[ItemCode],T0.[ItemName],T3.[ItmsGrpNam],T1.[U_IND_CATEGORIA2],T1.[SalUnitMsr],T0.[WhsCode]\r\n" +
        "   ORDER BY T3.[ItmsGrpNam],T1.[U_IND_CATEGORIA2],T0.[ItemCode]";
      
         
        //console.log(query);
        this.data = await this._api.GetNotFileQuery(query);
        this.data = this.data.objeto;
        //console.log(this.data);
    }
    cerrar() {
        this.activeModal.close();
    }
    AbrirDetalleItem(item)
    {
        let ngbModalOptions: NgbModalOptions = {
            backdrop : 'static',
            keyboard : false,
            size:"xl"
          };
          const modalRef = this.modalService.open(StockComercialDetalleItemBodegaComponent, ngbModalOptions);
          modalRef.componentInstance.ItemCode = item.ItemCode;
          modalRef.componentInstance.WhsCode = item.WhsCode;
          modalRef.componentInstance.modalReference = modalRef;
          modalRef.result.then((data) => {
            
        
          }, (reason) => {
            //this.editar(item);
          });
    }
   

}