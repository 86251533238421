import { Component, OnInit } from '@angular/core';
import {variableGlobal} from './../../global.enum';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { IntranetoldService } from 'src/app/api/intranetold.service';
import Swal from 'sweetalert2';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.sass']
})
export class SidebarComponent implements OnInit {

  
  constructor(
    private _dbService: NgxIndexedDBService,
    private _route: Router,
    private Location:Location,
    private route: ActivatedRoute,
    private _api:IntranetoldService,
    private _cookieService: CookieService,
  ) {

   }
   general = variableGlobal;
   menuAll:any;
   currentURL = "";
   usuarios:any;
   usuarioON:any;
   perfilUsuario:any;
   sinClienteAntes:boolean = false;
   sinCliente:boolean = false;

   login:any = {
    "NOMBRE": ""
   };
   
  async ngOnInit(): Promise<void> {

    await this.cargaInicial();
  }

  cargaInicial(){
    //console.log(this.general);

  
    this.currentURL = this.Location.path();
    this._dbService.getByID(variableGlobal.DB_TABLE_GENERAL, variableGlobal.DB_TABLE_GENERAL_id_menu_final).subscribe(
      menu => {
        var manux:any = menu;
        this.menuAll = manux.datos;

        //console.log("cargando url con esta url ", this.currentURL);
        //console.log(this.menuAll);
      }
    );

    this._dbService.getByID(variableGlobal.DB_TABLE_GENERAL, variableGlobal.DB_TABLE_GENERAL_id_login).subscribe(
      item => {
        var itemx:any = item;
        this.login = itemx.datos.login;

        //console.log("cargando url con esta url ", this.currentURL);
        //console.log(this.login);
        localStorage.setItem("USER_ROL", itemx.datos.perfil.NOMBRE);

      }
    );

   
    this._route.routeReuseStrategy.shouldReuseRoute = () => false;

    this.buscaUsuario();
  }

  async buscaUsuario() {
    await this._dbService.getByID(variableGlobal.DB_TABLE_GENERAL, variableGlobal.DB_TABLE_GENERAL_id_login).subscribe(
      async item => {
        var itemx: any = item;
        this.login = itemx.datos.login;
  
        console.log("LOGIN ", this.login);
  
        var res: any = await this._api.getAllLogin();
        if (res.estado) {
          this.usuarios = [...res.objeto];
          this.usuarios = this.usuarios.filter(x => x._id == this.login._id);
          this.usuarioON = this.usuarios[0];
  
          let perfil = localStorage.getItem("USER_ROL");
          console.log("perfil", perfil);
          console.log("this.usuarioON", this.usuarioON);
  
          this.sinClienteAntes = this.sinCliente;


          if (perfil === "Cliente") {
            if (this.usuarioON.CLIENTES.length == 0) {
              this.sinCliente = true;
              await this.mostrarSweetAlert();
            } else {
              this.sinCliente = false;
            }
          }

  
          this.perfilUsuario = JSON.parse(localStorage.getItem("PERFILJSON"));
  
          console.log("PERFIL USUARIO", this.perfilUsuario);
        }
  
        console.log("usuarioON", this.usuarioON);
      }
    );
  }
  
  async mostrarSweetAlert() {
    const result = await Swal.fire({
      title: '¡Advertencia!',
      text: 'No tienes un Cliente asignado. Por favor, contacta al soporte.',
      icon: 'warning',
      confirmButtonText: 'OK',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false
    });
  
    if (result.isConfirmed) {
      
      if (!this.sinClienteAntes && this.sinCliente) {
        this._cookieService.deleteAll();
        await this._dbService.deleteByKey(variableGlobal.DB_TABLE_GENERAL, variableGlobal.DB_TABLE_GENERAL_id_login).toPromise();
        await this._dbService.deleteByKey(variableGlobal.DB_TABLE_GENERAL, variableGlobal.DB_TABLE_GENERAL_id_menu).toPromise();
        await this._dbService.deleteByKey(variableGlobal.DB_TABLE_GENERAL, variableGlobal.DB_TABLE_GENERAL_id_menu_final).toPromise();
        await this._dbService.deleteByKey(variableGlobal.DB_TABLE_GENERAL, variableGlobal.DB_TABLE_GENERAL_id_perfiles).toPromise();
  
        window.location.href = "/login";
      }

      await this.buscaUsuario();      

    }
    else
    {

      if (!this.sinClienteAntes && this.sinCliente) {
        this._cookieService.deleteAll();
        await this._dbService.deleteByKey(variableGlobal.DB_TABLE_GENERAL, variableGlobal.DB_TABLE_GENERAL_id_login).toPromise();
        await this._dbService.deleteByKey(variableGlobal.DB_TABLE_GENERAL, variableGlobal.DB_TABLE_GENERAL_id_menu).toPromise();
        await this._dbService.deleteByKey(variableGlobal.DB_TABLE_GENERAL, variableGlobal.DB_TABLE_GENERAL_id_menu_final).toPromise();
        await this._dbService.deleteByKey(variableGlobal.DB_TABLE_GENERAL, variableGlobal.DB_TABLE_GENERAL_id_perfiles).toPromise();
  
        window.location.href = "/login";
      }

      await this.buscaUsuario();    
      
    }
  }
  cambiaURL()
  {
    //window.location.href = url;
    //this._route.navigate([url]);
    //console.log("Cambiando url");
    //window.location.reload();
    this.currentURL = this.Location.path();
    console.log(this.currentURL);

    
  }

}
