import { Component, OnInit, OnDestroy, Input,  EventEmitter} from '@angular/core';
import {IntranetoldService} from './../../../api/intranetold.service'

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-asignacionVendedoroEditAdd',
  templateUrl: './addEdit.html', 
  styleUrls: ['./asignacion-vendedor.component.sass']
})

export class AsignacionVendedorAddEditComponent implements OnInit {
  @Input() anyDataForm: any;
    constructor(
      public activeModal: NgbActiveModal,
      private _api:IntranetoldService,

      ) { 

        
      }

      data:any = {};
      titulo:string = "";
      txtButton:string = "Actualizar";
      usuarios:any;
    ngOnInit(): void {

      //console.log(this.data);
      if (this.titulo.toUpperCase().includes("NUEVO")) {
        this.txtButton = "Agregar";
      }
    }
    cerrar() {
      this.activeModal.close();
        //this.modalReference.close(this.usuario);
    }
    guardar()
    {
      this.activeModal.close(this.data);
    }
    async CambiaDato(item, tipo)
    {
      //console.log(item, tipo);
      if (tipo == "JEFE") {
        this.data.NombreJ = await this.buscaEmpleado(item);
      }
      else if (tipo == "EMPLEADO")
      {
        this.data.NombreE = await this.buscaEmpleado(item);
      }

      //console.log(this.data);

    }
    async buscaEmpleado(rut)
    {
      var nombre:string = "";
      this.usuarios.forEach( async  element => {
        if (element.RUT == rut) {
          nombre = element.NOMBRE;
        }
      });

      return nombre;
    }

}