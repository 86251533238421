import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Columns, Config, DefaultConfig, APIDefinition, API } from 'ngx-easy-table';
import { IntranetoldService } from 'src/app/api/intranetold.service';
import { variableGlobal } from 'src/app/global.enum';
import { abreTablaGenericoComponent } from '../../app/query-mongo/abreTablaGenerico';

@Component({
  selector: 'app-informe',
  templateUrl: './informe.component.html',
  styleUrls: ['./informe.component.sass']
})
export class InformeComponent implements OnInit {
  
  data:any = [];  
  Nombre:string;

  public configuration: Config;
  public columns: Columns[];

  @ViewChild('table', { static: true }) table: APIDefinition;
  
  constructor(
    private _api: IntranetoldService,
    private _activeRoute: ActivatedRoute,
    ) { }
  
  ngOnInit(): void {
  

    this.Nombre= this._activeRoute.snapshot.paramMap.get("Nombre");

    console.log(this.Nombre);
    this.columns = [];
    this.cargardatos();
    
  }
  
  async obtieneQueryMongo(nombre)
  {
    var data:any = await this._api.getQueryAll();
    var query:string = "";
    await data.objeto.forEach(element => {
      if (element.NOMBRE == nombre) {
        query = element.CONSULTA;        
      }
    });

    return query;
  }
async cargardatos(){
  var query = await this.obtieneQueryMongo(this.Nombre);
  console.log(query);
  var dato:any = await this._api.GetNotFileQuery(query);
  console.log("DATOS");
  console.log(dato);
  if (dato.estado) {
      
      console.log(dato.objeto[0]);
      var arregloNombre = Object.keys(dato.objeto[0]);
      arregloNombre.forEach(element => {
        var columna = { key: element, title: element};
        this.columns.push(columna);
      });
    
      this.data = dato.objeto;
      this.configuration = { 
        ...DefaultConfig,
        searchEnabled: true,
        detailsTemplate: true,
        paginationRangeEnabled: true,
        paginationEnabled:true,
        horizontalScroll: true,
        resizeColumn: true,
        orderEnabled: false,
        rowReorder:false,
        columnReorder:false,
        fixedColumnWidth: true,
      };
      if(variableGlobal.ESTILO_EASY_TABLE)
        this.setClass(variableGlobal.ESTILO_EASY_TABLE);

    }
  }
  private setClass(name: string): void {
    this.table.apiEvent({
      type: API.setTableClass,
      value: name,
    });    
  }
}
