import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {IntranetoldService} from '../../../api/intranetold.service'
import { Columns, Config, DefaultConfig, APIDefinition, API } from 'ngx-easy-table';
import {DocumentoMarketingDetalle, DocumentoMarting} from "./../../../model/ventaModel"
import { variableGlobal } from '../../../global.enum';
import {NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {clienteModelComponent} from "../../sap/BusinessPartnet/cliente-model"
import {editaSocioNegocioComponent} from '../socio-negocio/editaSocioNegocio';
import { NgxIndexedDBService } from 'ngx-indexed-db';


@Component({
  selector: 'app-selectedCliente',
  templateUrl: './listaCliente.html',
  styleUrls: ['./venta.component.sass']
})
export class listaClienteComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private _api: IntranetoldService,
    private modalService: NgbModal,
    private _dbService: NgxIndexedDBService,

  ) { }
  @ViewChild('table', { static: true }) table: APIDefinition;
  public configuration: Config;
  public columns: Columns[];
  Encabezado:DocumentoMarting;
  nombreConsulta:string = "ListaClientes"
  data:any;
  DesdeUserEdit:any;
  ngOnInit(): void {
    this.cargaInicial();
  }

  @ViewChild('frmPrice', { static: true }) frmPrice: TemplateRef<any>;
  @ViewChild('frmAccion', { static: true }) frmAccion: TemplateRef<any>;
  async cargaInicial()
  {

    await this.buscaUsuario();

    this.columns = [
      { key: 'CardCode', title: "Codigo"},
      { key: 'CardName', title: 'Cliente' },
      { key: 'GroupName', title: 'Categoria' },
      //{ key: 'Notes', title: 'Giro' },
      { key: 'PymntGroup', title: 'Metodo pago' },
      { key: 'SlpName', title: 'Vendedor' },
      //{ key: 'Balance', title: 'Balance', cellTemplate: this.frmPrice },
      { key: '', title: 'Acción', cellTemplate: this.frmAccion },
    ];
    this.configuration = { 
      ...DefaultConfig,
      searchEnabled: true,
      detailsTemplate: false,
      paginationRangeEnabled: false,
      horizontalScroll: true,
      resizeColumn: true,
      orderEnabled: true,
      rowReorder:false,
      columnReorder:false,
      fixedColumnWidth: false,
      selectRow:false,

      paginationEnabled:true,
      //radio:true
      //infiniteScroll:true,
      //infiniteScrollThrottleTime: 10,
      //rows:10,

   };



    await this.cargaDatosSAP();

    if(variableGlobal.ESTILO_EASY_TABLE)
      this.setClass(variableGlobal.ESTILO_EASY_TABLE);
  
  }

  login:any;
  usuarios:any;
  usuarioON:any;
  perfilUsuario:any;

  async buscaUsuario(){
    await this._dbService.getByID(variableGlobal.DB_TABLE_GENERAL, variableGlobal.DB_TABLE_GENERAL_id_login).subscribe(
      async item => {
        var itemx:any = item;
        this.login = itemx.datos.login;
    
        this.usuarioON = this.login;
        console.log("LOGIN ", this.login);
    
        
        console.log("usuarioON", this.usuarioON);
    
      }
    )
  }

  async cargaDatosSAP()
  {
    var data:any = await this._api.getQueryAll();
    var query:string = "";
    await data.objeto.forEach(element => {
      if (element.NOMBRE == this.nombreConsulta) {
        query = element.CONSULTA;
      }
    });

    if (this.Encabezado.ObjType == "540000006" || this.Encabezado.ObjType == "22") {
      query = query.replace("T0.[CardType] != 'S'", "T0.[CardType] = 'S'");
    }


    data = await this._api.GetNotFileQuery(query);
    this.data = [...data.objeto];

    let perfil = localStorage.getItem("USER_ROL");

    console.log("DesdeUserEdit",this.DesdeUserEdit);
    console.log("perfil",perfil);
    console.log("usuarioON",this.usuarioON);

    if (!this.DesdeUserEdit && this.usuarioON.CLIENTES.length > 0) {
      this.data = this.data.filter(item => {
        return this.usuarioON.CLIENTES.some(cliente => cliente.CardCode === item.CardCode);
      });
    }

    console.log("data",this.data);

  }

  private setClass(name: string): void {
    this.table.apiEvent({
      type: API.setTableClass,
      value: name,
    });
  }

  cerrar() {
    this.activeModal.close();
  }
  eventEmitted($event): void {
    //this.clicked = JSON.stringify($event);
    //console.log('$event', $event);
    var data:any = $event.value.row;
    // || $event.event == "onClick"
    if ($event.event == "onDoubleClick") {
      //console.log($event);

      this.Encabezado.CardCode = data.CardCode;
      this.Encabezado.CardName = data.CardName;
      this.Encabezado.DescuentoCampoUsuario = data.U_Descuento;
      this.Encabezado.ShipToDef = data.ShipToDef;
      this.Encabezado.BillToDef = data.BillToDef;
      this.Encabezado.ListaDePrecioSocio = data.ListNum;
      this.Encabezado.GroupNum = data.GroupNum;
      this.Encabezado.Currency = data.Currency;
      this.Encabezado.ListaCUrrency = false;
      this.Encabezado.GroupCode = data.GroupCode;
      this.Encabezado.Discount = data.Discount;
      console.log(this.Encabezado.Currency);

      if (this.Encabezado.Currency  == "##") {
        // this.Encabezado.Currency = "USD";
        this.Encabezado.Currency = "CLP";
        console.log(this.Encabezado.Currency);
        this.Encabezado.ListaCUrrency = true;
      }
      
    }
    if ($event.event == "onDoubleClick") {
      //console.log(data);
      //console.log(this.Detalle.Factor1, this.Detalle.Factor2)
      this.Seleccionar();      
    }
  
  }
  Seleccionar()
  {
    this.activeModal.close(this.Encabezado);
  }

  mantenedorCliente(CardCode:string)
  {
    // let ngbModalOptions: NgbModalOptions = {
    //   backdrop : 'static',
    //   keyboard : false,
    //   size:"xl",
    //   modalDialogClass:variableGlobal.CLASS_MODAL_BG
    // };
    // const modalRef = this.modalService.open(clienteModelComponent, ngbModalOptions);
    // modalRef.componentInstance.headerInivisible = true;
    // modalRef.componentInstance.CardCode = CardCode;
    // modalRef.componentInstance.modalReference = modalRef;
    // modalRef.result.then((data) => {
    //   this.cargaDatosSAP();
    // }, (reason) => {
    //   //this.editar(item);
    // });

    // código de modal editaSocioNegocio.ts
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size:"xl",
      modalDialogClass:variableGlobal.CLASS_MODAL_BG
    };
    const modalRef = this.modalService.open(editaSocioNegocioComponent, ngbModalOptions);
    modalRef.componentInstance.headerInivisible = true;
    modalRef.componentInstance.CardCode = CardCode;
    modalRef.componentInstance.desdeNotaVenta = true;
    modalRef.componentInstance.modalReference = modalRef;
    modalRef.result.then((data) => {
      this.cargaDatosSAP();
    }, (reason) => {
      //this.editar(item);
    });
    
    
    // let ngbModalOptions: NgbModalOptions = {
    //   backdrop : 'static',
    //   keyboard : false,
    //   size:"xl",
    //   modalDialogClass:variableGlobal.CLASS_MODAL_BG
    // };
  }
}