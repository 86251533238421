<div class="card">


    <div class="card-header">
        <h3 class="card-title">{{titulo}} Test</h3>
    </div>
    <div class="card-body">
        <div id="example2_wrapper" class="dataTables_wrapper dt-bootstrap4">
            <div class="row">
                <div class="col-sm-12 col-md-6"></div>
                <div class="col-sm-12 col-md-6"></div>
            </div>
            <div class="row">
                <div class="col-sm-12">

                    <ngx-table #table [configuration]="configuration" [data]="data" [columns]="columns"></ngx-table>
                    <ng-template #frmDocNum let-row let-rowIndex="rowIndex">

                        <button class="btn btn-primary" (click)="OpenModal(row)" *ngIf="tipo  == 'preparar'">{{row.DocNum}} </button>
                        <button class="btn btn-primary" (click)="OpenModalV2(row)" *ngIf="tipo  == 'generar'">{{row.DocNum}} </button>
                        <button class="btn btn-primary" (click)="OpenDoc(row)" *ngIf="tipo  == 'asignar'">{{row.DocNum}}</button>

                    </ng-template>
                    <ng-template #frmDueDate let-row let-rowIndex="rowIndex">
                        {{row.DocDueDate | date: 'dd/MM/yyyy'}}
                    </ng-template>
                    <ng-template #frmTotal let-row let-rowIndex="rowIndex">
                        <div *ngIf="row.DocCur  != 'CLP'">{{row.DocTotal | currency: row.DocCur}}</div>
                        <div *ngIf="row.DocCur  == 'CLP'">{{row.DocTotal | iNDCLP}}</div>
                    </ng-template>
                    <ng-template #frmAccion let-row let-rowIndex="rowIndex">
                        <select class="form-control" (change)="UpdateResponsable(row,$event);" [ngValue]="row.U_IND_Encargado"  *ngIf="tipo  == 'asignar'" >
                            <option selected>Sin usuario asignado</option>
                            <option *ngFor="let row of usuarios" [value]="row.RUT">{{row.NOMBRE}}</option>
                        </select>

                        <p  *ngIf="tipo  == 'preparar'"> {{row.U_IND_ENCARGADO}} </p>
                    </ng-template>

                </div>
            </div>
        </div>
    </div>
</div>
