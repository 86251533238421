
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Administrador de usuarios</h3>
            </div>
            <!-- /.card-header -->
            <div class="card-body" >

              <button class="btn btn-primary" style="margin-bottom:10px" (click)="editar(nuevo, 'Nuevo perfil', '-1')" > <i class="fa fa-plus"></i> Nuevo </button>
              
              <ngx-table #table
                      [configuration]="configuration" 
                      [data]="data"
                      [columns]="columns"
                      
                      [detailsTemplate]="detailsTemplate"
                      >
              </ngx-table>

              <ng-template #frmAccion let-row let-rowIndex="rowIndex">
                <button class="btn btn-success" (click)="editar(row, 'Editar usuario', rowIndex)">Editar</button>
                <button class="btn btn-danger" style="margin-left:5px" (click)="eliminar(row, rowIndex)"><i class='fa fa-trash'></i></button>
              </ng-template>

              <ng-template #frmDetalle let-row let-rowIndex="rowIndex">
                <button
                        class="btn btn-primary"
                        id="expandButton-{{ rowIndex }}"
                        (click)="onRowClickEvent($event, rowIndex)">
                        <i class="fas fa-arrow-right"></i>
                        Detalle
                      </button>
              </ng-template>

              <ng-template #frmFoto let-row let-rowIndex="rowIndex">
                <img [src]="row.IMAGE_LITTLE"  width="30px" />
              </ng-template>
              <ng-template #frmPerfil let-row let-rowIndex="rowIndex">
                {{buscaPerfil(row.PERFIL)}}
              </ng-template>

              <ng-template #detailsTemplate let-row>
                <div>
                  
                  <h2><img [src]="row.IMAGE_LITTLE"  width="30px" /> {{ row.CARGO }}</h2>
                  {{buscaPerfil(row.PERFIL)}}
                  <br>
                  {{ row.CORREO }}
                  <br>
                  <button class="btn btn-success" (click)="editar(row, 'Editar usuario', rowIndex)">Editar</button>
                  <button class="btn btn-danger" style="margin-left:5px" (click)="eliminar(row, rowIndex)"><i class='fa fa-trash'></i></button>
                </div>
              </ng-template>


              

            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->
        </div>
        <!-- /.col -->
      </div>

