<style>
  ::ng-deep  .ng-dropdown-panel-items{
    background: #3498dbe6;
    color: white;
  }
  ::ng-deep  .ng-select-container{
    color: #767676;
    background: white;
    border: 1px solid #3498DB;
    border-radius: 5px;
  }
  ::ng-deep .ng-option{
    padding: 3px;
  }
</style>

<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Lista articulos</h4>

    <button type="button" class="close" aria-label="Close" (click)="cerrar()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="row" style="padding: 10px;">

    <div class="col-12" style="display: flex;justify-content: center;align-items: center;padding: 5px;">
      <img src="assets/jslogo.png" alt="" srcset="" style="height: 60px;">
      <img src="assets/NIBK.png" alt="" srcset="" style="height: 60px;">
    </div>

    <div class="col-3">
      <label>Marca:</label>
      <ng-select [(ngModel)]="marcaSelected" (change)="filtrarArreglo()">
        <ng-option *ngFor="let car of marcasArray" [value]="car">{{car}}</ng-option>
      </ng-select>
    </div>

    <div class="col-3">
      <label>Modelo:</label>
      <ng-select [(ngModel)]="modeloSelected" (change)="filtrarArreglo()">
        <ng-option *ngFor="let car of modelosArray" [value]="car">{{car}}</ng-option>
      </ng-select>
    </div>

    <div class="col-3">
      <label>Familia:</label>
      <ng-select [(ngModel)]="familiaSelected" (change)="filtrarArreglo()">
        <ng-option *ngFor="let car of familiasArray" [value]="car">{{car}}</ng-option>
      </ng-select>
    </div>

    <div class="col-3">
      <label>SubFamilia:</label>
      <ng-select [(ngModel)]="subfamiliaSelected" (change)="filtrarArreglo()">
        <ng-option *ngFor="let car of subfamiliasArray" [value]="car">{{car}}</ng-option>
      </ng-select>
    </div>

    <div class="col-12">
      <button class="btn btn-primary" style="margin-top: 10px;width: 100%;" (click)="limpiarFiltros()">
         Limpiar 
         <i class="fa-solid fa-rotate-left"></i>
      </button>
    </div>

  </div>


  <div class="modal-body">
     <span>Favor hacer doble click para seleccionar artículo</span>

    <ngx-table #table [configuration]="configuration"
        [data]="dataView"
        [columns]="columns"
        (event)="eventEmitted($event)"></ngx-table>
        <ng-template #frmItemCode let-row let-rowIndex="rowIndex">
            <button class="btn btn-primary" (click)="AbrirDetalleItem(row)">{{row.ItemCode}}</button>
        </ng-template>
        <ng-template #frmPrice let-row let-rowIndex="rowIndex">
          <div *ngIf="row.Currency != 'CLP'">{{row.Price | currency: row.Currency}}</div>
          <div *ngIf="row.Currency == 'CLP'">{{row.Price | iNDCLP}}</div>
        </ng-template>
        <ng-template #frmPriceBruto let-row let-rowIndex="rowIndex">
          <div *ngIf="row.Currency != 'CLP'">{{row.PriceBruto | currency: row.Currency}}</div>
          <div *ngIf="row.Currency == 'CLP'">{{row.PriceBruto | iNDCLP}}</div>
        </ng-template>
        <ng-template #frmSerie let-row let-rowIndex="rowIndex">
          <div *ngIf="row.ManSerNum == 'N'">No</div>
          <div *ngIf="row.ManSerNum == 'Y'">Si</div>
        </ng-template>
        <ng-template #frmLote let-row let-rowIndex="rowIndex">
          <div *ngIf="row.ManBtchNum == 'N'">No</div>
          <div *ngIf="row.ManBtchNum == 'Y'">Si</div>
        </ng-template>

        <ng-template #frmPriceOther let-row let-rowIndex="rowIndex">
          <div>{{row.PriceOther | currency: Encabezado.currency}}</div>
        </ng-template>


  </div>
  <div class="modal-footer">
    <!--<button (click)="Seleccionar()" type="button" class="btn btn-success">Seleccionar</button>-->
  </div>
