

<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Envio correo cliente</h4>

        <!--<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
        </button>-->
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="columns col-12">
                <label>Correo</label>
                <input type="text" [(ngModel)]="correo" class="form-control" placeholder="Favor ingresar correo electronco, se puede separar por ;" />
            </div>
        </div>
        <div class="row">
            <div class="columns col-12">
                <label>Asunto</label>
                <input type="text" [(ngModel)]="asunto" class="form-control" placeholder="Favor ingresar asunto" />
            </div>
        </div>
        <div class="row">
            <div class="columns col-12">
                <label>Mensaje</label>
                <ckeditor #myEditor [editor]="Editor" data="{{mensaje}}" style="background: red;"></ckeditor>
            </div>
        </div>

    </div>
    <div class="modal-footer">
        <button (click)="cerrar()" type="button" class="btn btn-default">Cerrar</button>
        <button (click)="envioCorreo()" type="button" class="btn btn-success">Enviar correo</button>
    </div>
</div>

