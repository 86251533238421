<div class="form-group">
    <label for="exampleSelectRounded0">Selecciona un periodo </label>
    <select class="custom-select rounded-0" id="exampleSelectRounded0">
        <option>Value 1</option>
        <option>Value 2</option>
        <option>Value 3</option>
    </select>
</div>

<div class="row">

    <div class="col-lg-3 col-6">
        <div class="small-box bg-info">
            <div class="inner" style="background: #94cf3a;">
                <h3></h3>
                <p>Ingresos</p>
            </div>
            <div class="icon">
                <i class="fa-solid fa-money-bill-wave"></i>
            </div>
        </div>
    </div>

    <div class="col-lg-3 col-6">
        <div class="small-box bg-info">
            <div class="inner" style="background: #94cf3a;">
                <h3>{{costoNeto|iNDCLP}}</h3>
                <p>Costo GDS</p>
            </div>
            <div class="icon">
                <i class="fa-solid fa-chart-line"></i>
            </div>
        </div>
    </div>

    <div class="col-lg-3 col-6">
        <div class="small-box bg-info">
            <div class="inner" style="background: #94cf3a;">
                <h3 class="margin-bottom-0">{{queryVendedorCantidad[0].Cantidad}}</h3>
                <p class="margin-bottom-0">{{queryVendedorCantidad[0].SlpName}}</p>
                <p class="margin-bottom-0">Vendedor por Cantidad</p>
            </div>
            <div class="icon">
                <i class="fa-solid fa-arrow-up-9-1"></i>
            </div>
        </div>
    </div>

    <div class="col-lg-3 col-6">
        <div class="small-box bg-info">
            <div class="inner" style="background: #94cf3a;">
                <h3 class="margin-bottom-0">{{queryVentasPorVendedor[0].Suma|iNDCLP}}</h3>
                <p class="margin-bottom-0">{{queryVentasPorVendedor[0].SlpName}}</p>
                <p class="margin-bottom-0">Vendedor por Ingresos</p>
            </div>
            <div class="icon">
                <i class="fa-solid fa-dollar-sign"></i>
            </div>
        </div>
    </div>

</div>


<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">

                <div class="card card-primary">
                    <div class="card-header" style="background: #94CF3A !important;
                    color: black !important;
                    font-weight: bold !important;">
                        <h3 class="card-title">Area Chart</h3>
                    </div>
                    <div class="card-body">
                        <div class="chartdiv" style="height: 400px"></div>
                    </div>

                </div>

            </div>

            <div class="col-md-6">

                <div class="card card-primary">
                    <div class="card-header">
                        <h3 class="card-title">Area Chart</h3>
                    </div>
                    <div class="card-body">
                        <div class="chartBar" style="height: 400px;"></div>
                    </div>

                </div>

            </div>

        </div>

    </div>
</section>

<div class="card-body">
    <div id="example2_wrapper" class="dataTables_wrapper dt-bootstrap4">
        <div class="row">
            <div class="col-sm-12 col-md-6"></div>
            <div class="col-sm-12 col-md-6"></div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <table id="example2" class="table table-bordered table-hover dataTable dtr-inline"
                    aria-describedby="example2_info">
                    <thead>
                        <tr>
                            <th class="sorting sorting_asc" tabindex="0" aria-controls="example2" rowspan="1"
                                colspan="1" aria-label="Rendering engine: activate to sort column descending"
                                aria-sort="ascending">Rendering engine</th>
                            <th class="sorting" tabindex="0" aria-controls="example2" rowspan="1" colspan="1"
                                aria-label="Browser: activate to sort column ascending">Browser</th>
                            <th class="sorting" tabindex="0" aria-controls="example2" rowspan="1" colspan="1"
                                aria-label="Platform(s): activate to sort column ascending">Platform(s)</th>
                            <th class="sorting" tabindex="0" aria-controls="example2" rowspan="1" colspan="1"
                                aria-label="Engine version: activate to sort column ascending">Engine version</th>
                            <th class="sorting" tabindex="0" aria-controls="example2" rowspan="1" colspan="1"
                                aria-label="CSS grade: activate to sort column ascending">CSS grade</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr class="odd">
                            <td class="dtr-control sorting_1 background-table" tabindex="0">Gecko</td>
                            <td class="background-table">Firefox 1.0</td>
                            <td class="background-table">Win 98+ / OSX.2+</td>
                            <td class="background-table">1.7</td>
                            <td class="background-table">A</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>