import { Component, OnInit, OnDestroy, Input,  EventEmitter} from '@angular/core';
import {IntranetoldService} from './../../../api/intranetold.service'
import {IntranetNewService} from "./../../../api/intranet-new.service"
import {variableGlobal} from './../../../global.enum';
import { NgxIndexedDBService,  } from 'ngx-indexed-db';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, } from "@angular/material/dialog";
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2'


@Component({
  selector: 'app-correoEditAdd',
  templateUrl: './addEdit.html', 
  styleUrls: ['./correo-etapa.component.sass']
})

export class CorreoAddEditComponent implements OnInit {
  @Input() anyDataForm: any;
    constructor(
      public activeModal: NgbActiveModal,
      private _api:IntranetoldService,

      ) { 

        
      }

      data:any = {};
      titulo:string = "";
      txtButton:string = "Actualizar";
      etapas:any;
    ngOnInit(): void {


      if (this.titulo.toUpperCase().includes("NUEVO")) {
        this.txtButton = "Agregar";
      }
    }
    cerrar() {
      this.activeModal.close();
        //this.modalReference.close(this.usuario);
    }
    guardar()
    {
      this.activeModal.close(this.data);
    }
}