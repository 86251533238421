import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.sass']
})
export class IframeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
