import { Component, OnInit, OnDestroy, Input,  EventEmitter} from '@angular/core';
import {IntranetoldService} from './../../../api/intranetold.service'
import {variableGlobal} from './../../../global.enum';
import { NgxIndexedDBService,  } from 'ngx-indexed-db';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, } from "@angular/material/dialog";
import { ModalDismissReasons, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import * as _ from 'lodash';
import Swal from 'sweetalert2'
import { listaClienteComponent } from '../../crm/venta/listaCliente';


@Component({
  selector: 'app-userEditAdd',
  templateUrl: './addEdit.html',
  styleUrls: ['./user.component.sass']
})

export class UserAddEditComponent implements OnInit {
  @Input() anyDataForm: any;
    constructor(
      public activeModal: NgbActiveModal,
      private _api:IntranetoldService,
      private modalService: NgbModal,

      ) {


      }
    passnew:any;
    data:any = {};
    varglo:any;
    imageError: string;
    isImageSaved: boolean;
    titulo:string = "";
    txtButton:string = "Actualizar";

    ngOnInit(): void {
      this.data.PASSWORDNEW = "";
      this.varglo = variableGlobal;
        //console.log(this.data);
        this.isImageSaved = true;
        //console.log(this.usuario.RUT);

        this.obtieneListas();

        if (this.titulo.toUpperCase().includes("NUEVO")) {
          this.txtButton = "Agregar";
        }

        console.log("data",this.data);
    }

    vendedores:any;
    perfiles:any;
    async obtieneListas()
    {

      var objeto:any = {
          "nombre":"OSLP",
          "top":"",
          "where": ""
      };
      //var oslp = await this._api.GetFileQuery(objeto);
      //console.log(oslp);

      var query = "SELECT [SlpCode], [SlpName] FROM [OSLP]";


      var oslp2:any = await this._api.GetNotFileQuery(query);
      this.vendedores = oslp2.objeto;
      //console.log(this.vendedores);

      var perfil:any = await this._api.getPerfilAll();
      this.perfiles = perfil.objeto;
      //console.log(this.perfiles);

    }
    modalReference: NgbModalRef;

    async recuperarPass(){

      console.log(this.data)

      var datos:any = await this._api.EditPass(this.data);


      console.log(datos)
      if (datos.estado) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: datos.mensaje,
          showConfirmButton: false,
          timer: 1500
        });
      }

      else
      {
        Swal.fire(datos.mensaje, '', 'error');
      }

    }

    async guardar() {
      //console.log(this.data);
      //console.log(this.data.IMAGE_LITTLE);
      //this.activeModal.close();
      //this.modalReference.close(this.usuario);

      var datos:any = await this._api.AddEditLogin(this.data);



      if (datos.estado) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: datos.mensaje,
          showConfirmButton: false,
          timer: 1500
        });
        this.activeModal.close(this.data);
      }
      else
      {
        Swal.fire(datos.mensaje, '', 'error');
      }


    }




    fileChangeEvent(fileInput: any) {
      this.imageError = null;
      if (fileInput.target.files && fileInput.target.files[0]) {
          // Size Filter Bytes
          const max_size = 20971520;
          const allowed_types = ['image/png', 'image/jpeg'];
          const max_height = 15200;
          const max_width = 25600;

          if (fileInput.target.files[0].size > max_size) {
              this.imageError =
                  'Tamaño maximo permitido ' + max_size / 1000 + 'Mb';

              return false;
          }

          if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
              this.imageError = 'Solo estos formatos son soportados ( JPG | PNG )';
              return false;
          }
          const reader = new FileReader();
          reader.onload = (e: any) => {
              const image = new Image();
              image.src = e.target.result;
              image.onload = rs => {
                  const img_height = rs.currentTarget['height'];
                  const img_width = rs.currentTarget['width'];

                  console.log(img_height, img_width);


                  if (img_height > max_height && img_width > max_width) {
                      this.imageError =
                          'Dimension maxima permitida es ' +
                          max_height +
                          '*' +
                          max_width +
                          'px';
                      return false;
                  } else {
                      const imgBase64Path = e.target.result;
                      //this.cardImageBase64 = imgBase64Path;
                      this.data.IMAGE_LITTLE = imgBase64Path;
                      this.isImageSaved = true;
                      // this.previewImagePath = imgBase64Path;
                  }
              };
          };

          reader.readAsDataURL(fileInput.target.files[0]);
      }
  }

  removeImage() {
      //this.cardImageBase64 = null;
      this.data.IMAGE_LITTLE = variableGlobal.RUTA_IMAGEN_USER_NOT_FOUND;
      this.isImageSaved = false;
  }

  async selecionarCliente() { //encargado de cargar los datos de los clientes al pinchar icono de lupa

    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: "xl",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG
    };
    const modalRef = this.modalService.open(listaClienteComponent, ngbModalOptions);
    modalRef.componentInstance.PriceList = this.data.ListaDePrecioSocio;
    modalRef.componentInstance.Encabezado = this.data;
    modalRef.componentInstance.DesdeUserEdit = true;
    //modalRef.componentInstance.data = this.OCRD;
    modalRef.componentInstance.modalReference = modalRef;
    modalRef.result.then(async (data) => {
      if (data) {
        const index = this.data.CLIENTES.findIndex(element => element.CardCode === data.CardCode);
        
        if (index === -1) {
          let cliente = {
            "CardCode":data.CardCode,
            "CardName":data.CardName
          }
          this.data.CLIENTES.push(cliente);
        }
      }
    }, (reason) => {

    });


  }

  async confirmarEliminarCliente(cliente) {
    const resultado = await Swal.fire({
      title: 'Eliminar Cliente',
      text: `¿Estás seguro que deseas eliminar el cliente ${cliente.CardName}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#33DE7F',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true, // Coloca el botón de confirmación en el lado derecho
    });
  
    // Si el usuario confirma la eliminación, devuelve true
    return resultado.isConfirmed;
  }

  async eliminarCliente(cliente:any){
    const confirmado = await this.confirmarEliminarCliente(cliente);

    if (confirmado) {
      const index = this.data.CLIENTES.findIndex(element => element.CardCode === cliente.CardCode);
      if (index !== -1) {
        this.data.CLIENTES.splice(index, 1);
      }
    }
  }


}
