		<div class="table-wrapper">
			<div class="table-title">
				<div class="row">
					<div class="col-sm-6">
						<h2>Metas Vendedores</h2>
					</div>
					
				</div>
			</div>
			<table class="table table-striped table-hover">
				<thead>
					<tr>
						<th>SlpCode</th>
						<th>Nombre</th>
						<th>Mes</th>
						<th>Año</th>
						<th>Valor</th>
						<th>
                            <i class="material-icons buttonpointer colorverde"(click)="CrearMeta()"></i>
                        </th>
					</tr>
				</thead>
				<tbody>
				
					<tr *ngFor="let datoVendedor of jsonMetaVendedor">
						<td>{{datoVendedor.SlpCode}}</td>
						<td>{{datoVendedor.SlpName}}</td>
						<td>{{datoVendedor.Mes}}</td>
						<td>{{datoVendedor.Anio}}</td>
						<td>{{datoVendedor.Meta|iNDCLP}}</td>
						<td>
							<i class="material-icons buttonpointer blueeffect" data-toggle="tooltip" title="" data-original-title="Edit" (click)="EditarMeta(datoVendedor)"></i>
							<i class="material-icons" data-toggle="tooltip" title="" data-original-title="Delete"></i>
						</td>
					</tr> 
				</tbody>
			</table>

		</div>
