 <!-- Content Wrapper. Contains page content -->
 <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <!-- Main content -->
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Mapa de relaciones</h3>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body">
          
                            <div class="row">
                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>


