import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import Swal from 'sweetalert2'
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { variableGlobal, Template } from './../global.enum'
import {IntranetoldService} from './../api/intranetold.service'
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CambioPwComponent } from './cambio-pw/cambio-pw.component';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass','./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  constructor(
    //private _api: ApiCallService,
    private _formBuilder: FormBuilder,
    private _dbService: NgxIndexedDBService,
    private _cookieService: CookieService,
    private _route: Router,
    private _api:IntranetoldService,
    private modalService: NgbModal
    

  ) { }
   RUT: string;
   varGlobal = variableGlobal;
   loginData: any;
  async ngOnInit(): Promise<void> {



    this.varGlobal = variableGlobal;
   
    this.loginForm = this._formBuilder.group({
      RUT: ['', [Validators.required]],
      PASSWORD: ['', Validators.required]
    });


    //si la base de datos tiene datos llenar las cookies

    
    this._dbService.getByID(variableGlobal.DB_TABLE_GENERAL, variableGlobal.DB_TABLE_GENERAL_id_login).subscribe(lg => {
      var datos:any = lg;
      if (datos) {
        //console.log("lg", datos);
        this.loginDatosProcesa(datos.datos);
      }
    

    });


  }

  async loginDatosProcesa(datos:any)
  {

    //console.log(datos);
    var lg:any = datos.login;
    var token = lg.TOKEN;
    var PERFIL = lg.PERFIL;
   
    var tokenData:any = await this._api.validaToken(token);
    if (tokenData.estado) {
      
      var rut = this.loginForm.value.RUT;
      var password = this.loginForm.value.PASSWORD;


      if (password.toString().length > 0 && rut.toString().length > 0 && password == rut.toString().substring(0,4)) {
        this.cambioContrasena();
      }
      else {
        
        this._cookieService.set("token", token);

        var perfil:any = await this._api.getPerfilAll();
        var perfiles = {id:variableGlobal.DB_TABLE_GENERAL_id_perfiles, datos: perfil.objeto};
        await this._dbService.update(variableGlobal.DB_TABLE_GENERAL, perfiles).toPromise();

        
        var menu:any = await this._api.getMenuPadreHijo(PERFIL);



        //inicio carga menu
        var menux = {id:variableGlobal.DB_TABLE_GENERAL_id_menu, datos: menu.objeto};
        await this._dbService.update(variableGlobal.DB_TABLE_GENERAL, menux).toPromise();
        

        var menuTrabajado = [];
        var menuAll:any = menu;
        var padre = menuAll.objeto.padres;
        var hijos = menuAll.objeto.hijos;
        padre.forEach(element => {

          var hijoAgregado = [];
          hijos.forEach(element2 => {
            if (element._id == element2.PADRE) {
              hijoAgregado.push(element2);
              
            }
          });

          element.HIJOS = hijoAgregado;

          menuTrabajado.push(element);

      
        });


      
        menux = {id:variableGlobal.DB_TABLE_GENERAL_id_menu_final, datos: menuTrabajado};
        await this._dbService.update(variableGlobal.DB_TABLE_GENERAL, menux).toPromise();
        
        var perfilUrl = null;

        if (datos.perfil) {
          perfilUrl = datos.perfil.URL_DEFAULT;
        }
        
        if (!perfilUrl) {
          perfilUrl = "/inicio"
        }

        
        this._cookieService.set("template", Template.NORMAL);

        // this.cambioContrasena();

        window.location.href = perfilUrl;
        //this._route.navigate([perfilUrl]);
        //fin get menu

      }
    }

  }

  async login()
  {


    if (this.loginForm.value.RUT.includes('@')) {
      let res:any = await this._api.getAllLogin();
      if(res.estado)
      {
  
        let usuarios = [...res.objeto];

        usuarios.forEach(async element => {
          if (element.CORREO == this.loginForm.value.RUT) {
            var res:any = await  this._api.loginV2(element.RUT, this.loginForm.value.PASSWORD,"N");

            var login = {id: variableGlobal.DB_TABLE_GENERAL_id_login, datos: res.objeto}
            this._dbService.update(variableGlobal.DB_TABLE_GENERAL, login).subscribe();
            this._dbService.getByID(variableGlobal.DB_TABLE_GENERAL, variableGlobal.DB_TABLE_GENERAL_id_login).subscribe(lg => {
              var datos:any = lg;
              this.loginData = lg;
              if (datos) {
                //console.log("lg", datos);
                this.loginDatosProcesa(datos.datos);
              }
          
            });
          }
        });
        
      }

    }else{

      var res:any = await  this._api.loginV2(this.loginForm.value.RUT, this.loginForm.value.PASSWORD,"N");
      var login = {id: variableGlobal.DB_TABLE_GENERAL_id_login, datos: res.objeto}
      this._dbService.update(variableGlobal.DB_TABLE_GENERAL, login).subscribe();
      this._dbService.getByID(variableGlobal.DB_TABLE_GENERAL, variableGlobal.DB_TABLE_GENERAL_id_login).subscribe(lg => {
        var datos:any = lg;
        this.loginData = lg;
        if (datos) {
          //console.log("lg", datos);
          this.loginDatosProcesa(datos.datos);
        }
    
      });
    }





    
  }

  cambioContrasena(){
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size:"xs",
      modalDialogClass:variableGlobal.CLASS_MODAL_BG
    };
    console.log("DATA",this.loginData);
    const modalRef = this.modalService.open(CambioPwComponent, ngbModalOptions);
    modalRef.componentInstance.datos.RUT = this.loginData.datos.login.RUT;
    modalRef.result.then();
  }

}
