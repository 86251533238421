import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Columns, Config, DefaultConfig, APIDefinition, API } from 'ngx-easy-table';
import { ActivatedRoute, Router } from '@angular/router'
import { variableGlobal } from 'src/app/global.enum';
import { IntranetoldService } from 'src/app/api/intranetold.service';
import { PickingComponent } from '../preparar-pedido/picking/picking.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';
import { object } from '@amcharts/amcharts4/core';
import { IndesadService } from 'src/app/api/indesad.service';
import { MongoService } from 'src/app/api/mongo.service';
import { VistaNvComponent } from '../vista-nv/vista-nv.component';

@Component({
  selector: 'app-asignar-pedido',
  templateUrl: './asignar-pedido.component.html',
  styleUrls: ['./asignar-pedido.component.css']
})
export class AsignarPedidoComponent implements OnInit {
  public configuration: Config;
  public columns: Columns[];
  data = [];
  tipo = "";
  titulo = "";
  usuarios: any = [];
  checked = new Set([]);
  @ViewChild('table', { static: true }) table: APIDefinition;
  @ViewChild('frmDueDate', { static: true }) frmDueDate: TemplateRef<any>;
  @ViewChild('frmTotal', { static: true }) frmTotal: TemplateRef<any>;
  @ViewChild('frmDocNum', { static: true }) frmDocNum: TemplateRef<any>;
  @ViewChild('frmAccion', { static: true }) frmAccion: TemplateRef<any>;
  constructor(
    private modalService: NgbModal,
    private _activeRoute: ActivatedRoute,
    private _api: IntranetoldService,
    private _apiMongo: IndesadService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.cargaInicial();
  }

  async cargaInicial() {
    this.columns = [
      { key: 'DocNum', title: "N° Doc", cellTemplate: this.frmDocNum },
      { key: 'CardCode', title: 'Codigo SN' },
      { key: 'CardName', title: 'Socio' },
      { key: 'Address2', title: 'Despacho' },
      { key: 'DocDueDate', title: 'Fecha vencimiento', cellTemplate: this.frmDueDate },
      { key: 'DocTotal', title: 'Total', cellTemplate: this.frmTotal },
      { key: '', title: 'Asignar', cellTemplate: this.frmAccion },

    ];


    var query = await this.obtieneQueryMongo("VST_WMS");

    var data: any = await this._api.GetNotFileQuery(query);
    this.data = data.objeto;

    this.columns.forEach(element => {
      this.checked.add(element.key);
    });

    this.obtieneTitulo();

    this.configuration = {
      ...DefaultConfig,
      searchEnabled: true,
      detailsTemplate: true,
      paginationRangeEnabled: true,
      paginationEnabled: true,
      horizontalScroll: true,
      resizeColumn: false,
      orderEnabled: true,
      rowReorder: false,
      columnReorder: false,
      fixedColumnWidth: true,
    };
    if (variableGlobal.ESTILO_EASY_TABLE) {
      this.setClass(variableGlobal.ESTILO_EASY_TABLE);
    }

    await this.getUsuarios();
    // console.log("USUARIOS:  \n");
    // console.log(this.usuarios);

  }
  async getUsuarios() {

    var res: any = await this._api.getAllLogin();
    var encargados: any = await this._apiMongo.GetGrupo("Responsable");
    console.log(encargados);
    // if (encargados.estado) {
    this.usuarios = encargados.objeto;
    // }
  }
  obtieneTitulo() {
    this.tipo = this._activeRoute.snapshot.paramMap.get("tipo");
    console.log(this.tipo);
    if (this.tipo == "asignar") {
      console.log("Dice asignar");
      this.titulo = "Asignacion de pedidos";

    } if (this.tipo == "generar") {
      this.titulo = "generar pedidos";
    } if (this.tipo == "pre-asignar") {
      this.titulo = "pre-asignacíon de pedidos";
    }
    if (this.tipo == "preparar") {
      this.titulo = "Preparacion de pedidos";
      // this.ocultaColumna("");
    }
  }

  ocultaColumna(name: string): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    //
    this.checked.has(name) ? this.checked.delete(name) : this.checked.add(name);
    this.columns = this.columns.filter((column) => this.checked.has(column.key));
  }

  private setClass(name: string): void {
    this.table.apiEvent({
      type: API.setTableClass,
      value: name,
    });
  }

  OpenDoc(row:any){

    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: "xl",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG
    };

    const modalRef = this.modalService.open(VistaNvComponent, ngbModalOptions);
    modalRef.componentInstance.data = row;
    modalRef.componentInstance.modalReference = modalRef;
    modalRef.result.then(async (data) => {

    });

  }




  async obtieneQueryMongo(nombre) {
    var data: any = await this._api.getQueryAll();
    var query: string = "";
    await data.objeto.forEach(element => {
      if (element.NOMBRE == nombre) {
        query = element.CONSULTA;
      }
    });

    return query;
  }

  async OpenModal(Encabezado) {




    var doc = await this.CargarDatos(Encabezado.DocEntry);

    console.log(doc);
    console.log(Encabezado);




    var config = {
      height: '100%',
      width: '98vw', //sets width of dialog
      data: {
        DocEntry: Encabezado.DocEntry,
        DocNum: Encabezado.DocNum,
        ObjType:Encabezado.ObjType,
        Document: doc
      },

    };

    const dialogRef = this.dialog.open(PickingComponent, config);
    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
    })

  }

  async OpenModalV2(datos) {



    var query:any = await this.obtieneQueryMongo("loteStockBodega");
    query = query + "WHERE [ItemCode] = ' "+ +"'";
    var lotes = await this._api.GetNotFileQuery(query);



  }

  async CargarDatos(DocEntry) {
    var query = await this.obtieneQueryMongo("WMS_NV_DET");
    query = query.replace("NV", DocEntry);
    var respuesta: any = await this._api.GetNotFileQuery(query);
    var Document = respuesta.objeto;

    console.log(query)
    console.log(respuesta);
    return Document;
  }

  UpdateResponsable(fila, event) {

    // console.log(event.target.value);
    this._apiMongo.AsignarResponsalbe(fila.DocEntry, event.target.value, fila.ObjType)
  }
}
