import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Columns, Config, DefaultConfig, APIDefinition, API } from 'ngx-easy-table';
import {IntranetoldService} from '../../../api/intranetold.service'
import {NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2'
import {abreTablaGenericoComponent} from './abreTablaGenerico'
import {variableGlobal} from "../../../global.enum";

@Component({
  selector: 'app-query-mongo',
  templateUrl: './query-mongo.component.html',
  styleUrls: ['./query-mongo.component.sass']
})
export class QueryMongoComponent implements OnInit {

  constructor(
    private _api: IntranetoldService,
    private modalService: NgbModal,
  ) { }
  @ViewChild('table', { static: true }) table: APIDefinition;
  public configuration: Config;
  public columns: Columns[];
  columnsCopy: Columns[] = [];
  data:any = [];
  dataQuery:any = {
    "_id": null,
    "NOMBRE": null,
    "CONSULTA": null
  }
  @ViewChild('frmNombre', { static: true }) frmNombre: TemplateRef<any>;
  @ViewChild('frmConsulta', { static: true }) frmConsulta: TemplateRef<any>;
  @ViewChild('frmAccion', { static: true }) frmAccion: TemplateRef<any>;
  ngOnInit(): void {
    this.cargaDatos();
  }

  async cargaDatos()
  {
    this.columns = [
      { key: 'NOMBRE', title: "Nombre query", width: "300px", cellTemplate: this.frmNombre  },
      { key: 'CONSULTA', title: 'Consulta', width: "900px", cellTemplate: this.frmConsulta  },
      { key: '', title: 'Acción', cellTemplate: this.frmAccion  }

    ];
    var dato:any = await this._api.getQueryAll();

    this.data = dato.objeto;

    this.configuration = { 
      ...DefaultConfig,
      searchEnabled: true,
      detailsTemplate: true,
      paginationRangeEnabled: true,
      paginationEnabled:true,
      horizontalScroll: false,
      resizeColumn: false,
      orderEnabled: true,
      rowReorder:false,
      columnReorder:true,
      fixedColumnWidth: true,
   };

   if(variableGlobal.ESTILO_EASY_TABLE)
    {
      this.setClass(variableGlobal.ESTILO_EASY_TABLE);
    }
  }

  private setClass(name: string): void {
    this.table.apiEvent({
      type: API.setTableClass,
      value: name,
    });
  }

  
  async Guardar(item)
  {
    var data:any = await this._api.AddEditQuery(item);
    if (data.estado) {
      Swal.fire('Mensaje',data.mensaje, 'success');
    }
    else
    {
      Swal.fire('Mensaje',data.mensaje, 'error');
    }
    this.dataQuery._id = null;
    this.dataQuery.NOMBRE = null;
    this.dataQuery.CONSULTA = null;
    var dato:any = await this._api.getQueryAll();
    this.data = dato.objeto;
  }

  async ProbarQuery(item)
  {
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size:"xl",
      modalDialogClass:variableGlobal.CLASS_MODAL_BG
    };
    const modalRef = this.modalService.open(abreTablaGenericoComponent, ngbModalOptions);
    modalRef.componentInstance.query = item.CONSULTA;
    modalRef.componentInstance.titulo = item.NOMBRE;
    modalRef.result.then();
  }

}
