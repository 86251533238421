
<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Documento Preliminar</h3>
      </div>
      <!-- /.card-header -->
      <div class="card-body">

        <div class="columns col-12">
          <ngx-table #table
                [configuration]="configuration"
                [data]="data"
                [columns]="columns"
          ></ngx-table>
          <ng-template #frmObjetoSAP let-row let-rowIndex="rowIndex">
            <button class="btn btn-primary" (click)="AbrirDetalle(row)">{{buscaObjetoSAP(row)}}</button>
          </ng-template> 
          <ng-template #frmNumerointerno let-row let-rowIndex="rowIndex">
            <button class="btn btn-primary" (click)="EditarDocumento(row)">{{row.numeracionINDESAD}}</button>
          </ng-template>
          <ng-template #frmFechaCreacion let-row let-rowIndex="rowIndex">
            {{row.FECHA | date: 'dd/MM/yyyy HH:mm'}}
          </ng-template> 
          <ng-template #frmEstado let-row let-rowIndex="rowIndex">
            {{verEstado(row)}}
          </ng-template> 
          <ng-template #frmAccion let-row let-rowIndex="rowIndex">
            <button class="btn btn-primary" (click)="VerAprobacion(row)">Ver modelos</button>
          </ng-template> 
        </div>
      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->
  </div>
  <!-- /.col -->
</div>
