<div class="card col-12">
  <div class="card-header">
    Maestro de articulo
  </div>
  <div class="card-body">
    <div class="row">

      <div class="col-md-9">
        <div class="row">
          <div class="col-md-6">

            <div class="row">
              <div class="col-4">
                <label>Código</label>
              </div>
              <div class="input-group input-group-sm col-8">
                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.ItemCode" />
              </div>

            </div>
            <div class="row">
              <div class="col-4">
                <label>Nombre</label>
              </div>
              <div class="col-8">
                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.ItemName" />
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label>Nombre extranjero</label>
              </div>
              <div class="col-8">
                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.FrgnName" />
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label>Grupo</label>
              </div>
              <div class="col-8">
                <select class="form-control form-control-sm" [(ngModel)]="data.ItmsGrpCod">
                </select>

              </div>
            </div>

            <div class="row">
              <div class="col-4">
                <label>Clase de artículo</label>
              </div>
              <div class="col-8">
                <select class="form-control form-control-sm" [(ngModel)]="data.ItemType">
                  <option value="">-</option>
                  <option value="I">Articulos</option>
                  <option value="L">Labor</option>
                  <option value="T">Viaje</option>
                  <option value="F">Activos fijos</option>
                </select>

              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label>Cantidad de unidades</label>
              </div>
              <div class="col-8">
                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.TreeQty" />
              </div>
            </div>


          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-4">
                <label>Artículo de compra(*)</label>
              </div>
              <div class="col-8">
                <select class="form-control form-control-sm" [(ngModel)]="data.PrchseItem">
                  <option value="Y">SI</option>
                  <option value="N">NO</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label>Artículo de venta(*)</label>
              </div>
              <div class="col-8">
                <select class="form-control form-control-sm" [(ngModel)]="data.SellItem">
                  <option value="Y">SI</option>
                  <option value="N">NO</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label>Artículo de inventario(*)</label>
              </div>
              <div class="col-8">
                <select class="form-control form-control-sm" [(ngModel)]="data.InvntItem">
                  <option value="Y">SI</option>
                  <option value="N">NO</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label>Aleación</label>
              </div>
              <div class="col-8">
                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.SWW" />
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label>Tipo de lista de materiales</label>
              </div>
              <div class="col-8">
                <select class="form-control form-control-sm" [(ngModel)]="data.TreeType">
                  <option value="N">No es BOM</option>
                  <option value="A">Ensamblado</option>
                  <option value="S">Ventas</option>
                  <option value="P">Produccion</option>
                  <option value="T">Plantilla</option>
                </select>
              </div>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-12 col-sm-12">
            <div class="card card-primary card-outline card-outline-tabs">
              <div class="card-header p-1 border-bottom-0">
                <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" id="tab-GeneralId" data-toggle="pill" href="#tab-General" role="tab"
                      aria-controls="tab-General" aria-selected="true">General</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="datosdecompra-tab" data-toggle="pill" href="#datosdecompra" role="tab"
                      aria-controls="datosdecompra" aria-selected="false">Datos de compra</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="datosdeventa-tab" data-toggle="pill" href="#datosdeventa" role="tab"
                      aria-controls="datosdeventa" aria-selected="false">Datos de venta</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="datosdeinventario-tab" data-toggle="pill" href="#datosdeinventario"
                      role="tab" aria-controls="datosdeinventario" aria-selected="false">Datos de inventario</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="planificacion-tab" data-toggle="pill" href="#planificacion" role="tab"
                      aria-controls="planificacion" aria-selected="false">Datos de planificacion</a>
                  </li>
                </ul>
              </div>
              <div class="card-body">
                <div class="tab-content" id="custom-tabs-four-tabContent">
                  <div class="tab-pane fade show active" id="tab-General" role="tabpanel"
                    aria-labelledby="tab-GeneralId">
                    <!--Inicio general-->
                    <div class="row">
                      <div class="col-md-6">

                        <div class="row">
                          <div class="col-4">
                            <label>Sujeto a impuesto</label>
                          </div>
                          <div class="col-8">
                            <select class="form-control form-control-sm" [(ngModel)]="data.VATLiable">
                              <option value="Y">SI</option>
                              <option value="N">NO</option>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <label>Sujeto a retención impto.</label>
                          </div>
                          <div class="col-8">
                            <select class="form-control form-control-sm" [(ngModel)]="data.WTLiable">
                              <option value="Y">SI</option>
                              <option value="N">NO</option>
                            </select>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-4">
                            <label>Fabricante</label>
                          </div>
                          <div class="col-8">
                            <select class="form-control form-control-sm" [(ngModel)]="data.FirmCode">
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <label>Gestionar número de lote [Sí/No]</label>
                          </div>
                          <div class="col-8">
                            <select class="form-control form-control-sm" [(ngModel)]="data.ManBtchNum">
                              <option value="Y">SI</option>
                              <option value="N">NO</option>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <label>Gestión de números de serie</label>
                          </div>
                          <div class="col-8">
                            <select class="form-control form-control-sm" [(ngModel)]="data.ManSerNum">
                              <option value="Y">SI</option>
                              <option value="N">NO</option>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <label>Indicador de activo fijo</label>
                          </div>
                          <div class="col-8">
                            <select class="form-control form-control-sm" [(ngModel)]="data.AssetItem">
                              <option value="Y">SI</option>
                              <option value="N">NO</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-4">
                            <label>Clase de impuesto</label>
                          </div>
                          <div class="col-8">
                            <select class="form-control form-control-sm" [(ngModel)]="data.TaxType">
                              <option value="">-</option>
                              <option value="Y">Impuesto regular</option>
                              <option value="U">Tiene impuesto</option>
                              <option value="N">No tiene impuesto</option>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <label>MEDIDA</label>
                          </div>
                          <div class="col-8">
                            <select class="form-control form-control-sm" [(ngModel)]="data.MEDIDA">
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <label>Gestionar números de serie sólo al finalizar</label>
                          </div>
                          <div class="col-8">
                            <select class="form-control form-control-sm" [(ngModel)]="data.ManOutOnly">
                              <option value="Y">SI</option>
                              <option value="N">NO</option>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <label>Método de gestión</label>
                          </div>
                          <div class="col-8">
                            <select class="form-control form-control-sm" [(ngModel)]="data.MngMethod">
                              <option value="">-</option>
                              <option value="A">En cada transacción</option>
                              <option value="R">Solo en lanzamiento</option>
                            </select>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-4">
                            <label>Característica 1</label>
                          </div>
                          <div class="col-8">
                            <select class="form-control form-control-sm" [(ngModel)]="data.QryGroup1">
                              <option value="Y">SI</option>
                              <option value="N">NO</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--Fin general-->
                  </div>
                  <div class="tab-pane fade" id="datosdecompra" role="tabpanel" aria-labelledby="datosdecompra-tab">
                    <!--inicio persona de contacto-->
                    <div class="row">
                      <div class="col-md-6">

                        <div class="row">
                          <div class="col-4">
                            <label>Unidad de medida de compras(*)</label>
                          </div>
                          <div class="col-8">
                            <input type="text" class="form-control form-control-sm" [(ngModel)]="data.BuyUnitMsr" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <label>Cantidad de artículos por unidad de medida de compra</label>
                          </div>
                          <div class="col-8">
                            <input type="number" step="0.001" class="form-control form-control-sm"
                              [(ngModel)]="data.NumInBuy" />
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-4">
                            <label>Grupo de aduanas</label>
                          </div>
                          <div class="col-8">
                            <select class="form-control form-control-sm" [(ngModel)]="data.CstGrpCode">
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-4">
                            <label>Cantidad de artículos por unidad de medida de compra</label>
                          </div>
                          <div class="col-8">
                            <input type="text" class="form-control form-control-sm" [(ngModel)]="data.PurPackMsr" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <label>Cantidad por empaque (compras)</label>
                          </div>
                          <div class="col-8">
                            <input type="text" class="form-control form-control-sm" [(ngModel)]="data.PurPackUn" />
                          </div>
                        </div>
                        <div class="row">

                          <div class="col-4">
                            <label>Volume: Unidad de medida para compras</label>
                          </div>
                          <div class="col-8">
                            <select class="form-control form-control-sm" [(ngModel)]="data.BVolUnit">
                            </select>
                          </div>
                        </div>



                      </div>
                    </div>
                    <!--Fin persona de contacto-->
                  </div>
                  <div class="tab-pane fade" id="datosdeventa" role="tabpanel" aria-labelledby="datosdecompra-tab">
                    <!--inicio persona de contacto-->
                    <div class="row">
                      <div class="col-md-6">

                        <div class="row">
                          <div class="col-4">
                            <label>Unidad de medida de ventas(*)</label>
                          </div>
                          <div class="col-8">
                            <input type="text" class="form-control form-control-sm" [(ngModel)]="data.SalUnitMsr" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <label>Cantidad de artículos por unidad de medida de venta</label>
                          </div>
                          <div class="col-8">
                            <input type="number" step="0.001" class="form-control form-control-sm"
                              [(ngModel)]="data.NumInSale" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <label>Unidad de medida de ventas(*)</label>
                          </div>
                          <div class="col-8">
                            <input type="text" class="form-control form-control-sm" [(ngModel)]="data.SalUnitMsr" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <label>Cantidad por empaque (ventas)</label>
                          </div>
                          <div class="col-8">
                            <input type="text" class="form-control form-control-sm" [(ngModel)]="data.SalPackUn" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <label>Altura 1: Unidad de venta</label>
                          </div>
                          <div class="col-8">
                            <input type="number" step="0.001" class="form-control form-control-sm"
                              [(ngModel)]="data.SHeight1" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">


                        <div class="row">
                          <div class="col-4">
                            <label>Longitud 2: Unidad de medida de ventas</label>
                          </div>
                          <div class="col-8">
                            <input type="number" class="form-control form-control-sm" [(ngModel)]="data.SLen2Unit" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <label>
                              Ø Exterior Inferior
                            </label>
                          </div>
                          <div class="col-8">
                            <input type="number" step="0.01" class="form-control form-control-sm"
                              [(ngModel)]="data.SLength1" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <label>Cantidad: Unidad de venta</label>
                          </div>
                          <div class="col-8">
                            <input type="number" step="0.001" class="form-control form-control-sm"
                              [(ngModel)]="data.SVolume" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <label>Volumen: Unidad de medida de ventas</label>
                          </div>
                          <div class="col-8">
                            <input type="number" class="form-control form-control-sm" [(ngModel)]="data.SVolUnit" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <label>Peso 1: Unidad de venta</label>
                          </div>
                          <div class="col-8">
                            <input type="number" step="0.001" class="form-control form-control-sm"
                              [(ngModel)]="data.SWeight1" />
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-4">
                            <label>Peso 1: Unidad de medida de ventas</label>
                          </div>
                          <div class="col-8">
                            <input type="number" class="form-control form-control-sm" [(ngModel)]="data.SWght1Unit" />
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-4">
                            <label>
                              Ø Inferior
                            </label>
                          </div>
                          <div class="col-8">
                            <input type="number" class="form-control form-control-sm" [(ngModel)]="data.SWidth1" />
                          </div>
                        </div>


                      </div>
                    </div>
                    <!--Fin persona de contacto-->
                  </div>
                  <div class="tab-pane fade" id="datosdeinventario" role="tabpanel"
                    aria-labelledby="datosdeinventario-tab">
                    <!--inicio direccion-->
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-4">
                            <label>Fijar cuentas de mayor según(*)</label>
                          </div>
                          <div class="col-8">
                            <select [(ngModel)]="data.GLMethod" class="form-control form-control-sm">
                              <option value="C">Grupo</option>
                              <option value="W">Bodega</option>
                              <option value="L">Nivel</option>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <label>Unidad de medida de inventario</label>
                          </div>
                          <div class="col-8">
                            <input type="text" class="form-control form-control-sm" [(ngModel)]="data.InvntryUom" />
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-4">
                            <label>Método de valoración</label>
                          </div>
                          <div class="col-8">
                            <select [(ngModel)]="data.EvalSystem" class="form-control form-control-sm">
                              <option value="">-</option>
                              <option value="A">Media movil</option>
                              <option value="S">Standard</option>
                              <option value="F">FIFO</option>
                              <option value="B">Serie/Lote</option>
                            </select>
                          </div>
                        </div>

                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-4">
                            <label>Unidad de medida de inventario (*)</label>
                          </div>
                          <div class="col-8">
                            <!-- <select class="form-control form-control-sm" [(ngModel)]="data.IUoMEntry">
                              <option *ngFor="let row of OUOM" [ngValue]="row.UomEntry"> {{row.UomName}}</option>
                            </select> -->
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <label>Gestión de stocks por almacén</label>
                          </div>
                          <div class="col-8">
                            <select class="form-control form-control-sm" [(ngModel)]="data.ByWh">
                              <option value="Y">SI</option>
                              <option value="N">NO</option>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <label>Contado</label>
                          </div>
                          <div class="col-8">
                            <select class="form-control form-control-sm" [(ngModel)]="data.WasCounted">
                              <option value="Y">SI</option>
                              <option value="N">NO</option>
                            </select>
                          </div>
                        </div>


                      </div>
                    </div>
                    <!--fin direccion-->
                  </div>
                  <div class="tab-pane fade" id="planificacion" role="tabpanel" aria-labelledby="planificacion-tab">
                    <!--Inicio condicion de pago-->
                    <div class="row">
                      <div class="col-md-6">

                        <div class="row">
                          <div class="col-4">
                            <label>Método de planificación</label>
                          </div>
                          <div class="col-8">
                            <select class="form-control form-control-sm" [(ngModel)]="data.PlaningSys">
                              <option value="">-</option>
                              <option value="M">MRP</option>
                              <option value="N">Ninguno</option>
                            </select>

                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <label>Método aprovisionamiento</label>
                          </div>
                          <div class="col-8">
                            <select class="form-control form-control-sm" [(ngModel)]="data.PrcrmntMtd">
                              <option value="">-</option>
                              <option value="B">Comprar</option>
                              <option value="M">Crear</option>
                            </select>

                          </div>
                        </div>


                      </div>
                      <div class="col-md-6">

                      </div>
                    </div>
                    <!--Fin condicion de pago-->
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>
        </div>

      </div>
      <div class="col-md-3">

        <div class="card " style="max-height:650px;">
          <div class="card-header">
            <h4 class=""> Campos de usuario </h4>
          </div>
          <div class="card-body" style=" overflow-y: scroll; ">
            <!-- @foreach (var item in Model.Where(x => x.Code.StartsWith("U_")).OrderBy(x => x.ORDEN))
            { -->
            <div class="row" *ngFor="let row of USERFIELDS">
              <label> {{row.Descr}}</label>
              <input type="text" class="form-control" id="row.USER_FIELD" >
            </div>
          </div>
        </div>


      </div>
      <div>


        <button class="btn btn-warning" onclick="EnviarObjeto()">Agregar</button>

        <button class="btn btn-warning" onclick="EnviarObjeto()">Modificar</button>


      </div>
    </div>
  </div>
</div>