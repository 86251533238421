import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import {NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {IntranetoldService} from './../../../api/intranetold.service'
import {variableGlobal} from './../../../global.enum';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import Swal from 'sweetalert2'
import {PerfilAddEditComponent} from './addEdit'
import { Columns, Config, DefaultConfig, API, APIDefinition } from 'ngx-easy-table';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.sass']
})
export class PerfilComponent implements OnInit {

  constructor(
    private _api:IntranetoldService,
    private _dbService: NgxIndexedDBService,
    private modalService: NgbModal,
  ) { }
  @ViewChild('table', { static: true }) table: APIDefinition;
  data:any = [];
  public configuration: Config;
  public columns: Columns[];
  @ViewChild('frmAccion', { static: true }) frmAccion: TemplateRef<any>;
   ngOnInit(): void {

    this.columns = [
      { key: 'NOMBRE', title: "NOMBRE" },
      { key: 'URL_DEFAULT', title: 'URL Default' },
      { key: 'action', title: 'Acción', cellTemplate: this.frmAccion, searchEnabled: false },
    ];

    this.configuration = { 
      ...DefaultConfig,
      searchEnabled: true,
      detailsTemplate: true,
      paginationRangeEnabled: false,
     
      horizontalScroll: true,
      resizeColumn: false,
      fixedColumnWidth: true,
   };
   
    this.cargaPerfil();

    this.modeloNuevo();
  }

  nuevo:any;
  modeloNuevo()
  {
    this.nuevo = {
      "_id": "",
      "NOMBRE": "",
      "URL_DEFAULT": ""
    };
  }


  async cargaPerfil()
  {
    this.data = [];
    
    var perfiles:any = await this._api.getPerfilAll();
    this.data = perfiles.objeto;

    console.log("PERFILES",this.data);

    var carga = {id:variableGlobal.DB_TABLE_GENERAL_id_perfiles, datos: this.data};
    this._dbService.update(variableGlobal.DB_TABLE_GENERAL, carga).toPromise();
    if (variableGlobal.ESTILO_EASY_TABLE)
      this.setClass(variableGlobal.ESTILO_EASY_TABLE);
  }
  private setClass(name: string): void {
    this.table.apiEvent({
      type: API.setTableClass,
      value: name,
    });
  }

  eliminar(item)
  {
    Swal.fire({
      title: "¿Está seguro que desea eliminar el perfil (" + item.NOMBRE + ")?",
      showDenyButton: true,
      showCancelButton: false,
      denyButtonText: "Cerrar",
      confirmButtonText: "Si",
    
    }).then( async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        var respuesta:any = await this.eliminarApi(item);
        console.log(respuesta);
        if (respuesta.estado) {
          this.cargaPerfil();
          Swal.fire(respuesta.mensaje, '', 'success');
        }
        else
        {
          Swal.fire(respuesta.mensaje, '', 'error');
        }
       
      } else if (result.isDenied) {
        //Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }

  async eliminarApi(item)
  {
 
    const respuesta = await this._api.EliminaPerfil(item)
    console.log(respuesta);
    return respuesta;
  }

  editar(item, titulo = "Editar perfil", rowIndex)
  {
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size:"lg",
      animation:true,
      modalDialogClass:variableGlobal.CLASS_MODAL_BG,
    };
    const modalRef = this.modalService.open(PerfilAddEditComponent, ngbModalOptions);
    modalRef.componentInstance.data = item;
    modalRef.componentInstance.titulo = titulo;
    modalRef.componentInstance.modalReference = modalRef;
    modalRef.result.then((data) => {
      // on close
      //console.log("opt1", data);
      if (data) {
        this.cargaPerfil();
      }
      this.modeloNuevo();
  
    }, (reason) => {
      //this.editar(item);
    });
  }

}
