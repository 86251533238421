
<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Administrador de correo por etapa</h3>
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <input type="text" [(ngModel)]="data.NOMBRE" name="NOMBRE" class="form-control">

    
        <textarea rows="40" [(ngModel)]="data.CONSULTA" name="CONSULTA" class="form-control">{{data.CONSULTA}}</textarea>

        <button type="button" class="btn btn-primary" (click)="Guardar(data)">Guardar</button>


      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->
  </div>
  <!-- /.col -->
</div>
