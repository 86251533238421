<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Selecciona socio de negocio</h4>
    <br>
   
    <button type="button" class="close" aria-label="Close" (click)="cerrar()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <span>haz doble click y selecciona un cliente</span>
    <br>
    <ngx-table #table [configuration]="configuration"
        [data]="data"
        [columns]="columns"
        (event)="eventEmitted($event)"></ngx-table>
        <ng-template #frmPrice let-row let-rowIndex="rowIndex">
          {{row.Balance | currency: 'CLP'}}
        </ng-template>
        <ng-template #frmAccion let-row let-rowIndex="rowIndex">
          <button type="button" class="btn btn-info btn-sm" (click)="mantenedorCliente(row.CardCode)">Editar</button>
        </ng-template>


  </div>
  <div class="modal-footer">
    <button (click)="mantenedorCliente('')" type="button" class="btn btn-success">Nuevo socio</button>
  </div> 