
<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Administrador de perfil</h3>
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <button class="btn btn-primary" style="margin-bottom:10px" (click)="editar(nuevo, 'Nuevo perfil', '-1')" > <i class="fa fa-plus"></i> Nuevo </button>

        <ngx-table #table
              [configuration]="configuration"
              [data]="data"
              [columns]="columns"
        ></ngx-table>
        <ng-template #frmAccion let-row let-rowIndex="rowIndex">
          <button class="btn btn-success" (click)="editar(row, 'Editar perfil', rowIndex)">Editar</button>
          <button class="btn btn-danger" style="margin-left:5px" (click)="eliminar(row, rowIndex)"><i class='fa fa-trash'></i></button>
        </ng-template>

  
      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->
  </div>
  <!-- /.col -->
</div>
