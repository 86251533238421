import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import {variableGlobal} from './../global.enum'
import {DocumentoMarting} from '../model/ventaModel'
import {BusinessPartner} from '../model/BusinessPartnerModel'
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SapService {

  constructor(
    private _http: HttpClient,
  ) { }

  async GeneraDocumentoMarketing(objeto:DocumentoMarting)
  {
    const body =
    {
      "documento": objeto,
      "respuesta": {"estado": true, "TOKEN": environment.TOKEN, "mensaje": "", "resultado": null}
    }
    console.log(body);
    return this._http.post(environment.RUTA_API + '/api/SAP/GeneraDocumentoMarketing', body).toPromise();
  }
  async GeneraDocumentoMarketingDesdeBorrador(preliminal)
  {
    const body =
    {
      estado: false,
      TOKEN: environment.TOKEN,
      resultado: preliminal
    }
    console.log(body);
    return this._http.post(environment.RUTA_API + '/api/SAP/GeneraDocumentoMarketingDesdeBorrador', body).toPromise();
  }
  async ObtenerDocumentoMarketing(objeto:DocumentoMarting)
  {
    const body =
    {
      "documento": objeto,
      "respuesta": {"estado": true, "TOKEN": environment.TOKEN, "mensaje": "", "resultado": null}
    }
    //console.log(body);
    return this._http.post(environment.RUTA_API + '/api/SAP/ObtenerDocumentoMarketing', body).toPromise();
  }
  async ObtenerDocumentoMarketingLITE(objeto:DocumentoMarting)
  {
    const body =
    {
      "documento": objeto,
      "respuesta": {"estado": true, "TOKEN": environment.TOKEN, "mensaje": "", "resultado": null}
    }
    console.log(body);
    return this._http.post(environment.RUTA_API + '/api/SAP/ObtenerDocumentoMarketingLITE', body).toPromise();
  }

  async GeneraSocioNegocio(objeto:BusinessPartner)
  {
    const body =
    {
      "socio": objeto,
      "respuesta": {"estado": true, "TOKEN": environment.TOKEN, "mensaje": "", "resultado": null}
    }
    //console.log(body);
    return this._http.post(environment.RUTA_API + '/api/SAP/GeneraSocioNegocio', body).toPromise();
  }
  async ObtenerSocioNegocio(objeto) // objeto: BusinessPartner
  {
    const body =
    {
      "socio": objeto,
      "respuesta": {"estado": true, "TOKEN": environment.TOKEN, "mensaje": "", "resultado": null}
    }
    console.log(body);
    return this._http.post(environment.RUTA_API + '/api/SAP/ObtenerSocioNegocio', body).toPromise();
  }

  async ObtenerSocioNegocioEdita(objeto) // objeto: BusinessPartner
  {
    const body =
    {
      "socio": objeto,
      "respuesta": {"estado": true, "TOKEN": environment.TOKEN, "mensaje": "", "resultado": null}
    }
    console.log(body);
    return this._http.post(environment.RUTA_API + '/api/SAP/ObtenerSocioNegocioEdita', body).toPromise();
  }

  async ObtenerMaestroTarifarios()

  {
    const body = {
      "respuesta": {
          "estado": true,
          "TOKEN": "INDESAD",
          "mensaje": "",
          "resultado": null
      }
  }


    return this._http.post(environment.RUTA_API + '/api/SAP/obtieneItems', body).toPromise();
  }
  async ObtenerItemMT(itemcode)

  {
    const body = {
      "respuesta": {
        "estado": true,
        "TOKEN": "INDESAD",
        "mensaje": "",
        "resultado": null
    },
    "item": {
        "ItemCode": itemcode
    }
  }


    return this._http.post(environment.RUTA_API + '/api/SAP/obtieneItem', body).toPromise();
  }

  async CrearEditarMT(data)

  {
    const body = {
      "respuesta": {
          "estado": true,
          "TOKEN": "INDESAD",
          "mensaje": "",
          "resultado": null
      },
      "item": data
  }


    return this._http.post(environment.RUTA_API + '/api/SAP/generaActualizaMaestroArticulo', body).toPromise();
  }


  async EliminarMT(data)

  {
    const body = {
          "respuesta": {
        "estado": true,
        "TOKEN": "INDESAD",
        "mensaje": "",
        "resultado": null
    },
    "item": {
        "ItemCode": data.ItemCode
    }
  }


    return this._http.post(environment.RUTA_API + '/api/SAP/eliminaMaestroArticulo', body).toPromise();
  }

}
