import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { CookieService } from 'ngx-cookie-service';
import {variableGlobal} from './../global.enum'
import md5 from 'md5-ts';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VentaService {

  constructor(
    private _http: HttpClient,
  ) { }

  async CerrarDocumento(DocEntry: string, ObjType: string) {

    var parametros = "?DocEntry=" + DocEntry + "&ObjType=" + ObjType;
    return this._http.post(environment.RUTA_API + '/api/Venta/CerrarDocumento' + parametros, null).toPromise();
  }
  async CotizacionANV(DocEntry:any)
  {
    var parametros = "?DocEntry=" + DocEntry;
    return this._http.post(environment.RUTA_API + '/api/Venta/CotizacionANV' + parametros, null).toPromise();
  }
  async OfertaAOC(DocEntry:any)
  {
    var parametros = "?DocEntry=" + DocEntry;
    //console.log(DocEntry);
    return this._http.post(environment.RUTA_API + '/api/Venta/OfertaAOC' + parametros, null).toPromise();
  }
}
