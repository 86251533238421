import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { variableGlobal } from './../global.enum';
import { CookieService } from 'ngx-cookie-service';
import { DOCUMENTO_MARKETING_PRELIMINAL } from '../model/Mongo';
import { Respuesta } from '../model/Indesad';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IndesadService {

  constructor(
    private _http: HttpClient,
    private _cookieService: CookieService,
  ) { }

  async obtenerEventoCalendario() {
    var tokeUsuario = this._cookieService.get("token");
    const body =
    {
      "token": tokeUsuario,
      "respuesta": { "estado": true, "TOKEN": environment.TOKEN, "mensaje": "", "resultado": null }
    }

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    let options = {
      headers
    }
    console.log(body);
    return this._http.post(environment.RUTA_API + '/api/INDESAD/obtenerEventoCalendario', body, options).toPromise();
  }

  async EnviaDocumentoAprobacion(data) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
  
    const body = {
      "datos": data
    };
  
    console.log("body que mando", body);
    return this._http.post(environment.RUTA_API + '/api/INDESAD/verificaDocumentoAprobacion', body, { headers }).toPromise();
  }
  
  async EnviaDocumentoAprobacionTEST(data) {

    return this._http.post(environment.RUTA_API + '/api/INDESAD/verificaDocumentoAprobacionTEST', data).toPromise();

  }
  async AgregarDocumentoPreliminar(datos) {
    const body: Respuesta =
    {
      estado: false,
      TOKEN: environment.TOKEN,
      resultado: datos
    }
    console.log(body);
    return this._http.post(environment.RUTA_API + '/api/INDESAD/addEditDocumentoMarketingPreliminal', body).toPromise();
    
  }
  async GetPicking(datos) {
    const body =
    {
      estado: false,
      TOKEN: environment.TOKEN,
      objeto: datos
    }
    console.log(body);
    return this._http.post(environment.RUTA_API + '/api/WMS/GetPickingAll', body).toPromise();
  }
  
  async AddPicking(datos) {
    const body =
    {
      estado: false,
      TOKEN: environment.TOKEN,
      objeto: datos
    }
    console.log(body);
    return this._http.post(environment.RUTA_API + '/api/WMS/GetPickingAll', body).toPromise();
  }

  async GetGrupo(grupo) {
    const body =
    {
      estado: false,
      TOKEN: environment.TOKEN,
      objeto: {
        grupo:grupo
      }
    }
    console.log(body);
    return this._http.post(environment.RUTA_API + '/api/WMS/GetGrupo', body).toPromise();
  }
  
  async AgregarEditarGrupos(datos) {
    const body =
    {
      estado: false,
      TOKEN: environment.TOKEN,
      objeto: datos
    }
    console.log(body);
    return this._http.post(environment.RUTA_API + '/api/WMS/AgregarEditarGrupos', body).toPromise();
  }

  async AsignarResponsalbe(DocEntry, Responsable,ObjType) {
    const body =
    {
      estado: true,
      TOKEN: environment.TOKEN,
      objeto: {
          DocEntry:DocEntry,
          responsable:Responsable
      }
    }
    
    console.log(body);
    var ruta =environment.RUTA_API + '/api/WMS/AsignarResponsable';
    console.log(ruta);
    return this._http.post(environment.RUTA_API + '/api/WMS/AsignarResponsable', body).toPromise();
  }

  
  async GenerarDespacho(DocEntry) {
    const body =
    {
      estado: true,
      TOKEN: environment.TOKEN,
      objeto: {
          DocEntry:DocEntry          
      }
    }
    
    console.log(body);
    return this._http.post(environment.RUTA_API + '/api/WMS/GenerarDespacho', body).toPromise();
  }
}
