import { Component, OnInit, Input } from '@angular/core';
import { IntranetoldService } from '../../../api/intranetold.service';
import { IntranetNewService } from '../../../api/intranet-new.service';
import { ActivatedRoute } from '@angular/router'
import { variableGlobal } from '../../../global.enum'
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ventaLayault',
  templateUrl: './layoult-venta.component.html',
  styleUrls: ['./layoult-venta.component.sass']
})
export class LayoutVentaComponent implements OnInit {


  @Input() public ObjType: string;
  @Input() public DocEntry: string;
  @Input() public urllayout: string;
  @Input() public urlImprimir: string;
  constructor(private sanitizer: DomSanitizer) {}
  safeUrl;


  ngOnInit(): void {

    console.log("URL FINAL",this.urllayout);

    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.urllayout);




  }


}
