<div class="encabezado">
  <p>Metas</p>
</div>

<div class="contenedor-tabla">
  <table class="table">
    <thead>
      <tr>
        <th scope="col">SlpCode</th>
        <th scope="col">Nombre</th>
        <th scope="col">Mes</th>
        <th scope="col">Año</th>
        <th scope="col">Valor</th>
        <th scope="col">
          <button (click)="crearMeta()" type="button" class="btn btn-success">
            CREAR
          </button>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of allmetas;let i = index">
        <th>{{row.SlpCode}}</th>
        <td>{{row.NOMBRE}}</td>
        <td>{{row.MES}}</td>
        <td>{{row.ANIO}}</td>
        <td>{{row.VALOR | iNDCLP}}</td>

        <td>

          <button type="button" class="btn btn-warning" style="margin-right: 5px;" (click)="editarMeta(row)">
            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" fill="#fff" class="bi bi-pencil-fill" viewBox="0 0 16 16">
              <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
            </svg>
          </button>

          <button type="button" class="btn btn-danger" (click)="eliminarMeta(row)">

            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" fill="#fff" class="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
            </svg>

          </button>





        </td>
      </tr>

    </tbody>
  </table>
</div>
