import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iNDCLP'
})
export class INDCLPPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    let newStr: string = "";

    const formatter = new Intl.NumberFormat('es-CL', {
      style: 'currency',
      currency: 'CLP',
      minimumFractionDigits: 0
    });
    newStr= formatter.format(value)

    return newStr;
  }

}
