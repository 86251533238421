  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{titulo}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="cerrar()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
        <div class="col-12">
          <ngx-table #table [configuration]="configuration" [data]="data" [columns]="columns" ></ngx-table>
        </div>
    </div>
  </div>

  <div class="modal-footer">
    <button (click)="cerrar()" type="button" class="btn btn-success">Cerrar</button>
  </div>