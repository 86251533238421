<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title">Crear Meta</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="cerrar()">×</button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label>Nombre</label>
            <input type="text" class="form-control" required="" [(ngModel)]="SlpName" readonly>
        </div>
        <div class="form-group">
            <label>Mes</label>
            <input type="text" class="form-control" required="" [(ngModel)]="Mes">
        </div>
        <div class="form-group">
            <label>Año</label>
            <input type="text" class="form-control" required="" [(ngModel)]="Anio">
        </div>
        <div class="form-group">
            <label>Meta</label>
            <input type="text" class="form-control" required="" [(ngModel)]="Meta">
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-success" (click)="editarMeta()">Actualizar</button>
        <input type="button" class="btn btn-default" data-dismiss="modal" value="Cancel" (click)="cerrar()">
    </div>
</div>