import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SapService } from 'src/app/api/sap.service';
@Component({
  selector: 'app-vista-nv',
  templateUrl: './vista-nv.component.html',
  styleUrls: ['./vista-nv.component.css']
})
export class VistaNvComponent implements OnInit {
  displayedColumns: string[] = ['estadolinea', 'codesap', 'descripcion', 'ubicpicking', 'stock', 'stockbodega', 'bodega', 'cantsolicitada', 'cantpendiente', 'cantpicking'];
  dataSource:any;


  constructor(
      private _apiSap: SapService,
      public activeModal: NgbActiveModal,
    ) { }

  data:any;
  datasap:any;

  ngOnInit(): void {

    this.cargaInicial();

  }

 async cargaInicial(){
  var json:any = {

         "DocEntry": this.data.DocEntry,
         "ObjType": this.data.ObjType

    }
    console.log(json)
    this.datasap = await this._apiSap.ObtenerDocumentoMarketingLITE(json);
    console.log(this.datasap)

    this.dataSource = this.datasap.resultado.Detalles;
    this.dataSource.forEach(element => {
      if (element.LineStatus=="O") {
        element.LineStatus="Abierto"
      }else{
        element.LineStatus="Cerrado"
      }
    });

  }

  cerrar() {
    this.activeModal.close();
  }

}

export interface PeriodicElement {
  estadolinea: any;
  codesap: any;
  descripcion: any;
  stock: any;
  stockbodega: any;
  bodega: any;
  cantsolicitada: any;
  cantpendiente: any;
  cantpicking: any;
  ubicpicking: any;

}



