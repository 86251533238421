import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MongoService } from 'src/app/api/mongo.service';

@Component({
  selector: 'app-crear-editar-meta',
  templateUrl: './crear-editar-meta.component.html',
  styleUrls: ['./crear-editar-meta.component.css']
})
export class CrearEditarMetaComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal,private _apiMongo: MongoService) { }
  data:any;

  ngOnInit(): void {

    console.log(this.data)

  }
  async guardar(){

    var modelo = {
      SlpCode:this.data.SlpCode,
      CONCEPTO:this.data.CONCEPTO,
      NOMBRE:this.data.NOMBRE,
      MES:this.data.MES,
      ANIO:this.data.ANIO,
      VALOR:this.data.VALOR
    }

  var datos = await this._apiMongo.crearMetaVendedor(this.data);

  this.activeModal.dismiss(datos);


}

  cerrar() {
    this.activeModal.close();
  }

}
