import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IndesadService } from 'src/app/api/indesad.service';
import { PrepararPedidoComponent } from '../preparar-pedido.component';


@Component({
  selector: 'app-picking',
  templateUrl: './picking.component.html',
  styleUrls: ['./picking.component.css',]
})
export class PickingComponent implements OnInit {
  DocEntry;
  DocNum;
  Detalle;
  InputItemCode;
  InputQty;
  Picking: any;
  constructor(
    public dialogRef: MatDialogRef<PrepararPedidoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _apiMongo: IndesadService,

  ) { }


  elem: any;
  fullScreen: boolean = false;
  ngOnInit(): void {


    this.elem = document.documentElement;

    console.log(this.data);
    this.DocEntry = this.data.DocEntry;
    this.DocNum = this.data.DocNum;
    this.Detalle = this.data.Document
    //this.openFullscreen();
  }

  async Inicializar() {
    this.Picking = this._apiMongo.GetPicking(this.DocEntry);    
  }

  SolicitarTraslado() {
    console.log("Solicitar traslado");
  }
  EnviaraAprobacion() {
    console.log("Enviar a aprobacion");
  }

  keyFunc(e) {

    this.Detalle.forEach(element => {
      if (element.ItemCode == this.InputItemCode) {
        element.OpenQty = element.OpenQty + this.InputQty;
      }
    });

  }

}
