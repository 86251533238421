
<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Mantenedor modelo de aprobación</h3>
      </div>
      <!-- /.card-header -->
      <div class="card-body">

        <div class="columns col-12">
          <button type="button" class="btn btn-secondary" (click)="addEditModelo(null);">Nuevo modelo</button>
          <ngx-table #table
                [configuration]="configuration"
                [data]="data"
                [columns]="columns"
          ></ngx-table>
          <ng-template #frmEstado let-row let-rowIndex="rowIndex">
            <font color='green' [hidden]="!row.ACTIVO">Activo</font>
            <font color='red'[hidden]="row.ACTIVO">Inactivo</font>
          </ng-template>
          <ng-template #frmAprobadores let-row let-rowIndex="rowIndex">
            <p *ngFor="let aprobador of row.APROBADORES">{{aprobador.NOMBRE}}</p>
          </ng-template>
          <ng-template #frmAccion let-row let-rowIndex="rowIndex">
            <button class="btn btn-outline-danger" (click)="eliminarModelo(row)">Eliminar</button>
            <button class="btn btn-outline-primary" (click)="addEditModelo(row);">Ver</button>
          </ng-template>
        </div>

  
      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->
  </div>
  <!-- /.col -->
</div>
