<style>
    .container {
        background-color: #fff;
        margin: 20px auto;
        padding: 20px;
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    h1 {
        color: #005792;
        text-align: left;
    }

    .saldo,
    .facturas {
        background-color: #f1f1f1;
        border-left: 5px solid #005792;
        padding: 10px;
        margin-bottom: 20px;
    }

    .saldo p,
    .facturas p {
        margin: 5px 0;
        color: black;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
    }

    table,
    th,
    td {
        border: 1px solid #ddd;
    }

    th,
    td {
        padding: 10px;
        text-align: left;
    }

    th {
        background-color: #005792;
        color: white;
    }

    button {
        padding: 10px;
        background-color: #005792;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }

    button:hover {
        background-color: #003f5f;
    }

    footer {
        background-color: #005792;
        color: white;
        padding: 10px;
        text-align: center;
    }

    footer img {
        width: 200px;
        margin-bottom: 5px;
    }

    .footer-text {
        margin-top: 10px;
    }

    input[type="text"],
    select {
        width: 100%;
        padding: 10px;
        margin: 5px 0 20px 0;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
    }

    .header {
        background-color: #0074A2;
        /* Azul */
        padding: 10px;
        color: white;
    }

    .user-info {
        background-color: #005792;
        /* Azul oscuro */
        color: white;
        padding: 10px;
        border-radius: 4px;
        margin-top: 20px;
    }

    .user-info p {
        margin: 5px 0;
    }
    @media print {
    * {
        display: none;
        }
        .printable {
            display: block; /* Asegúrate de que el elemento sea visible */
            /* Otros estilos para asegurar que el div se imprima como deseas */
        }
    }
</style>

<div class="container printable">
    <button style="position: absolute;right: 10px; top: 10px;" (click)="cerrar()">Cerrar</button>

    <h1>Detalle Financiero</h1>
    <h3 style="color: black;">{{data.CardName}} - {{ data.CardCode}}</h3>
    <div class="saldo">
        <p>Saldo Disponible: &nbsp; {{(BalanceCliente[0].CreditLine - BalanceCliente[0].Balance) | iNDCLP}}</p>
        <p>Saldo Utilizado: &nbsp; {{BalanceCliente[0].Balance |iNDCLP}}</p>
    </div>
    <div class="facturas">
        <div style="display: flex;color: black;align-items: center;">Facturas Vencidas: &nbsp; <p style="color: red;">{{facturas.length}}</p></div>
        <div style="display: flex;color: black;align-items: center;">Saldo Vencido: &nbsp; <p style="color: red;">{{this.Balance | iNDCLP}}</p></div>
    </div>
    <table>
        <thead>
            <tr>
                <th>Tipo documento</th>
                <th>N° Folio</th>
                <th>Fecha vencimiento</th>
                <th>Monto</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of ObtieneVentasCliente">
                <td>{{item.TIPO_DOCUMENTO}}</td>
                <td>{{item.FolioNum}}</td>
                <td>{{item.DocDueDate | date:'dd/MM/yyyy'}}</td>
                <td style="text-align: right;">{{item.Balance | iNDCLP}}</td>
            </tr>
        </tbody>
    </table>

    <div>

    </div>
</div>

<footer>
    <img src="{{this.general.RUTA_IMAGEN_LOGO}}" alt="COMERCIALMB" />
    <p>Copyright © {{ Date | date:'yyyy' }}. Todos los derechos reservados.</p>
</footer>

<!--

    <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Detalle cliente</h4>

    <button type="button" class="close" aria-label="Close" (click)="cerrar()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-md-12">

            <div class="row">
                <div class="col-12 col-sm-12">
                    <div class="card card-tabs">
                        <div class="card-header p-0 pt-1">
                            <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="custom-tabs-one-home-tab" data-toggle="pill"
                                        href="#Tab1" role="tab" aria-controls="custom-tabs-one-home"
                                        aria-selected="true">Saldo</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="custom-tabs-one-messages-tab" data-toggle="pill"
                                        href="#Tab2" role="tab" aria-controls="custom-tabs-one-messages"
                                        aria-selected="false">Cuentas pendientes</a>
                                </li>
                            </ul>
                        </div>
                        <div class="card-body">
                            <div class="tab-content" id="custom-tabs-one-tabContent">
                                <div class="tab-pane fade show active" id="Tab1" role="tabpanel"
                                    aria-labelledby="custom-tabs-one-home-tab">
                                    <ngx-table #table [configuration]="configurationSaldos" [data]="dataSaldos"
                                        [columns]="columnsSaldos"></ngx-table>

                                    <ng-template #frmRefDate let-row let-rowIndex="rowIndex">
                                        <div>{{row.RefDate | date:'dd/MM/yyyy'}}</div>
                                    </ng-template>

                                    <ng-template #frmDebit let-row let-rowIndex="rowIndex">
                                        <div>{{row.Debit | iNDCLP}}</div>
                                    </ng-template>
                                    <ng-template #frmCredit let-row let-rowIndex="rowIndex">
                                        <div>{{row.Crebit | iNDCLP}}</div>
                                    </ng-template>
                                    <ng-template #frmBalDueDeb let-row let-rowIndex="rowIndex">
                                        <div>{{row.BalDueDeb | iNDCLP}}</div>
                                    </ng-template>
                                    <ng-template #frmBalDueCred let-row let-rowIndex="rowIndex">
                                        <div>{{row.BalDueCred | iNDCLP}}</div>
                                    </ng-template>
                                </div>

                                <div class="tab-pane fade" id="Tab2" role="tabpanel"
                                    aria-labelledby="custom-tabs-one-messages-tab">
                                    <ngx-table #table2 [configuration]="configurationVencidos" [data]="dataVencidos"
                                        [columns]="columnsVencidos"></ngx-table>
                                    <ng-template #frmFechaemision let-row let-rowIndex="rowIndex">
                                        <div>{{row.Fechaemision | date:'dd/MM/yyyy'}}</div>
                                    </ng-template>

                                    <ng-template #frmTotalFactura let-row let-rowIndex="rowIndex">
                                        <div>{{row.TotalFactura | iNDCLP}}</div>
                                    </ng-template>
                                    <ng-template #frm15dias let-row let-rowIndex="rowIndex">
                                        <div>{{row.V115 | iNDCLP}}</div>
                                    </ng-template>
                                    <ng-template #frm31dias let-row let-rowIndex="rowIndex">
                                        <div>{{row.V1_31 | iNDCLP}}</div>
                                    </ng-template>
                                    <ng-template #frm61dias let-row let-rowIndex="rowIndex">
                                        <div>{{row.V1_61 | iNDCLP}}</div>
                                    </ng-template>
                                    <ng-template #frm91dias let-row let-rowIndex="rowIndex">
                                        <div>{{row.V1_91 | iNDCLP}}</div>
                                    </ng-template>
                                    <ng-template #frm121dias let-row let-rowIndex="rowIndex">
                                        <div>{{row.V1_121 | iNDCLP}}</div>
                                    </ng-template>
                                    <ng-template #frm151dias let-row let-rowIndex="rowIndex">
                                        <div>{{row.v151 | iNDCLP}}</div>
                                    </ng-template>
                                    <ng-template #frm181dias let-row let-rowIndex="rowIndex">
                                        <div>{{row.VMasDe180 | iNDCLP}}</div>
                                    </ng-template>


                                </div>



                            </div>
                        </div>
                       
                    </div>
                </div>

            </div>
        </div>
    </div>




</div>
<div class="modal-footer">
    COMENTAR DE NUEVO ----   <button (click)="Seleccionar()" type="button" class="btn btn-success">Seleccionar</button>
</div>

-->