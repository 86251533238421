import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Columns, Config, DefaultConfig, APIDefinition, API } from 'ngx-easy-table';
import { variableGlobal } from 'src/app/global.enum';
import Swal from 'sweetalert2';
import {VerAprobadoresComponent} from "./verAprobadores/verAprobadores.component"
import { NgxIndexedDBService } from 'ngx-indexed-db';
import {SapService} from "./../../../../api/sap.service"
import {MongoService} from "./../../../../api/mongo.service"
import { faL } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-verAprobacion',
  templateUrl: './verAprobacion.component.html',
  styleUrls: ['./verAprobacion.component.scss']
})
export class VerAprobacionComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private _dbService: NgxIndexedDBService,
    private _apiSAP: SapService,
    private _apiMongo:MongoService,
  ) { }
  public configuration: Config;
  public columns: Columns[];
  public data:any;
  usuario:any;
  idUsuario;any;
  adminUsuario:any;
  @ViewChild('tabla', { static: true }) tabla: APIDefinition;
  @ViewChild('frmAccion', { static: true }) frmAccion: TemplateRef<any>;
  ngOnInit() {
    this.cargaInicial();
  }
  async cargaInicial()
  {
    console.log(this.data);
    this._dbService.getByID(variableGlobal.DB_TABLE_GENERAL, variableGlobal.DB_TABLE_GENERAL_id_login).subscribe(
      item => {
        var itemx: any = item;
        this.usuario = itemx.datos.login;
        this.idUsuario = this.usuario._id;
        this.adminUsuario = this.usuario.ADMIN;
        console.log("usuario", this.usuario);
        //console.log("usuario 2", this.idUsuario);
      }
    );
    this.columns = [
      { key: 'modeloAprobacion.NOMBRE', title: "Modelo", width:"300px" },
      { key: 'MENSAJE', title: 'Mensaje usuario'},
      { key: '', title: 'Acción', cellTemplate: this.frmAccion},
      // { key: '', title: 'Acción', width: "100px", cellTemplate: this.frmAccion, searchEnabled: false, orderEnabled: false }

    ];
    this.configuration = {
      ...DefaultConfig,
      searchEnabled: false,
      detailsTemplate: false,
      paginationRangeEnabled: false,

      horizontalScroll: true,
      resizeColumn: false,
      orderEnabled: false,
      rowReorder: false,
      columnReorder: false,
      selectRow: false,

      paginationEnabled: true,
      fixedColumnWidth: true,

      //radio:true
      //infiniteScroll:true,
      //infiniteScrollThrottleTime: 10,
      //rows:10,

    };


    if (variableGlobal.ESTILO_EASY_TABLE) {
      this.setClass(variableGlobal.ESTILO_EASY_TABLE);
    }
  }


  cerrar() {
    this.activeModal.close();
  }
  private setClass(name: string): void {
    this.tabla.apiEvent({
      type: API.setTableClass,
      value: name,
    });
  }
  async verAprobadores(row)
  {
    console.log(row);
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: "lg",
      modalDialogClass: variableGlobal.CLASS_MODAL_BG
    };
    const modalRef = this.modalService.open(VerAprobadoresComponent, ngbModalOptions);
    modalRef.componentInstance.data = row;
    modalRef.componentInstance.modalReference = modalRef;
    modalRef.result.then((datos) => {

    }, (reason) => {
      //this.editar(item);
    });
  }
  async aprobarRechazar(apruebaRechaza:boolean)
  {
    if (!apruebaRechaza) {
      this.data.RECHAZADO = true;
    }
    //console.log(this.data);
    var respuesta:any = await this._apiMongo.apruebaORechazaDocumento(this.data);
    console.log(respuesta);
    if (respuesta.estado) {
      Swal.fire("Mensaje", respuesta.mensaje, "success");
    }
    else
    {
      Swal.fire("Mensaje", respuesta.mensaje, "error");
    }
  }

  async generarDocumento()
  {
    Swal.fire({
      title: 'Espere por favor!',
      html: 'Se está procesando el documento',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      },
    });
    var respuesta:any = await this._apiSAP.GeneraDocumentoMarketingDesdeBorrador(this.data);
    Swal.close();
    console.log(respuesta);
    if (respuesta.estado) {
      //this.creaActualiza = "Actualizar";
      //this.data = datos.resultado;
      //Swal.fire("Mensaje", datos.mensaje, "success");
      Swal.fire({
        title: respuesta.mensaje,
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: 'Ok',
        icon: "success"
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          location.reload();
        }
      })

    }
    else {
      Swal.fire("Mensaje", respuesta.mensaje, "error");
    }
  }

  muestraBotonApruebRechaza()
  {
    var respuesta:boolean = false;
    if (this.data.APROBADO == false && this.data.RECHAZADO == false) {
      respuesta = true;
    }

    //console.log("data2", respuesta);
    if (respuesta) {
      var existeAlguienQuePuedeEditar = false;
      this.data.APROBACION.forEach(aprobacion => {
        //console.log("p2", aprobacion.modeloAprobacion.APROBADORES);
        aprobacion.modeloAprobacion.APROBADORES.forEach(element => {
          //console.log("app", element);
          if (element.id == this.idUsuario) {
            existeAlguienQuePuedeEditar = true;
          }
        });
      });
      if (this.adminUsuario) {
        existeAlguienQuePuedeEditar = true;
      }
      respuesta = existeAlguienQuePuedeEditar;
    }

    //respuesta = false;
    
    /*for (var aprobacion2 in this.data.APROBACION)
    {
      console.log("app", aprobacion2);
      var aprobacion:any = aprobacion2;
      for (var modeloAprobacion2 in aprobacion.modeloAprobacion)
      {
        var modeloAprobacion:any = modeloAprobacion2;
        for (var aprobadores in modeloAprobacion.APROBADORES)
        {
          console.log("prueba", aprobadores);
        }
      }
    }*/

    return respuesta;
  }
  muestraGenerarDoc()
  {
    var respuesta:boolean = false;
    if (this.data.APROBADO == true) {
      respuesta = true;
    }
    if (this.data.usuario._id != this.idUsuario) {
      if (!this.adminUsuario) {
        respuesta = false;
      }
      
    }
    return respuesta;
  }


}
