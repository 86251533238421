<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">{{titulo}} {{data.DocNum}}</h3>
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <div class="row">

          <div class="form-group col-md-6">
            <div class="col-md-3">
              <label for="txtCardCode">{{nombreCampo("CardCode")}}</label>
            </div>
            <div class="col-md-9">

              <div class="input-group input-group-sm" style="width: 100%;">
                <!--<input #CardCode id="CardCode" type="text" class="form-control" [(ngModel)]="data.CardCode"
                    matInput
                    [formControl]="myControl"
                    [matAutocomplete]="auto"
                    (keyup)="filtraSocio();"
                    (change)="validarSocioSeleccionado();"
                    >
                    <mat-autocomplete #auto="matAutocomplete">
                      <mat-option *ngFor="let item of OCRDCopy " [value]="item.CardCode">
                        {{item.CardName}}
                      </mat-option>
                    </mat-autocomplete>
                  -->
                <!-- VER CLIENTES (NO CARGAR LEAD) -->
                <input id="CardCode" type="text" class="form-control" [(ngModel)]="data.CardCode" disabled="">
                <span class="input-group-append">
                  <button type="button" class="btn btn-info btn-flat" (click)="selecionarCliente()">
                    <i class="fas fa-search"></i>
                  </button>
                </span>

                <span class="input-group-append">
                  <button type="button" class="btn btn-info btn-flat" (click)="CargarDatosClienteCuentas()"><i
                      class="fas fa-folder"></i></button>
                </span>
              </div>
              <label>{{data.CardName}}</label>
            </div>
          </div>

          <div class="form-group col-md-2">
            <div class="col-md-3">
              <label for="Moneda">Moneda</label>
            </div>

            <div class="col-md-9">
              <select class="form-control" [(ngModel)]="data.Currency" name="Currency"
                (change)="cambioTipoMoneda(true);" [disabled]="!data.ListaCUrrency">
                <option *ngFor="let curre of OCRN" [ngValue]="curre.CurrCode" selected="{{curre.sel}}" >{{curre.CurrName}}</option>
              </select>
              <label *ngIf="data.Currency != 'CLP'">{{tipoCambio | currency: data.Currency}}</label>
              <label *ngIf="data.Currency == 'CLP'">{{tipoCambio | iNDCLP}}</label>
            </div>

          </div>
          <div class="form-group col-md-4">
            <div class="col-md-3">
              <label> Impuesto </label>
            </div>
            <div class="col-md-12">
              <select id="TAXCODE" class="form-control"  [(ngModel)]="TaxCode" (change)="UpdateTaxCode()">
                <!-- <option  ></option> -->
                <option *ngFor="let row of IMPUESTOS" [value]="row.Code ">{{row.Name}}</option>
                <!-- <option value="IVA">IVA</option>
                <option value="IVA_EXE">IVA Exento</option> -->
              </select>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="form-group col-md-4">
            <label>{{nombreCampo("ShipToDef")}}</label>
            <div class="row">
              <div class="col-xs-4 col-sm-10">
                <div class="row">
                  <select class="form-control" [(ngModel)]="data.ShipToDef" name="ShipToDef" #t
                    (change)="cargaDatosDireccionDespacho(t.value)" [disabled]="!data.CardCode">
                    <option [ngValue]="null" disabled>Selecciona dirección</option>
                    <option *ngFor="let row of DESPACHO" [value]="row.Address">{{row.Address}}</option>
                  </select>
                </div>
                <div class="row">
                  <label>{{data.Address}}</label>
                </div>
              </div>
              <div class="col-sm-2">
                <button class="btn btn-warning" (click)="abreDireccion('DESPACHO')" [disabled]="!data.CardCode"><i
                    class="fas fa-ellipsis-h fa-xs"></i></button>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <label>{{nombreCampo("BillToDef")}}</label>
            <div class="row">
              <div class="col-xs-4 col-sm-12">
                <div class="row">
                  <select class="form-control" [(ngModel)]="data.BillToDef" name="BillToDef"
                    [disabled]="!data.CardCode">
                    <option [ngValue]="null" disabled>Selecciona dirección</option>
                    <option *ngFor="let row of FACTURA" [ngValue]="row.Address">{{row.Address}}</option>
                  </select>
                </div>
                <div class="row">
                  <label>{{textoDirFactura()}}</label>
                </div>

              </div>
              <!--<div class="col-sm-2" style="padding: 0 0 0 5px; border-color: transparent; background: none;">
                        <button class="btn btn-warning" onclick="DetalleFactura()" hidden><i class="fas fa-ellipsis-h"></i></button>
                    </div>-->
            </div>

          </div>
          <div class="col-md-4">
            <label>{{nombreCampo("GroupNum")}}</label>
            <div class="row">
              <div class="col-xs-4 col-sm-10">
                <select class="form-control" [(ngModel)]="data.GroupNum" name="GroupNum" [disabled]="true">
                  <option [ngValue]="null" disabled>Selecciona condicion de pago</option>
                  <option *ngFor="let row of OCTG" [ngValue]="row.GroupNum">{{row.PymntGroup}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-4">
            <label>{{nombreCampo("TrnspCode")}}</label>
            <select class="form-control" [(ngModel)]="data.TrnspCode" name="TrnspCode" [disabled]="!data.CardCode">
              <option [ngValue]="null" disabled>Seleccione una opción</option>
              <option *ngFor="let row of OSHP" [ngValue]="row.TrnspCode">{{row.TrnspName}}</option>
            </select>
          </div>

          <div class="form-group col-md-4">
            
            <label style="width: 100%;">Contacto: {{contacto?.CardName}}</label>
            <label style="width: 100%;">Celular: {{contacto?.Phone1}}</label>
            <label style="width: 100%;">Correo: {{contacto?.E_Mail}}</label>

          </div>

          <div class="form-group col-sm-4">
            <label> OC </label>
            <div class="col-xs-4 col-sm-10" style="display: flex;">
                 <input type="text" class="form-control" [(ngModel)]="data.NumAtCard">

                  <div style="position:relative;">
                  <a class='btn btn-primary' href='javascript:;'>
                    <i class="fa-solid fa-paperclip"></i>
                      <input (change)="LoadOC($event)" type="file" style='position:absolute;z-index:2;top:0;left:0;filter: alpha(opacity=0);-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";opacity:0;background-color:transparent;color:transparent;' name="file_source" size="40"  onchange='$("#upload-file-info").html($(this).val());'>
                  </a>
                  &nbsp;
                  <span class='label label-info' id="upload-file-info"></span>
          </div>


            </div>

          </div>
        </div>


        <div class="row">
          <div class="col-12">
            <label>
              <h4>{{nombreCampo("DocumentDetails")}}</h4>
            </label>
            <button class="btn btn-primary" (click)="addLine()" [disabled]="!data.CardCode"><i
                class="far fa-plus-square"></i></button>


            <div class="input-group-prepend float-right">
              <button type="button" class="btn btn-warning dropdown-toggle" data-toggle="dropdown" aria-expanded="false"
                [disabled]="!data.CardCode">

              </button>
              <ul class="dropdown-menu">
                <li class="dropdown-item" *ngFor="let row of columnsCopy"><input type="checkbox"
                    [checked]="validaColumnaValidaInicio(row.key)" (change)="ocultaColumna(row.key)" />{{row.title}}
                </li>

              </ul>
            </div>

            <hr>
          </div>
          <div class="columns col-12">



            <ngx-table #table [configuration]="configuration" [data]="data.Detalles" [columns]="columns"
              (event)="eventEmitted($event)"></ngx-table>


            <ng-template #frmItemCode let-row let-rowIndex="rowIndex">
              <div class="input-group input-group-sm" style="width: 200px;">
                <input type="text" class="form-control form-control-sm" [value]="row.ItemCode" disabled>
                <span class="input-group-append">
                  <button type="button" class="btn btn-info btn-flat btn-sm" (click)="ListaMateriales(row, rowIndex)"><i
                      class="fas fa-search"></i></button>
                </span>
              </div>
            </ng-template>
            <ng-template #frmDscription let-row let-rowIndex="rowIndex">
              <div class="input-group input-group-sm" style="width: 300px;">
                <input *ngIf="perfil != 'Cliente'" type="text" class="form-control form-control-sm" [(ngModel)]="row.Dscription">

                <div *ngIf="perfil == 'Cliente'" style="color: black;white-space: normal;">
                  {{row.Dscription}}
                </div>
                <!--<span class="input-group-append">
                  <button type="button" class="btn btn-info btn-flat" (click)="ListaMateriales(row, rowIndex)"><i class="fas fa-search"></i></button>
                </span>-->
              </div>
            </ng-template>
            <ng-template #frmFactor1 let-row let-rowIndex="rowIndex">
              <input style="width: 100px;" type="number" class="form-control form-control-sm" [(ngModel)]="row.Factor1"
                name="Factor1" (change)="cambiaDatoLinea(row, rowIndex, 'FACTOR1')">
            </ng-template>
            <ng-template #frmFactor2 let-row let-rowIndex="rowIndex">
              <!--<input style="width: 100px;" step="0.1"  type="number" class="form-control" [value]="row.Factor2">-->
              {{row.Factor2}}
            </ng-template>

            <ng-template #frmQuantity let-row let-rowIndex="rowIndex">
              <input style="width: 80px;" type="number" step="0.1" class="form-control form-control-sm"
                [(ngModel)]="row.Quantity" name="Quantity" (change)="cambiaDatoLinea(row, rowIndex, 'CANTIDAD');checkOnHand(row)">
            </ng-template>

            <ng-template #frmWhsCode let-row let-rowIndex="rowIndex">
              <select class="form-control form-control-sm" [(ngModel)]="row.WhsCode" name="WhsCode"
                [disabled]="!BodegaEditable" (change)="cambiaDatoLinea(row, rowIndex, 'CANTIDAD')"
                style="width: 150px;">
                <option [ngValue]="null" disabled>Selecciona Bodega</option>
                <option *ngFor="let row of OWHS" [ngValue]="row.WhsCode">{{row.WhsName}}</option>
              </select>
            </ng-template>


            <ng-template #frmPrice let-row let-rowIndex="rowIndex">
              <!--<div *ngIf="data.Currency != 'CLP'">{{row.Price | currency: row.Currency}}</div>
              <div *ngIf="data.Currency == 'CLP'">{{row.Price | iNDCLP}}</div>-->
              <input *ngIf="ROL == 'Vendedor'" style="width: 80px;" type="number" step="1" class="form-control form-control-sm"
                [(ngModel)]="row.Price" name="Price" (change)="cambiaDatoLinea(row, rowIndex, 'PRECIO')"
                [disabled]="EsCotizacion()" disabled>

              <input *ngIf="ROL != 'Vendedor'" style="width: 80px;" type="number" step="1" class="form-control form-control-sm"
                [(ngModel)]="row.Price" name="Price" (change)="cambiaDatoLinea(row, rowIndex, 'PRECIO')"
                [disabled]="EsCotizacion()">
            </ng-template>

            <ng-template #frmPriceBruto let-row let-rowIndex="rowIndex">
              <!--<div *ngIf="data.Currency != 'CLP'">{{row.Price | currency: row.Currency}}</div>
              <div *ngIf="data.Currency == 'CLP'">{{row.Price | iNDCLP}}</div>-->
              <input style="width: 80px;" type="number" step="1" class="form-control form-control-sm"
                [(ngModel)]="row.PriceBruto" name="Price" (change)="cambiaDatoLinea(row, rowIndex, 'PRECIO')"
                [disabled]="EsCotizacion()">
            </ng-template>


            <ng-template #frmPriceAfeterDisc let-row let-rowIndex="rowIndex">
              <input *ngIf="perfil != 'Cliente'" style="width: 80px;" type="number" step="1" class="form-control form-control-sm"
                [(ngModel)]="row.PriceAfeterDisc" name="PriceAfeterDisc"
                (change)="cambiaDatoLinea(row, rowIndex, 'PriceAfeterDisc')" disabled>

                <div *ngIf="perfil == 'Cliente'" style="color: black;">
                  {{row.PriceAfeterDisc}}
                </div>

              
              <!-- <div *ngIf="data.Currency != 'CLP'">{{row.PriceAfeterDisc | currency: row.Currency}}</div>
              <div *ngIf="data.Currency == 'CLP'">{{row.PriceAfeterDisc | iNDCLP}}</div> -->
            </ng-template>
            <ng-template #frmEstadoWMS let-row let-rowIndex="rowIndex">
              <div *ngFor="let item of row.UserFields">


                <select *ngIf="item.Field=='U_IND_WMS_ESTADO'" [(ngModel)]="item.Value">
                  <option value="-">No enviar</option>
                  <option value="P">Enviar</option>
                  <option value="T">Transito</option>
                  <option value="C">Enviado</option>

                </select>
              </div>
            </ng-template>

            <ng-template #frmLineTotal let-row let-rowIndex="rowIndex">
              <div *ngIf="data.Currency != 'CLP'">{{row.LineTotal | currency: row.Currency}}</div>
              <div *ngIf="data.Currency == 'CLP'">{{row.LineTotal | iNDCLP}}</div>
            </ng-template>
            <ng-template #frmAccion let-row let-rowIndex="rowIndex">
              <button type="button" class="btn btn-warning btn-sm" (click)="abrirLoteSerie(row)"
              *ngIf="data.ManBtchNum == 'N'" disabled ><i class="fas fa-dolly"></i></button>
              <button type="button" class="btn btn-info btn-sm" (click)="abrirLoteSerie(row)"
                [hidden]="row.ocultaSerie"><i class="fas fa-dolly"></i></button>
              <button type="button" class="btn btn-success btn-sm" (click)="abrirItemDetalle(row)"
                [hidden]="HidePur()"><i class="fas fa-list"></i></button>
              <button type="button" class="btn btn-danger btn-sm" (click)="removeRow(rowIndex)"><i
                  class="fas fa-trash-alt"></i></button>
            </ng-template>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-4 col-sm-6">
            <div class="col-md-8">
              <label>{{nombreCampo("DocDueDate")}}</label>
              <input type="date" [(ngModel)]="data.DocDueDate" [ngModel]="data.DocDueDate | date:'yyyy-MM-dd'"
                datetime="yyyy-MM-dd HH:mm:ss" name="DocDueDate" class="form-control" TextMode="date"
                [disabled]="!data.CardCode" />
            </div>
            <div class="form-group col-md-8" *ngIf="perfil != 'Cliente'">
              <label>{{nombreCampo("SlpCode")}}</label>
              <br />

              <select class="form-control" [(ngModel)]="data.SlpCode" name="SlpCode" [disabled]="!data.CardCode">
                <option [ngValue]="null" disabled>Selecciona vendedor</option>
                <option *ngFor="let vendedor of OSLP" [ngValue]="vendedor.SlpCode">{{vendedor.SlpName}}</option>
              </select>

            </div>
            <!-- <div class="form-group col-md-8">
              <label>Adjunto</label>
              <br />
              <input type="file" [ngModel]="FILES" placeholder="Upload file" (change)="UploadFiles()" accept=".pdf,.doc,.docx">

            </div> -->
            <div class="form-group col-md-8" [hidden]="true">
              <label>Propietario</label>
              <select class="form-control" [(ngModel)]="data.OwnerCode" name="OwnerCode" [disabled]="!data.CardCode">
                <option [ngValue]="null" disabled>Selecciona propietario</option>
                <option *ngFor="let emp of OHEM" [ngValue]="emp.empID">{{emp.lastName}} {{emp.firstName}}</option>
              </select>
            </div>

          </div>
          <div class="col-xs-4 col-sm-6">
            <div class="col-md-12">
              <label>Total Antes del impuesto</label>
              <div class="row">
                <div class="col-md-8">
                  <input class="form-control text-right" value="{{GrossProfit() | currency: data.Currency}}"
                    *ngIf="data.Currency != 'CLP'" disabled />
                  <input class="form-control text-right" value="{{GrossProfit() | iNDCLP}}"
                    *ngIf="data.Currency == 'CLP'" disabled />
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <label>Descuento </label>
              <div class="row">

                <div class="col-md-2">
                  <input *ngIf="perfil != 'Cliente'" type="number" step="1" class="form-control text-left" name="DiscPrcnt" [value]="data.DiscPrcnt"
                    (change)="cambiaInput($event)"/>

                  <div *ngIf="perfil == 'Cliente'" style="color: black;">
                    {{ data.DiscPrcnt }}
                  </div>
                </div>

                <div class="col-md-6">
                  <input class="form-control text-right" value="{{DiscTotal() | currency: data.Currency}}" *ngIf="data.Currency != 'CLP'" />
                  <input class="form-control text-right" value="{{DiscTotal() | iNDCLP}}" *ngIf="data.Currency == 'CLP'" />
                </div>
                
              </div>
            </div>

            <div class="col-md-12">
              <label>Impuesto IVA</label>
              <div class="row">
                <div class="col-md-8">
                  <input class="form-control text-right" value="{{VatSum() | currency: data.Currency}}"
                    *ngIf="data.Currency != 'CLP'" disabled />
                  <input class="form-control text-right" value="{{VatSum() | iNDCLP}}" *ngIf="data.Currency == 'CLP'"
                    disabled />
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <label>Monto Total</label>
              <div class="row">
                <div class="col-md-8">
                  <input class="form-control text-right" value="{{DocTotal() | currency: data.Currency}}"
                    *ngIf="data.Currency != 'CLP'" disabled />
                  <input class="form-control text-right" value="{{DocTotal() | iNDCLP}}" *ngIf="data.Currency == 'CLP'"
                    disabled />
                </div>
              </div>
            </div>
          </div>


          <div class="col-md-12">
            <label>Comentario SAP:</label>
            <textarea id="txtComments" type="text" class="form-control" TextMode="MultiLine"
              style="height:100px;width: 100%;" [(ngModel)]="data.Comments"></textarea>
            <br>
          </div>

          <!-- Botón Crear / Actualizar nota de venta -->
          <div class="col-md-12">
            <div>
              <button type="button" class="btn flex-fill btn-success btn-lg" (click)="generarDocumento()"
                style="width:100%" [disabled]="!data.CardCode">
                {{creaActualiza}} {{titulo}}
              </button>

            </div>
          </div>
        </div>

      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->
  </div>
  <!-- /.col -->
</div>
<!-- /.row -->
