import { Component, OnInit, ViewChild, TemplateRef, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {IntranetoldService} from '../../../api/intranetold.service'
import { Columns, Config, DefaultConfig, APIDefinition, API } from 'ngx-easy-table';
import {DocumentoMarketingDetalle} from "../../../model/ventaModel"
import { variableGlobal } from '../../../global.enum';
import {LayoutVentaComponent} from "./layoult-venta.component"
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas';
import { environment } from 'src/environments/environment';




@Component({
  selector: 'app-ventaLayaultModel',
  templateUrl: './venta-layault-model.html',
  styleUrls: ['./layoult-venta.component.sass'],
})
export class VentaLayaultModelComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private _api: IntranetoldService
  ) {

  }

  ObjType:string = "";
  DocEntry:string = "";
  DocNum:string="";
  urllayout:string= "";
  urlImprimir:string= "";

  ngOnInit(): void {

  }

  imprimir()
  {

    window.open(
      this.urlImprimir,
      '_blank' // <- This is what makes it open in a new window.
    );
  }
  cerrar() {
    this.activeModal.close();
  }



}
