<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title" *ngIf="Origen=='Venta'">Mantenedor  Clientes/Lead </h3>
                <h3 class="card-title" *ngIf="Origen=='Compra'">Mantenedor  Proveedores </h3>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                <div class="row">
                    <button (click)="nuevoSocio()" type="button" class="btn btn-primary">Crear Cuenta</button>
                </div>
                <div class="row">
                    <div class="columns col-12">
                        <ngx-table #table [configuration]="configuration" [data]="data" [columns]="columns" ></ngx-table>
                        <ng-template #frmAccion let-row let-rowIndex="rowIndex" let-id>
                            <button type="button" class="btn btn-success" (click)="Editar(row, rowIndex, id)" [hidden]="CardType=='P'">Editar</button>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>