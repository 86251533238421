import { Component, OnInit, ViewChild, TemplateRef, HostListener
, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import {IntranetoldService} from './../../../api/intranetold.service'
import {variableGlobal} from './../../../global.enum';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import {UserAddEditComponent} from './addEdit'
import { Subject } from 'rxjs';
import {NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2'
import { Columns, API, APIDefinition, DefaultConfig, Config } from 'ngx-easy-table';



@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.sass'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserComponent implements OnInit {

  constructor(
    private _api:IntranetoldService,
    private _dbService: NgxIndexedDBService,

    private modalService: NgbModal,

  ) { }
  @ViewChild('table', { static: true }) table: APIDefinition;
  data:any = [];

  perfiles:any = []; 
  general = variableGlobal;
  public configuration: Config;
  public columns: Columns[];
  public innerWidth: number;

  ngOnInit(): void {
    this._dbService.getByID(variableGlobal.DB_TABLE_GENERAL, variableGlobal.DB_TABLE_GENERAL_id_perfiles).subscribe(res =>{
      var per:any = res;
      this.perfiles = per.datos;

    });

    this.primeraCarga();
    this.modeloNuevo();
  }


  @HostListener('window:resize', [])
  onResize(): void {
    this.checkView();
  }

  @ViewChild('frmAccion', { static: true }) frmAccion: TemplateRef<any>;
  @ViewChild('frmFoto', { static: true }) frmFoto: TemplateRef<any>;
  @ViewChild('frmPerfil', { static: true }) frmPerfil: TemplateRef<any>;
  @ViewChild('frmDetalle', { static: true }) frmDetalle: TemplateRef<any>;
  async primeraCarga()
  {
    this.configuration = { 
      ...DefaultConfig,
      searchEnabled: true,
      detailsTemplate: true,
      paginationRangeEnabled: false,
     
      horizontalScroll: true,
      resizeColumn: true,
      fixedColumnWidth: true,
   };

      
      this.checkView();
    await this.getUsuarios();

    if(variableGlobal.ESTILO_EASY_TABLE)
      this.setClass(variableGlobal.ESTILO_EASY_TABLE);

  }
  private setClass(name: string): void {
    this.table.apiEvent({
      type: API.setTableClass,
      value: name,
    });
  }

  private checkView(): void {
    this.innerWidth = window.innerWidth;
    if (this.isMobile) {
      this.columns = [
        //{ key: 'RUT', title: "RUT" },
        { key: 'NOMBRE', title: 'Nombre', width: "400px" },
        { key: 'action', title: 'Detalle', cellTemplate: this.frmDetalle, searchEnabled: false },
      ];
    } 
    else {
      this.columns = [
        { key: 'RUT', title: "RUT" },
        { key: 'NOMBRE', title: 'Nombre' },
        { key: 'CARGO', title: 'Cargo' },
        { key: 'IMAGE_LITTLE', title: 'Foto', cellTemplate: this.frmFoto, searchEnabled: false },
        { key: 'PERFIL', title: 'PERFIL', cellTemplate: this.frmPerfil, searchEnabled: false },
        { key: 'action', title: 'Actions', cellTemplate: this.frmAccion, searchEnabled: false },
      ];

  }

  }

 

  async getUsuarios()
  {
    this.data = null;
    var res:any = await this._api.getAllLogin();
    if(res.estado)
    {

      this.data = [...res.objeto];
       
    }
  }

  buscaPerfil(id)
  {
    var nombre:string = "";
    var dato = this.perfiles.filter(item => item._id.search(new RegExp(id, 'i')) > -1  )[0];
    if (dato) {
      nombre = dato.NOMBRE;
    }
    return nombre;
  }


  
  nuevo:any;
  modeloNuevo()
  {
    this.nuevo = {
      "_id": null,
      "RUT": "",
      "PASSWORD": null,
      "F_SISTEMA": null,
      "ADMIN": false,
      "NIVEL": null,
      "F_ULTIMO_CAMBIO": null,
      "IMAGE_LITTLE": variableGlobal.RUTA_IMAGEN_USER_NOT_FOUND,
      "PERFIL": null,
      "LISTA_PRECIO": null,
      "FACTOR": null,
      "ACCESO_COSTO": false,
      "COLOR": null,
      "VENDEDOR": -1,
      "URL_DEFAULT": null,
      "NOMBRE": null,
      "ACTIVO": true,
      "CARGO": null,
      "JEFE": null,
      "TOKEN": null,
      "CORREO": null
    };
  }
  async editar(item, titulo, rowIndex)
  {
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size:"",
      modalDialogClass:variableGlobal.CLASS_MODAL_BG
    };
    const modalRef = this.modalService.open(UserAddEditComponent, ngbModalOptions);
    modalRef.componentInstance.data = item
    modalRef.componentInstance.titulo = titulo;
    modalRef.componentInstance.modalReference = modalRef;
    modalRef.result.then((data) => {
      this.modeloNuevo();
      if(data)
      {
        //this.updateDataRow(data, rowIndex);
        //console.log(data);
        this.getUsuarios();
      }
      
  
      //this.rerender();
    }, (reason) => {
      //this.editar(item);
    });
   
  }

 
 
  get isMobile(): boolean {
    return this.innerWidth <= 768;
  }
  async eliminar(item, rowIndex)
  {
  


    
    Swal.fire({
      title: "¿Está seguro que desea eliminar el usuario (" + item.NOMBRE + ")?",
      showDenyButton: true,
      showCancelButton: false,
      denyButtonText: "Cerrar",
      confirmButtonText: "Si",
    
    }).then( async (result) => {

      if (result.isConfirmed) {
        var respuesta:any = await this._api.EliminaLogin(item);
        //console.log(respuesta);
        if (respuesta.estado) {
          //this.rerender();
          this.data = [...this.data.filter((_v, k) => k !== rowIndex)];
          Swal.fire(respuesta.mensaje, '', 'success');
          if(this.isMobile)
          {
            this.getUsuarios();
          }
        }
        else
        {
          Swal.fire(respuesta.mensaje, '', 'error');
        }
       
      } else if (result.isDenied) {
        //Swal.fire('Changes are not saved', '', 'info')
      }
    })
    
  }



  //@ViewChild('table', { static: true }) table: APIDefinition;
  public toggledRows = new Set<number>();
  onRowClickEvent($event: MouseEvent, index: number): void {

    $event.preventDefault();
    this.table.apiEvent({
      
      type: API.toggleRowIndex,
      value: index,
    });



    if (this.toggledRows.has(index)) {
      this.toggledRows.delete(index);
    } else {
      this.toggledRows.add(index);
    }
  } 
}