<script src="https://kit.fontawesome.com/868ff58ad9.js" crossorigin="anonymous"></script>
<nav role="navigation">
  <div id="menuToggle">
    <!--
      A fake / hidden checkbox is used as click reciever,
      so you can use the :checked selector on it.
      -->
    <input type="checkbox" />

    <!--
      Some spans to act as a hamburger.

      They are acting like a real hamburger,
      not that McDonalds stuff.
      -->
    <div class="menu-btn">
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#23272C" class="bi bi-upc-scan"
        viewBox="0 0 16 16">
        <path
          d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z" />
      </svg>
    </div>


    <!--
      Too bad the menu has to be inside of the button
      but hey, it's pure CSS magic.
      -->
    <ul id="menu">

      <div class="row container-botton">

        <div class="col-12 detalleov-titulo">
          <p>
            Detalle OV {{DocNum}}
          </p>
        </div>

        <div class="col-12 detalleov-comentario">
          <p class="negrita">
            Comentario:
          </p>
          <p>
            reservar no despachar aún
          </p>
        </div>





        <div class="col-12">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                  class="bi bi-upc-scan" viewBox="0 0 16 16">
                  <path
                    d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z" />
                </svg>
              </span>
            </div>
            <input placeholder="Escanear código aquí" type="text" class="form-control" aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              style="opacity: 1; position: initial;font-family: 'Roboto';font-size: 27px;" [(ngModel)]="InputItemCode" (keyup.enter)="keyFunc($event)">
          </div>
        </div>

        <div class="col-12">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                  class="bi bi-box-seam-fill" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                    d="M15.528 2.973a.75.75 0 0 1 .472.696v8.662a.75.75 0 0 1-.472.696l-7.25 2.9a.75.75 0 0 1-.557 0l-7.25-2.9A.75.75 0 0 1 0 12.331V3.669a.75.75 0 0 1 .471-.696L7.443.184l.01-.003.268-.108a.75.75 0 0 1 .558 0l.269.108.01.003 6.97 2.789ZM10.404 2 4.25 4.461 1.846 3.5 1 3.839v.4l6.5 2.6v7.922l.5.2.5-.2V6.84l6.5-2.6v-.4l-.846-.339L8 5.961 5.596 5l6.154-2.461L10.404 2Z" />
                </svg>
              </span>
            </div>
            <input type="number" id="pickingQuantity" min="1" step="0.01" class="form-control" placeholder="Cantidad"
              autocomplete="off" style="opacity: 1; position: initial;font-family: 'Roboto';font-size: 27px;" [(ngModel)]="InputQty"  (keyup.enter)="keyFunc($event)">
          </div>
        </div>

        <div class="col-12">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">

                  <div class="input-group input-group-sm mb-3" style="height: 30px;">
                    <div class="input-group-prepend" style="height: 38px;">
                      <span class="input-group-text" id="inputGroup-sizing-sm">

                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-funnel-fill" viewBox="0 0 16 16">
                          <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z"/>
                        </svg>
                      </span>
                    </div>
                    <input type="text" id="pickingQuantity" min="1" step="0.01" class="form-control" placeholder="Cantidad"
                      autocomplete="off" style="opacity: 1; position: initial;font-family: 'Roboto';font-size: 27px;height: 38px;" [(ngModel)]="InputQty"  (keyup.enter)="keyFunc($event)">
                  </div>

                </th>
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </thead>
            <thead>
              <tr>
                <th scope="col">Código</th>
                <th scope="col">Cantidad</th>
                <th scope="col">Cantidad Editable</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>123</td>
                <td>123</td>
                <td>123</td>
              </tr>

            </tbody>
          </table>
        </div>

      </div>
    </ul>
  </div>
</nav>

<div class="row">


  <div class="col-12 titulo-picking">
    <p>Detalle picking OV({{DocNum }})</p>
  </div>

  <hr style="width: 95%;">

  <div class="col-12 titulo-pickingv2">
    <p>Productos para picking</p>
  </div>

  <div class="col-12">

    <div class="row" *ngFor="let row of Detalle" style="margin-bottom: 10px;">

      <div class="col-12" style="background:#d4d8dd;color: black;font-size: 20px;">
        <div class="row">
          <div class="col-6" style="display: flex;justify-content: center;">
            <div>

              <input class="form-check-input myproject--checkbox" type="checkbox" value="" id="flexCheckDefault" name="CHK_SOLICITUD_TRASLADO">
              <label class="form-check-label" for="flexCheckDefault" style="font-size: 22px;font-weight: bold;">
                Artículo
              </label>
            </div>

          </div>
          <div class="col-6" style="display: flex;justify-content:center;font-size: 22px;">
            {{row.ItemCode}}
          </div>
        </div>
      </div>

      <div class="col-12" style="background:#bbd8a3;color: black;font-size: 20px;">
        <div class="row">
          <div class="col-6" style="display: flex;justify-content: center;">
            <div>
              <label class="form-check-label" for="flexCheckDefault" style="font-size: 22px;font-weight: bold;">
                Descripción
              </label>
            </div>

          </div>
          <div class="col-6" style="display: flex;justify-content:center;font-size: 22px;
          ">
            {{row.Dscription}}
          </div>
        </div>
      </div>
      <div class="col-12" style="background:#bbd8a3;color: black;font-size: 20px;">
        <div class="row">
          <div class="col-6" style="display: flex;justify-content: center;">
            <div>
              <label class="form-check-label" for="flexCheckDefault" style="font-size: 22px;font-weight: bold;">
                Bodega
              </label>
            </div>

          </div>
          <div class="col-6" style="display: flex;justify-content:center;font-size: 22px;
          ">
            {{row.WhsCode}}
          </div>
        </div>
      </div>

      <div class="col-12" style="background:#bbd8a3;color: black;font-size: 20px;">
        <div class="row">
          <div class="col-6" style="display: flex;justify-content: center;">
            <div>
              <label class="form-check-label" for="flexCheckDefault" style="font-size: 22px;font-weight: bold;">
                Categoría
              </label>
            </div>

          </div>
          <div class="col-6" style="display: flex;justify-content:center;font-size: 22px;
          ">
            {{row.ItmsGrpNam}}
          </div>
        </div>
      </div>

      <div class="col-12" style="background:#d4d8dd;color: black;font-size: 20px;">
        <div class="row">
          <div class="col-6" style="display: flex;justify-content: center;">
            <div>
              <label class="form-check-label" for="flexCheckDefault" style="font-size: 22px;font-weight: bold;">
                Stock
              </label>
            </div>

          </div>
          <div class="col-6" style="display: flex;justify-content:center;font-size: 22px;
          ">
            {{row.OnHand}}
          </div>
        </div>
      </div>

      <div class="col-12" style="background:#bbd8a3;color: black;font-size: 20px;">
        <div class="row">
          <div class="col-6" style="display: flex;justify-content: center;">
            <div>
              <label class="form-check-label" for="flexCheckDefault" style="font-size: 22px;font-weight: bold;">
                Cantidad solicitada
              </label>
            </div>

          </div>
          <div class="col-6" style="display: flex;justify-content:center;font-size: 22px;
          ">
            {{row.Quantity}}
          </div>
        </div>
      </div>

      <div class="col-12" style="background:#d4d8dd;color: black;font-size: 20px;">
        <div class="row">
          <div class="col-6" style="display: flex;justify-content: center;">
            <div>
              <label class="form-check-label" for="flexCheckDefault" style="font-size: 22px;font-weight: bold;">
                Cantidad pickeada
              </label>
            </div>

          </div>
          <div class="col-6" style="display: flex;justify-content:center;font-size: 22px;
          ">
            {{row.OpenQty}}
          </div>
        </div>
      </div>

      <div class="col-12" style="background:#bbd8a3;color: black;font-size: 20px;">
        <div class="row">
          <div class="col-6" style="display: flex;justify-content: center;">
            <div>
              <label class="form-check-label" for="flexCheckDefault" style="font-size: 22px;font-weight: bold;">
                Ubic Reserva
              </label>
            </div>

          </div>
          <div class="col-6" style="display: flex;justify-content:center;font-size: 22px;
          ">
            Sin Ubicación
          </div>
        </div>
      </div>

      <div class="col-12" style="background:#d4d8dd;color: black;font-size: 20px;">
        <div class="row">
          <div class="col-6" style="display: flex;justify-content: center;">
            <div>
              <label class="form-check-label" for="flexCheckDefault" style="font-size: 22px;font-weight: bold;">
                Ubic Reserva2
              </label>
            </div>

          </div>
          <div class="col-6" style="display: flex;justify-content:center;font-size: 22px;
          ">
            Sin Ubicación
          </div>
        </div>
      </div>

      <div class="col-12" style="background:#bbd8a3;color: black;font-size: 20px;">
        <div class="row">
          <div class="col-6" style="display: flex;justify-content: center;">
            <div>
              <label class="form-check-label" for="flexCheckDefault" style="font-size: 22px;font-weight: bold;">
                Ubic Picking
              </label>
            </div>

          </div>
          <div class="col-6" style="display: flex;justify-content:center;font-size: 22px;
          ">
            D-G-04-06
          </div>
        </div>
      </div>
      <div>
        <br>
      </div>

    </div>
    <div class="col-12" style="display:flex;justify-content: center;"  (click)="SolicitarTraslado()">

      <div class="boton-amarillo">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-truck"
          viewBox="0 0 16 16">
          <path
            d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
        </svg>
        Solicitar traslado
      </div>

      <div class="boton-verde"  (click)="EnviaraAprobacion()">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
          class="bi bi-clipboard2-check" viewBox="0 0 16 16">
          <path
            d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5h3Z" />
          <path
            d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-12Z" />
          <path
            d="M10.854 7.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3Z" />
        </svg>
        Enviar a aprobación
      </div>


    </div>

  </div>

</div>
