<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" id="labelTitulo">Aprobación</h5>
        <button type="button" class="close" (click)="cerrar();" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">

            <div class="col-md-12">

                <label>Nombre</label>
                <input class="form-control" id="NOMBRE" type="text" [(ngModel)]="data.NOMBRE" />

                <label>Descripción</label>
                <input class="form-control" id="DESCRIPCION" type="text" [(ngModel)]="data.DESCRIPCION" />

                <label>Estado</label>
                <input type="checkbox" name="acceptRules" class="form-control" [(ngModel)]="data.ACTIVO">


                <label>Documentos</label>
                <input class="form-control" id="DOCUMENTO" type="text" [(ngModel)]="data.DOCUMENTO" />




                <label>Procesa en</label>
                <select id="PROCESA_EN" class="form-control" [(ngModel)]="data.PROCESA_EN">
                    <option value="ENCABEZADO">Encabezado</option>
                    <option value="DETALLE">Detalle</option>
                </select>

                <label>Cantidad minima de aprobadores</label>
                <input class="form-control" id="CANTIDAD_MINIMA_APROBACIONES" type="number" step="1" value="1"
                    [(ngModel)]="data.CANTIDAD_MINIMA_APROBACIONES" />

                <label>Aprobadores</label>
                <button class="btn btn-primary" id="btnAgregarAprobador" (click)="agregarAprobador();">Agregar
                    aprobador</button>
                <table id="tblAprobadores" class="table table-bordered">
                    <tr>
                        <th>Aprobador</th>
                    </tr>
                    <tr *ngFor="let row of data.APROBADORES">
                        <td>
                            <select class="form-control" [(ngModel)]="row.id">
                                <option *ngFor="let row2 of aprobadores" value="{{row2._id}}">{{row2.NOMBRE}}</option>
                            </select>
                        </td>
                    </tr>
                </table>


                <label>Query</label>
                <textarea id="QUERY" autocomplete="off" class="form-control" style="width:100%; height:100px;"
                    [(ngModel)]="data.QUERY"></textarea>
                <button class="btn btn-primary" id="btnTestQuery" (click)="btnTestQuery()">Probar query</button>
                <br />

                <div>
                    <label>JSON</label>
                    <textarea name="" id="" cols="100" rows="20" [(ngModel)]="JSONstring"></textarea>
                </div>




            </div>



        </div>

    </div>
    <div class="modal-footer">

        <button class="btn btn-outline-success" (click)="guardarEditar();">Guardar</button>

        <button type="button" class="btn btn-secondary" (click)="cerrar();">Cerrar</button>

    </div>
</div>