<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Detalle documento</h4>

  <button type="button" class="close" aria-label="Close" (click)="cerrar()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12">

        
      <ngx-table #tabla [configuration]="configuration"
      [data]="documento.Detalles"
      [columns]="columns">
    </ngx-table>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-info btn-sm" (click)="cerrar()">Cerrar</button>
  <!--<button (click)="Seleccionar()" type="button" class="btn btn-success">Seleccionar</button>-->
</div> 