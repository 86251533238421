<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{titulo}}</h4>

    <button type="button" class="close" aria-label="Close" (click)="cerrar()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
     

    <div class="form-group">
      <label>Jefe</label>
      <select [(ngModel)]="data.JEFE" name="JEFE" class="form-control" (ngModelChange)="CambiaDato($event, 'JEFE')">
          <option [ngValue]="null" disabled>Selecciona Jefe</option>
          <option *ngFor="let datos of usuarios" [ngValue]="datos.RUT">{{datos.NOMBRE}}</option>
      </select>

    </div>

    <div class="form-group">
      <label>Empleado</label>
      <select [(ngModel)]="data.EMPLEADO" name="EMPLEADO" class="form-control" (ngModelChange)="CambiaDato($event, 'EMPLEADO')">
          <option [ngValue]="null" disabled>Selecciona empleado</option>
          <option *ngFor="let datos of usuarios" [ngValue]="datos.RUT">{{datos.NOMBRE}}</option>
      </select>

    </div>



  </div>
  <div class="modal-footer">
    <!--<button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>-->

    <button (click)="guardar()" type="button" class="btn btn-primary w-100">{{txtButton}}</button>
  </div> 