<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" *ngIf="socio.Origen=='Venta'">Mantenedor Cliente/Lead</h4>
    <h4 class="modal-title" id="modal-basic-title" *ngIf="socio.Origen=='Compra'">Mantenedor Proveedores</h4>

    <button type="button" class="close" aria-label="Close" (click)="cerrar()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="row col-12">
            <div class="form-group col-md-3">
                <label>Nombre *</label>
                <div class="col-md-10">
                  <input type="text" [(ngModel)]="socio.Nombre" name="Nombre" class="form-control" [disabled]="!nuevo">
                </div>
            </div>
            <div class="form-group col-3">
              <label>Rut *</label>
                <div class="col-md-10">
                  <input type="text" [(ngModel)]="socio.Rut" name="Nombre" class="form-control" [disabled]="!nuevo">
                </div>
            </div>

            <div class="form-group col-3">
              <label>Telefono</label>
                <div class="col-md-10">
                  <input type="text" [(ngModel)]="socio.Telefono" name="Telefono" class="form-control">
                </div>
            </div>

            <div class="form-group col-3">
              <label>Correo *</label>
                <div class="col-md-10">
                  <input type="email" [(ngModel)]="socio.Correo" name="Correo" class="form-control">
                </div>
            </div>
        </div>

        <div class="row col-12">
            <div class="form-group col-3">
                <label>Grupo</label>
                <select class="form-control"  [(ngModel)]="socio.GroupCode" name="GroupCode" >
                  <option [ngValue]="null" disabled>Selecciona grupo</option>
                    <option *ngFor="let item of grupoSocio" [ngValue]="item.GroupCode">{{item.GroupName}}</option>
                </select>
            </div>
            <div class="form-group col-3">
              <label>Vendedor</label>
                <div class="col-md-10">
                    <select class="form-control" #vendedor (change)="onSelectedVendedor($event)" [(ngModel)]="socio.Vendedor" name="Vendedor" >
                        <option [ngValue]="null" disabled>Selecciona vendedor</option>
                        <option *ngFor="let item of vendedores" [ngValue]="item.SlpCode">{{item.SlpName}}</option>
                    </select>
                  
                </div>
            </div>
            <!-- Este código se ejecuta sin condiciones por ende siempre está -->
            <div  class="form-group col-3" >
              <label>Origen del Lead</label>
              <div class="col-md-10">
                <input type="text" class="form-control" [(ngModel)]="socio.U_IND_origenLead">
              </div>
            </div>
            <!-- Desde aquí se debe añadir el código para satisfacer la tarea dada -->
            <!-- Aquí debe ir el giro -->
            <div  class="form-group col-3" >
              <label>Giro</label>
              <div class="col-md-10">
                <input type="text" class="form-control" [(ngModel)]="socio.Notes">
              </div>
            </div>
            <div class="form-group col-3">
              <label>Dirección de facturación</label>

              <select class="form-control" [(ngModel)]="socio.BillingAddress.Address" name="DireccionFacturacion" (change)="cambiaDireccionFacturacion()" >
                <option [ngValue]="null" disabled>Selecciona ID de facturación</option>
                <option value="" [selected]="definirDireccionFacturacion">Definir nuevo</option>
                <option *ngFor="let factura of direccionFacturacion" [ngValue]="factura.Address">{{factura.Address}}: {{factura.Street}}, {{factura.County}}, {{factura.City}}</option>
              </select>
            </div>
            <div class="form-group col-3">
              <label>Persona de contacto</label>

              <select class="form-control"  [(ngModel)]="socioCBox.Name" name="PersonaContacto" (change)="cambiaPersonaContacto()" >
                <option [ngValue]="null" disabled>Selecciona persona de contacto</option>
                <option value="" [selected]="definirPersonaContacto">Definir nuevo</option>
                <option *ngFor="let item of personaContacto" [ngValue]="item.Name">{{item.Name}}</option>
              </select>
            </div>
        </div>
        <br>
        <!-- Código dedicado a la dirección de facturación -->
        <div class="row col-12">
          <div class="row">
            <div class="form-group">
                <h3>Dirección de facturación</h3>
            </div>
          </div>
          <div class="row">
            <div class="form-group">
              <label>Calle</label>
              <div class="col-md-10">
                  <input type="text" class="form-control" [(ngModel)]="socio.BillingAddress.Street" name="Street" [disabled]="facturacionInicial && socio.BillingAddress.Address == null"/>
              </div>
            </div>
            <div class="form-group">
              <label>Comuna</label>
              <div class="col-md-10">
                  <input type="text" class="form-control" [(ngModel)]="socio.BillingAddress.County" name="County" [disabled]="facturacionInicial && socio.BillingAddress.Address == null"/>
              </div>
            </div>
            <div class="form-group">
              <label>Ciudad</label>
              <div class="col-md-10">
                  <input type="text" class="form-control" [(ngModel)]="socio.BillingAddress.City" name="City" [disabled]="facturacionInicial && socio.BillingAddress.Address == null"/>
              </div>
            </div>
            <div class="form-group">
              <label>País</label>
              <div class="col-md-10">
                <select #cboxPaises (change)="onSelected(cboxPaises.value)" class="form-control" [(ngModel)]="socio.BillingAddress.Country" name="Country" style="min-width: 257.07px;" [disabled]="facturacionInicial && socio.BillingAddress.Address == null">
                  <option [ngValue]="null" disabled>Selecciona país</option>
                  <option *ngFor="let item of paises" [ngValue]="item.Code">{{item.Name}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group">
              <label>Región</label>
              <div class="col-md-10">
                <select class="form-control" [(ngModel)]="socio.BillingAddress.State" name="State" style="min-width: 257.07px;" [disabled]="facturacionInicial && socio.BillingAddress.Address == null">
                  <option [ngValue]="null" disabled>Selecciona región</option>
                  <option *ngFor="let item of regiones" [ngValue]="item.Code">{{item.Name}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <!-- END Código dedicado a la dirección de facturación -->

        <br>
        <div class="row col-12">
          <div class="row">
            <div class="form-group">
                <h3>Detalle persona de contacto</h3>
            </div>
          </div>
          <div class="row">
            <div class="form-group">
                <label>ID de contacto</label>
                <div class="col-md-10">
                    <input type="text" class="form-control" [(ngModel)]="socio.ContactPersons1.Name" name="Name" [disabled]="contactoInicial && socio.ContactPersons1.Name == null"/>
                </div>
            </div>
            <div class="form-group">
                <label>Nombre</label>
                <div class="col-md-10">
                    <input type="text" class="form-control" [(ngModel)]="socio.ContactPersons1.FirstName" name="FirstName" [disabled]="contactoInicial && socio.ContactPersons1.Name == null"/>
                </div>
            </div>
            <div class="form-group" *ngIf="!nuevo || desdeNotaVenta">
                <label>Cargo</label>
                <div class="col-md-10">
                    <input type="text" class="form-control" [(ngModel)]="socio.ContactPersons1.Cargo" name="Cargo" [disabled]="contactoInicial && socio.ContactPersons1.Name == null"/>
                </div>
            </div>
            <div class="form-group">
                <label>Telefono</label>
                <div class="col-md-10">
                    <input type="tel" class="form-control" [(ngModel)]="socio.ContactPersons1.tel" name="tel" [disabled]="contactoInicial && socio.ContactPersons1.Name == null"/>
                </div>
            </div>
            <div class="form-group">
                <label>Correo</label>
                <div class="col-md-10">
                    <input class="form-control" type="email" [(ngModel)]="socio.ContactPersons1.correo" name="correo" [disabled]="contactoInicial && socio.ContactPersons1.Name == null"/>
                </div>
            </div>
          </div>
      </div>


      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="nuevoSocio('crear')" type="button" class="btn btn-primary" [hidden]="btnMostrar != 'socioNuevo'">Crear Cuenta</button>
    <!-- <button (click)="creaActualizaContacto(contactoNuevo, nuevo)" type="button" class="btn btn-primary" [hidden]="btnMostrar != 'crearContacto' || nuevo">Crear contacto</button> -->
    <button (click)="nuevoSocio('actualizar')" type="button" class="btn btn-primary" [hidden]="btnMostrar != 'actualizaContacto' || nuevo">Actualizar socio</button>
    <button (click)="cerrar()" type="button" class="btn btn-danger">Cerrar</button>
  </div>